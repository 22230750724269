import React from 'react';
import * as Yup from 'yup';
import { URL_REGEX } from '@hum/icm-app/src/utils/url';
import { useField, Form } from '@hum/common/src/modules/form';
import { BalanceSheetPartnerForm } from '@hum/icm-app/src/state';
import { Company, CompanyContact } from '@hum/types';
import { Grid, Fieldset, TextInputField } from '@hum/legacy-ui';

export type BalanceSheetPartner = {
  company: Pick<
    Company,
    'name' | 'website' | 'description' | 'publicDescription'
  >;
  contact: Pick<CompanyContact, 'firstName' | 'lastName' | 'email'>;
};

export const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    name: Yup.string().required('Company name is required'),
    description: Yup.string().required('Description is required'),
    publicDescription: Yup.string().required('Public description is required'),
    website: Yup.string()
      .matches(URL_REGEX, 'Please enter a valid URL')
      .required('Company website is required'),
  }),
  contact: Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
  }),
});

export const initialValues = {
  company: {
    name: '',
    description: '',
    publicDescription: '',
    website: '',
  },
  contact: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

export const AddBalanceSheetPartnerFormFields = ({
  form,
}: {
  form: Form<BalanceSheetPartnerForm>;
}) => {
  return (
    <>
      <Fieldset label="Balance Sheet Partner Overview">
        <Grid templateColumns="1fr">
          <TextInputField
            infoText="Balance sheet partner's name"
            label="Company Name"
            {...useField('company.name', 'text', form).input}
            inputTestId="company-name"
            autoFocus
          />

          <TextInputField
            label="Private Description"
            {...useField('company.description', 'text', form).input}
            inputTestId="company-website"
            infoText="Description that may contain sensitive information"
          />
          <TextInputField
            label="Public Description"
            {...useField('company.publicDescription', 'text', form).input}
            inputTestId="company-description"
            infoText="Description of the company that can be made public"
          />
          <TextInputField
            label="company website"
            {...useField('company.website', 'text', form).input}
            inputTestId="company-website"
          />
        </Grid>
      </Fieldset>
      <Fieldset label="Contact">
        <TextInputField
          label="First Name"
          {...useField('contact.firstName', 'text', form).input}
          inputTestId="firstname"
        />
        <TextInputField
          label="Last Name"
          {...useField('contact.lastName', 'text', form).input}
          inputTestId="lastname"
        />

        <TextInputField
          label="Email Address"
          {...useField('contact.email', 'text', form).input}
          inputTestId="email"
        />
      </Fieldset>
    </>
  );
};
