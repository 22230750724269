import { fork } from 'redux-saga/effects';
import {
  handleManageInsightsSettings,
  handleCustomizationSaveButtonClicked,
  handleFinancialSummaryVisibility,
} from './adminAnalytics';
export function* handleAdminAnalytics() {
  yield fork(handleManageInsightsSettings);
  yield fork(handleCustomizationSaveButtonClicked);
  yield fork(handleFinancialSummaryVisibility);
}
