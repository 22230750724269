import { takeEvery, call, put } from 'redux-saga/effects';

import { CompanyContactStatus } from '@hum/types';
import { OTPAction } from '@hum/icm-app/src/components/types';
import { client } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { adminCompanyUsersHookUsed } from '@hum/icm-app/src/actions';
import {
  AdminEntityActionType,
  AdminEntityTestAccountToggled,
  adminEntityTestAccountToggledSuccess,
  adminEntityTestAccountToggledError,
  AdminManageUsersResendInviteButtonClicked,
  resendInviteSuccess,
  resendInviteError,
  AdminManageUsersDisableUserButtonClicked,
  disableUserSuccess,
  disableUserError,
  AdminManageUsersEnableUserButtonClicked,
  enableUserSuccess,
  enableUserError,
  deleteUserSuccess,
  deleteUserError,
  AdminManageUsersDeleteUserButtonClicked,
  AdminManageUsersSetAsPrimaryContactButtonClicked,
  setAsPrimaryContactSuccess,
  setAsPrimaryContactError,
  DeleteUserSuccess,
  DisableUserSuccess,
  EnableUserSuccess,
  SetAsPrimaryContactSuccess,
} from './actions';
import { toast } from '@hum/common/src/modules/toast';

export function* handleAdmin() {
  yield takeEvery(
    AdminEntityActionType.ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED,
    handleTestAccountToggled
  );
}

export function* handleManageUsers() {
  yield takeEvery(
    AdminEntityActionType.ADMIN_MANAGE_USERS_RESEND_INVITE_BUTTON_CLICKED,
    handleManageUsersResendInvite
  );
  yield takeEvery(
    AdminEntityActionType.ADMIN_MANAGE_USERS_DISABLE_USER_BUTTON_CLICKED,
    handleDisableUser
  );
  yield takeEvery(
    AdminEntityActionType.ADMIN_MANAGE_USERS_ENABLE_USER_BUTTON_CLICKED,
    handleEnableUser
  );
  yield takeEvery(
    AdminEntityActionType.ADMIN_MANAGE_USERS_DELETE_USER_BUTTON_CLICKED,
    handleDeleteUser
  );
  yield takeEvery(
    AdminEntityActionType.ADMIN_MANAGE_USERS_SET_AS_PRIMARY_CONTACT_BUTTON_CLICKED,
    handleSetPrimaryContact
  );
  yield takeEvery(
    [
      AdminEntityActionType.DISABLE_USER_SUCCESS,
      AdminEntityActionType.ENABLE_USER_SUCCESS,
      AdminEntityActionType.DELETE_USER_SUCCESS,
      AdminEntityActionType.SET_AS_PRIMARY_CONTACT_SUCCESS,
    ],
    handleReloadListOfUsers
  );
}

function* handleTestAccountToggled({ payload }: AdminEntityTestAccountToggled) {
  try {
    yield call(updateCompanyApplicationTest, payload);
    yield put(adminEntityTestAccountToggledSuccess());
  } catch (error) {
    yield put(adminEntityTestAccountToggledError());
  }
}

// FLAG: Ad-hoc api call - should be in capitalApplication.ts
export const updateCompanyApplicationTest = async ({
  companyId,
  isTestAccount,
}: AdminEntityTestAccountToggled['payload']) => {
  const path = `/companies/${companyId}/capital_application`;
  return await client
    .patch(path, {
      application: normalize.application.out({ isTestAccount }),
    })
    .then((res) => {
      toast.success('Company test status successfully changed!');
      return res;
    })
    .catch((err) => {
      toast.error('Failed to set status');
      throw err;
    });
};

function* handleManageUsersResendInvite({
  payload,
}: AdminManageUsersResendInviteButtonClicked) {
  try {
    yield call(resendInvite, payload);
    yield put(resendInviteSuccess());
  } catch (error) {
    yield put(resendInviteError());
  }
}

// FLAG: Ad-hoc api call - should be in invite.ts?
export const resendInvite = async ({
  user,
}: AdminManageUsersResendInviteButtonClicked['payload']) => {
  return await client
    .post(`otp`, {
      email: user.email,
      action:
        user.status === CompanyContactStatus.Invited
          ? OTPAction.SetPassword
          : OTPAction.ResetPassword,
    })
    .then((res) => {
      toast.success('Invite sent.');
      return res;
    })
    .catch((err) => {
      toast.error('Failed to send invite.');
      throw err;
    });
};

function* handleDisableUser({
  payload,
}: AdminManageUsersDisableUserButtonClicked) {
  try {
    yield call(disableUser, payload);
    yield put(disableUserSuccess({ companyId: payload.companyId }));
  } catch (error) {
    yield put(disableUserError());
  }
}

export const disableUser = async ({
  user,
}: AdminManageUsersDisableUserButtonClicked['payload']) => {
  return await client
    .delete(`users/${user.id}`, { withCredentials: true })
    .then((res) => {
      toast.success('Disabled user!');
      return res;
    })
    .catch((err) => {
      toast.error('Failed to disable user');
      throw err;
    });
};

function* handleEnableUser({
  payload,
}: AdminManageUsersEnableUserButtonClicked) {
  try {
    yield call(enableUser, payload);
    yield put(enableUserSuccess({ companyId: payload.companyId }));
  } catch (error) {
    yield put(enableUserError());
  }
}

export const enableUser = async ({
  user,
}: AdminManageUsersEnableUserButtonClicked['payload']) => {
  return await client
    .patch(
      `users/${user.id}`,
      { status: CompanyContactStatus.Active },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success('Enabled user!');
      return res;
    })
    .catch((err) => {
      toast.error('Failed to enable user');
      throw err;
    });
};

function* handleDeleteUser({
  payload,
}: AdminManageUsersDeleteUserButtonClicked) {
  try {
    yield call(deleteUser, payload);
    yield put(deleteUserSuccess({ companyId: payload.companyId }));
  } catch (error) {
    yield put(deleteUserError());
  }
}

export const deleteUser = async ({
  user,
}: AdminManageUsersDeleteUserButtonClicked['payload']) => {
  return await client
    .delete(`users/${user.id}`, {
      withCredentials: true,
    })
    .then((res) => {
      toast.success('Deleted user!');
      return res;
    })
    .catch((err) => {
      toast.error('Failed to delete user');
      throw err;
    });
};

function* handleSetPrimaryContact({
  payload,
}: AdminManageUsersSetAsPrimaryContactButtonClicked) {
  try {
    yield call(setPrimaryContact, payload);
    yield put(setAsPrimaryContactSuccess(payload));
  } catch (error) {
    yield put(setAsPrimaryContactError());
  }
}

export const setPrimaryContact = async ({
  companyId,
  user,
}: AdminManageUsersSetAsPrimaryContactButtonClicked['payload']) => {
  await client
    .patch(
      `companies/${companyId}`,
      normalize.company.out({ contact_id: user.id }),
      { withCredentials: true }
    )
    .then((res) => {
      toast.success('Set primary contact user successfully!');
      return res;
    })
    .catch((err) => {
      toast.error('Failed to set primary contact');
      throw err;
    });
};

function* handleReloadListOfUsers({
  payload,
}:
  | DeleteUserSuccess
  | DisableUserSuccess
  | EnableUserSuccess
  | SetAsPrimaryContactSuccess) {
  yield put(adminCompanyUsersHookUsed({ companyId: payload.companyId }));
}
