import React from 'react';

import { AddCompanyForm } from './AddCompanyForm';

interface AddCompanyProps {
  onClose: () => void;
  title: string;
  visible: boolean;
}

const AddCompanyV5: React.FC<AddCompanyProps> = ({
  onClose,
  title,
  visible,
}) => {
  return (
    <AddCompanyForm
      onClose={onClose}
      onCancel={onClose}
      title={title}
      visible={visible}
    />
  );
};

export default AddCompanyV5;
