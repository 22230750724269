// Libraries
import React from 'react';
import { useHistory } from 'react-router';
import { format } from 'url';
// Components
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';

import { Button, ButtonType } from '@hum/ui-library';
import { Alert } from '@hum/legacy-ui';
import { apiPortfolioInvestorCreated } from '@hum/icm-app/src/actions';
import {
  CompanyType,
  ApplicationEconomicTiers,
  ApplicationRunwayMonths,
} from '@hum/types';
import {
  FormFields,
  AddPortfolioInvestorForm,
  validationSchema,
  initialValues as defaultValues,
} from './FormFields';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import * as ds from '@hum/design-system';
import { analytics } from '@hum/common';

// Remove keys for entries that have empty strings as values
function stripFalsyValues<TValue>(value: TValue): TValue {
  // surpress deprecated code
  return Object.fromEntries(
    Object.entries(value as any).filter((entry: any) => entry[1])
  ) as any;
}

const cleanFormData = (values: AddPortfolioInvestorForm) => ({
  contact: stripFalsyValues(
    values.contact
  ) as AddPortfolioInvestorForm['contact'],
  company: stripFalsyValues(
    values.company
  ) as AddPortfolioInvestorForm['company'],
});

interface AddCompanyProps {
  onClose: () => void;
  title: string;
  visible?: boolean;
}

export const AddPortfolioInvestor: React.FC<AddCompanyProps> = ({
  onClose,
  visible,
}) => {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { dispatch } = useAppStore();

  const initialValues: AddPortfolioInvestorForm = defaultValues;

  const createPortfolioInvestor = async (payload: AddPortfolioInvestorForm) => {
    try {
      setLoading(true);
      const {
        application: { companyID },
      } = await backend
        .post('/admin_capital_applications', {
          contact: normalize.companyOperator.cleanOut(payload.contact),
          company: normalize.company.out(payload.company),
          application: {
            // TODO backend: These values shouldn't be required if type is investor
            yearly_revenue: ApplicationEconomicTiers.Between0And1M,
            months_of_runway: ApplicationRunwayMonths.MoreThan12,
            payment_processor: '',
            record_system: '',
          },
          role: { name: CompanyType.Investor },
        })
        .then((r) => ({
          application: normalize.application.in(r.application),
          company: normalize.company.in(r.company),
        }));
      if (companyID) {
        closeForm();
        dispatch(apiPortfolioInvestorCreated());
        toast.success('Investor created successfully!');
        // @ts-ignore
        analytics.track('admin:create_portfolio_investor:success');
        history.push(
          format({
            pathname: '/admin',
            query: { company_id: companyID },
          })
        );
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Failed to create investor.');

      // @ts-ignore
      analytics.track('admin:create_portfolio_investor:fail');
      return error;
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: (rawVariables: AddPortfolioInvestorForm) => {
      return createPortfolioInvestor(cleanFormData(rawVariables));
    },
  });

  const error = form.status;

  const closeForm = () => {
    onClose();
    form.resetForm();
  };

  return (
    <ds.Modal
      scrollable
      fullHeight
      onClose={onClose}
      header="Add portfolio investor"
      visible={visible}
      veryWide
      footer={
        <>
          <div>
            <Button
              type={ButtonType.SECONDARY}
              testId="manage_company:cancel_edit"
              onClick={() => closeForm()}
              disabled={loading || form.isSubmitting}
            >
              Cancel
            </Button>
          </div>

          <Button
            testId="save-button"
            onClick={form.handleSubmit}
            disabled={loading || form.isSubmitting}
            loading={form.isSubmitting}
          >
            Add
          </Button>
        </>
      }
    >
      <form data-test-id="edit-investor-form" onSubmit={form.handleSubmit}>
        <FormFields includePosition form={form} showDescriptionFields />
      </form>

      {error ? <Alert negative title={error} /> : null}
    </ds.Modal>
  );
};
