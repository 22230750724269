import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  InputMode,
  SelectOption,
  SelectInputField,
  TextInputField,
  Form,
  getOptionForValue,
} from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';

import { Company, SyndicationInvestorDetails } from '@hum/types';
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  adminEditCompanyFormClosed,
  investorFormSaved,
} from '@hum/icm-app/src/actions';
import {
  BUSINESS_TYPE_SELECT_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_PRIORITY_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
} from '@hum/icm-app/src/components/constants';

const getSelectedValuesFromSelectOption = (options: SelectOption[]) => {
  return (options || []).map((option) => option.value);
};

const outputSyndicationMultiSelectValues = (
  syndicationInvestor: UpdateSyndicationInvestorInformationFormPayload['syndicationInvestor'],
  toValues: string[]
) => {
  const formattedValues = toValues
    .map((key) => ({
      [key]: getSelectedValuesFromSelectOption(syndicationInvestor[key]),
    }))
    .reduce((acc, current) => ({ ...acc, ...current }), {});
  return {
    ...syndicationInvestor,
    ...formattedValues,
  };
};

function getNullOrNumber(value: number | null | undefined) {
  return value === null || value === undefined ? null : Number(value);
}

const formatPayload = (
  payload: UpdateSyndicationInvestorInformationFormPayload
) => {
  const syndicationInvestorValues = outputSyndicationMultiSelectValues(
    payload.syndicationInvestor,
    ['investmentRanges', 'investmentTypes', 'investmentBusinessTypes']
  );
  return {
    company: normalize.company.out(payload.company),
    syndicationInvestor: normalize.syndicationInvestorDetail.out({
      ...syndicationInvestorValues,
      priority: Number(syndicationInvestorValues.priority),
      investmentYearlyRevenueMin: getNullOrNumber(
        syndicationInvestorValues.investmentYearlyRevenueMin
      ),
      investmentYearlyRevenueMax: getNullOrNumber(
        syndicationInvestorValues.investmentYearlyRevenueMax
      ),
    }),
  };
};

type UpdateSyndicationInvestorInformationFormPayload = {
  company: Pick<Company, 'description' | 'publicDescription'>;
  syndicationInvestor: Pick<
    SyndicationInvestorDetails,
    | 'location'
    | 'priority'
    | 'investmentRanges'
    | 'investmentTypes'
    | 'investmentBusinessTypes'
    | 'investmentYearlyRevenueMin'
    | 'investmentYearlyRevenueMax'
  >;
};

export const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    description: Yup.string().required('Description is required'),
    publicDescription: Yup.string().required('Public description is required'),
  }),
  syndicationInvestor: Yup.object().shape({
    location: Yup.string().required('Investor location is required'),
    priority: Yup.string().required('Priority is required'),
    investmentRanges: Yup.array().required('Investment ranges is required'),
    investmentTypes: Yup.array().required('Investment type is required'),
    investmentBusinessTypes: Yup.array().nullable(),
    investmentYearlyRevenueMin: Yup.string().nullable(),
    investmentYearlyRevenueMax: Yup.string().nullable(),
  }),
});

interface UpdateSyndicationInvestorInformationProps {
  company: Company;
  onSuccess: () => void;
  onCancel: () => void;
  'data-testid': string;
}

const prepareForUpdate = (company: Partial<Company> = {}) => {
  return {
    company: {
      description: company.description || '',
      publicDescription: company.publicDescription || '',
    },
    syndicationInvestor: {
      location: company.syndicationInvestor?.location || '',
      priority:
        getOptionForValue(
          SYNDICATION_INVESTOR_INVESTMENT_PRIORITY_OPTIONS,
          String(company.syndicationInvestor?.priority) || ''
        )?.value || '',
      investmentRanges: company.syndicationInvestor?.investmentRanges || [],
      investmentTypes: company.syndicationInvestor?.investmentTypes || [],
      investmentBusinessTypes:
        company.syndicationInvestor?.investmentBusinessTypes || [],
      investmentYearlyRevenueMin:
        company.syndicationInvestor?.investmentYearlyRevenueMin || null,
      investmentYearlyRevenueMax:
        company.syndicationInvestor?.investmentYearlyRevenueMax || null,
    },
  };
};

export const SyndicationInvestor: React.FC<UpdateSyndicationInvestorInformationProps> = ({
  company: currentCompany,
  onSuccess,
  onCancel,
  'data-testid': dataTestId,
}) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAppStore();
  const updateInfo = async (
    payload: UpdateSyndicationInvestorInformationFormPayload
  ) => {
    try {
      setLoading(true);

      const companyId = currentCompany.id;
      const formattedPayload = formatPayload(payload);
      const response = await backend.patch(
        `/companies/${companyId}/syndication/investors`,
        {
          syndication_investor: formattedPayload.syndicationInvestor,
          company: formattedPayload.company,
        }
      );

      if (response.company.id) {
        dispatch(adminEditCompanyFormClosed({ companyId: currentCompany.id }));
        dispatch(investorFormSaved({ companyId }));
        toast.success('Updated syndication investor successfully!');
        onSuccess();
      }
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.message || 'Failed to syndication investor information.'
      );
    } finally {
      setLoading(false);
    }
  };
  const initialValues = React.useMemo(() => prepareForUpdate(currentCompany), [
    currentCompany,
  ]);

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: updateInfo,
  });

  return (
    <div data-testid={dataTestId}>
      <Form onSubmit={form.handleSubmit}>
        <TextInputField
          inputMode={InputMode.TEXT_AREA}
          label="Private Description for Syndication"
          inputTestId="admin-page:syndication-investor-modal:private-company-description"
          placeholder="Description that may contain sensitive information"
          {...useField('company.description', 'text', form).input}
        />
        <TextInputField
          inputMode={InputMode.TEXT_AREA}
          label="Public Description for Syndication"
          inputTestId="admin-page:syndication-investor-modal:public-company-description"
          placeholder="Description of the company that can be made public"
          {...useField('company.publicDescription', 'text', form).input}
        />
        <TextInputField
          label="Location"
          inputTestId="admin-page:syndication-investor-modal:location"
          placeholder="ex: San Francisco, CA"
          autoComplete="chrome-off"
          {...useField('syndicationInvestor.location', 'text', form).input}
        />

        <SelectInputField
          label="Priority"
          inputAriaLabel="Investment priority"
          inputTestId="priority"
          options={SYNDICATION_INVESTOR_INVESTMENT_PRIORITY_OPTIONS}
          {...useField('syndicationInvestor.priority', 'select', form).input}
        />
        <SelectInputField
          label="Investment range"
          inputAriaLabel="Investment range"
          isClearable
          isMulti
          options={SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS}
          {...useField('syndicationInvestor.investmentRanges', 'select', form)
            .input}
        />

        <SelectInputField
          label="Investment type/stage"
          inputAriaLabel="Investment type/stage"
          isClearable
          isMulti
          options={SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS}
          {...useField('syndicationInvestor.investmentTypes', 'select', form)
            .input}
        />
        <SelectInputField
          label="Investment business types"
          inputAriaLabel="Investment business types"
          isClearable
          isMulti
          options={BUSINESS_TYPE_SELECT_OPTIONS}
          {...useField(
            'syndicationInvestor.investmentBusinessTypes',
            'select',
            form
          ).input}
        />

        <TextInputField
          inputTestId="update-company-information-tabs:syndication_investor_information_tab:body:investment-yearly-revenue-min"
          label="Investment yearly revenue min"
          inputMode={InputMode.DOLLAR}
          min={0}
          step={1000000} // 1M
          {...useField(
            'syndicationInvestor.investmentYearlyRevenueMin',
            'text',
            form
          ).input}
        />

        <TextInputField
          inputTestId="update-company-information-tabs:syndication_investor_information_tab:body:investment-yearly-revenue-max"
          label="Investment yearly revenue max"
          inputMode={InputMode.DOLLAR}
          {...useField(
            'syndicationInvestor.investmentYearlyRevenueMax',
            'text',
            form
          ).input}
        />

        <Box justifySpaceBetween>
          <Button
            type={ButtonType.SECONDARY}
            onClick={() => {
              form.resetForm();
              onCancel();
            }}
            disabled={loading || form.isSubmitting}
            testId="update-company-general-information:cancel-button"
          >
            Cancel
          </Button>

          <Button
            submit
            disabled={loading || form.isSubmitting}
            loading={form.isSubmitting}
            testId="update-company-general-information:submit-button"
          >
            Update
          </Button>
        </Box>
      </Form>
    </div>
  );
};
