import { confettiDisplayed } from '../ducks';
import { AppState } from '@hum/icm-app/src/state';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import confetti from 'canvas-confetti';

export const ConfettiContainer = () => {
  const dispatch = useDispatch();
  const showConfetti = useSelector((state: AppState) => state.showConfetti);

  useEffect(() => {
    if (showConfetti) {
      confetti({
        angle: 90,
        particleCount: 150,
        startVelocity: 40,
        ticks: 500,
        gravity: 0.9,
        spread: 100,
        zIndex: 9999,
        origin: { y: 0.5, x: 0.5 },
      } as any);
    }

    dispatch(confettiDisplayed());
  }, [showConfetti]);

  return null;
};
