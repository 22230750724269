import React from 'react';
import {
  FinanicalHighlights,
  NAICS_INDUSTRY_GROUP_OPTIONS,
  REVENUE_MODEL_V6,
  USE_OF_FUNDS,
} from '@hum/types';
import { Text, TextType, Icon, StatBox } from '@hum/ui-library';
import { money } from '@hum/icm-app/src/utils/format';

export function FinancialHighlights({
  highlights,
}: {
  highlights: FinanicalHighlights;
}) {
  return (
    <div
      className="bg-secondaryBg"
      data-testid="smartraise::financial-highlights"
    >
      <div className="mx-auto max-w-3xl py-20 px-4 sm:px-2">
        <Text type={TextType.DISPLAY_S}>
          Your business stands out from the rest
        </Text>
        <div className="inline-grid grid-cols-6 mt-8 px-4 md:grid-cols-12 md:gap-0 sm:px-2">
          <Icon.CircleChart className="col-span-1 md:col-span-1" />
          <Text
            type={TextType.BODY_M}
            subdued
            className="col-span-5 md:col-span-11"
          >
            Hum takes your funding profile and compares it with the criteria
            provided by our SmartRaise investors. Based on the information you
            provided, here's why your business stands out.
          </Text>
        </div>
        <div className="grid grid-cols-1 gap-5 my-10 md:grid-cols-3">
          {highlights.targetRaise && (
            <StatBox
              title="Target capital raise"
              value={`Up to ${money(highlights.targetRaise)!}`}
              testId="smartraise::financial-highlights::target-capital-raise"
            />
          )}
          {highlights.locationState && (
            <StatBox
              title="Location"
              value={highlights.locationState}
              testId="smartraise::financial-highlights::location-state"
            />
          )}
          {!highlights.locationState && highlights.locationCountry && (
            <StatBox
              title="Location"
              value={highlights.locationCountry}
              testId="smartraise::financial-highlights::location-country"
            />
          )}
          {highlights.selfReportedIndustry && (
            <StatBox
              title="Industry"
              value={
                NAICS_INDUSTRY_GROUP_OPTIONS[highlights.selfReportedIndustry]
              }
              testId="smartraise::financial-highlights::industry"
            />
          )}
          {highlights.useOfFunds && (
            <StatBox
              title="Proposed use of funds"
              value={USE_OF_FUNDS[highlights.useOfFunds]}
              testId="smartraise::financial-highlights::use-of-funds"
            />
          )}
          {highlights.revenueModel && (
            <StatBox
              title="Revenue model"
              value={REVENUE_MODEL_V6[highlights.revenueModel]}
              testId="smartraise::financial-highlights::revenue-model"
            />
          )}
          <StatBox
            title="Type of funding"
            value="Debt"
            testId="smartraise::financial-highlights::type-of-financing"
          />
        </div>
      </div>
    </div>
  );
}
