import React from 'react';
import { Icon, Text, TextType } from '@hum/ui-library';
import { FAQs } from '../../SmartRaisePage/FAQs';
import { Card } from '../../SmartRaisePage/NextSteps/Card';

export function EmptyPage() {
  const questions = [
    {
      text: 'What financing options are available at Hum?',
      link:
        'https://info.humcapital.com/hum-help-center/what-financing-options-are-available-on-hums-intelligent-capital-market-icm',
    },
    {
      text: 'Prepare to meet with investors',
      link:
        'https://info.humcapital.com/hum-help-center/what-should-i-do-to-prepare-to-meet-investors-via-hums-platform',
    },
  ];

  const cards = [
    {
      icon: <Icon.CircleUser />,
      title: 'Chat with your funding expert',
      description:
        'Our team will review your profile and provide advice on sizing and timing of the fund raise.',
    },
    {
      icon: <Icon.CirclePuzzle />,
      title: 'Hum curates your matches',
      description:
        'Hum curates a list of investors for you to start conversing with.',
    },
    {
      icon: <Icon.CircleHand />,
      title: 'Receive introductions!',
      description:
        'Hum introduces you to investors after receiving their indication of interest.',
    },
  ];

  return (
    <div
      className="max-w-3xl px-4 pt-20 pb-20 mx-auto sm:px-2"
      data-tesid="smartmatch::empty-hero"
    >
      <Text type={TextType.DISPLAY_S} className="text-center">
        Let’s work on a deal together!
      </Text>
      <Text
        type={TextType.BODY_M}
        className="max-w-md mx-auto mt-2 text-center"
        subdued
      >
        You will start receiving introductions for investor conversations after
        our internal team reviews and creates a deal for you. Once deals have
        been created, they’ll appear here.
      </Text>
      <div className="grid grid-cols-1 gap-10 my-20 md:grid-cols-3 md:gap-5">
        {cards.map((card, index) => (
          <Card key={index} {...card} testId={`smartraise::card::${index}}`} />
        ))}
      </div>
      <FAQs
        questions={questions}
        title="Have questions?"
        description="Learning resources"
      />
    </div>
  );
}
