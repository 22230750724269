import React from 'react';
import { DocumentManagement } from '../../DocumentManagement';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { Company, CompanyFile } from '@hum/types';

export const CompanyFiles: React.FC<{ company: Company }> = ({ company }) => {
  const fileManager = useFileManager({
    companyId: company.id,
    filter: (v: CompanyFile) => Boolean(v?.meta?.uploadedByAdmin) === false,
  });

  return <DocumentManagement company={company} fileManager={fileManager} />;
};
