import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SelectCard } from '@hum/legacy-ui';
import { useField, useForm } from '@hum/common/src/modules/form';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { adminEntityTestAccountToggled } from '@hum/icm-app/src/pages/admin/ducks/actions';

const useInternalConfig = () => {
  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const initialValues = useMemo(() => {
    return { isTestAccount: Boolean(company.data?.application.isTestAccount) };
  }, [company.data?.application.isTestAccount]);

  const form = useForm({
    initialValues,
    onSubmit: async (fields) => {
      // form is submitted once automatically
      if (form.submitCount > 1) {
        dispatch(
          adminEntityTestAccountToggled({
            companyId: company.data?.id!,
            isTestAccount: fields.isTestAccount,
          })
        );
      }
    },
  });

  useEffect(() => {
    form.submitForm();
  }, [form.values.isTestAccount]);

  const isTestAccount = useField('isTestAccount', 'checkbox', form).input;

  return {
    form,
    isTestAccount,
  };
};

export const InternalConfig = () => {
  const { form, isTestAccount } = useInternalConfig();
  return (
    <>
      <SelectCard
        form={form}
        checkbox={isTestAccount}
        checkboxId="isTestAccount"
        checkboxIsToggle
        title={<h4>Is this a test account?</h4>}
        description={
          <p>If checked, this company won't show up in Heap or Mode reports.</p>
        }
      />
    </>
  );
};
