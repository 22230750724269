// Libraries
import React, { useState } from 'react';
// Components
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { apiPrincipalInvestmentStateChanged } from '@hum/icm-app/src/actions';
import * as selfStyles from './index.pc';
import {
  PrincipalInvestment,
  PrincipalInvestmentStatus,
  BalanceSheetPartnerStatus,
  InterestedBalanceSheetPartner,
} from '@hum/icm-app/src/state';
// Other
import * as styles from '@hum/icm-app/src/pages/admin/tabs/Syndication/Match/styles.pc';
import { Modal } from '@hum/design-system';
import { Pill, Table, TableCellConfig } from '@hum/legacy-ui';
import { AddBalanceSheetPartner } from '../AddBalanceSheetPartner';
import { UpdateStatusPrincipalInvestment } from '../UpdateStatusPrincipalInvestment';

import { formatDate } from '@hum/icm-app/src/utils';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { Button, ButtonSize, ButtonType, Empty } from '@hum/ui-library';

const TABLE_CELLS: Record<string, TableCellConfig> = {
  service: {
    label: `Balance sheet partner name`,
    renderer: ({ item }: any) => {
      return <>{item.balanceSheetPartner.company.name}</>;
    },
  },
  status: {
    label: `Status`,
    renderer: ({ item }: any) => {
      return (
        <Pill
          positive={item.status === BalanceSheetPartnerStatus.Interested}
          negative={item.status === BalanceSheetPartnerStatus.NotInterested}
        >
          {item.status.replace(/_/g, ' ')}
        </Pill>
      );
    },
  },
  lastUpdate: {
    label: `Last Update`,
    renderer: ({ item }: any) => {
      return <>{formatDate(item.updatedAt)}</>;
    },
  },
  action: {
    label: 'Actions',
    renderer: ({ item, companyId }: any) => {
      const { dispatch } = useAppStore();
      const [loading, setLoading] = useState(false);
      const api = useAPI();

      const markBalanceSheetPartnerAs = async (status: string) => {
        await api.principalInvestment
          .markBalanceSheetPartnerAs(
            companyId,
            item.balanceSheetPartner.id,
            status
          )
          .then(() => {
            dispatch(apiPrincipalInvestmentStateChanged({ companyId }));
            setLoading(false);
          });
      };

      return (
        <div>
          <Button
            size={ButtonSize.SLIM}
            type={
              item.status === BalanceSheetPartnerStatus.Interested
                ? ButtonType.DESTRUCTIVE
                : ButtonType.PRIMARY
            }
            onClick={() =>
              markBalanceSheetPartnerAs(
                item.status === BalanceSheetPartnerStatus.Interested
                  ? BalanceSheetPartnerStatus.NotInterested
                  : BalanceSheetPartnerStatus.Interested
              )
            }
            disabled={loading}
            loading={loading}
          >
            mark as{' '}
            {item.status === BalanceSheetPartnerStatus.Interested
              ? BalanceSheetPartnerStatus.NotInterested.replace(/_/g, ' ')
              : BalanceSheetPartnerStatus.Interested}
          </Button>
        </div>
      );
    },
  },
};

const BalanceSheetPartnerList = ({
  interestedBalanceSheetPartners,
  companyId,
}: {
  interestedBalanceSheetPartners: InterestedBalanceSheetPartner[];
  companyId: number;
}) => {
  if (!interestedBalanceSheetPartners.length) {
    return (
      <Empty message="There are no balance sheet partners linked to this principal investment." />
    );
  }
  return (
    <Table
      cellConfig={TABLE_CELLS}
      secondary
      itemKey="id"
      cellProps={{ companyId }}
      data={interestedBalanceSheetPartners}
    />
  );
};

export const PrincipalInvestmentItem = ({
  principalInvestment,
  companyId,
}: {
  principalInvestment: PrincipalInvestment;
  companyId: number;
}) => {
  const [
    addBalanceSheetPartnerModalOpen,
    setAddBalanceSheetPartnerModalOpen,
  ] = useState(false);
  const openModal = () => setAddBalanceSheetPartnerModalOpen(true);
  const closeModal = () => setAddBalanceSheetPartnerModalOpen(false);

  const [
    changeStatusPrincipalInvestmentModalOpen,
    setChangeStatusPrincipalInvestmentModalOpen,
  ] = useState(false);
  const openChangeStatusModal = () =>
    setChangeStatusPrincipalInvestmentModalOpen(true);
  const closeChangeStatusModal = () =>
    setChangeStatusPrincipalInvestmentModalOpen(false);

  return (
    <>
      {addBalanceSheetPartnerModalOpen && (
        <Modal header="Add balance sheet partner to" onClose={closeModal}>
          <AddBalanceSheetPartner
            companyId={companyId}
            closeModal={closeModal}
          />
        </Modal>
      )}
      {changeStatusPrincipalInvestmentModalOpen && (
        <Modal
          header="Update status of principal investment"
          onClose={closeChangeStatusModal}
        >
          <UpdateStatusPrincipalInvestment
            companyId={companyId}
            status={principalInvestment.status}
            closeModal={closeChangeStatusModal}
          />
        </Modal>
      )}
      <styles.SyndicationMatchHead>
        <selfStyles.Container>
          <selfStyles.Topbar>
            <div>
              Status:
              <Pill
                positive={[
                  PrincipalInvestmentStatus.Closed,
                  PrincipalInvestmentStatus.Success,
                ].includes(principalInvestment.status)}
                negative={[PrincipalInvestmentStatus.Failed].includes(
                  principalInvestment.status
                )}
                notice={[
                  PrincipalInvestmentStatus.Claimed,
                  PrincipalInvestmentStatus.Term_sheet_signed,
                ].includes(principalInvestment.status)}
                className="ml-4"
              >
                {principalInvestment.status.replace(/_/g, ' ')}
              </Pill>
            </div>
            <div>
              <Button
                className="add-principal-investment"
                onClick={() => openModal()}
              >
                Add balance sheet partner
              </Button>
              <Button
                type={ButtonType.QUIET}
                onClick={() => openChangeStatusModal()}
              >
                Change status
              </Button>
            </div>
          </selfStyles.Topbar>
          <BalanceSheetPartnerList
            interestedBalanceSheetPartners={
              principalInvestment.interestedBalanceSheetPartners
            }
            companyId={companyId}
          />
        </selfStyles.Container>
      </styles.SyndicationMatchHead>
    </>
  );
};
