import React from "react";
import _pub8C547Ae7, {Preview as _pub8C547Ae7_Preview} from "../../../../../../design-system/src/Select.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pub4435Fe58 from "../../../../../../design-system/src/Pill.pc";
import _pub5Ab99334 from "../../../../../../design-system/src/TextInput.pc";
import _pubF6B296A8, {Preview as _pubF6B296A8_Preview} from "../../../../components/Pagination/styles.pc";
import _pub2Dbb4493 from "../../../../../../design-system/src/Button.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_16482ad9 _1dcc2499 _pub-1dcc2499",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Wrapper };

var CompanyFilterItem = React.memo(React.forwardRef(function CompanyFilterItem(props, ref) {
  return React.createElement("div", {
    "className": "_da004632 _1dcc2499 _pub-1dcc2499" + (props["active"] ? " " + "_1dcc2499_active _pub-1dcc2499_active active" : ""),
    "ref": ref,
  }, 
    props["children"],
    React.createElement(_pub4435Fe58, {
      "class": "_dbeb7344 _1dcc2499 _pub-1dcc2499",
      "info": true,
      "bold": true,
    }, 
      "Portfolio"
      
    ),
    React.createElement(_pub4435Fe58, {
      "class": "_acec43d2 _1dcc2499 _pub-1dcc2499",
      "notice": true,
      "bold": true,
    }, 
      "Syndication"
      
    )
  )
}));
export { CompanyFilterItem };

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_340e271e _1dcc2499 _pub-1dcc2499" + " " + "_1dcc2499_v3 _pub-1dcc2499_v3 v3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_4166f690 _1dcc2499 _pub-1dcc2499",
    }, 
      React.createElement("div", {
        "className": "_15b2d47f _1dcc2499 _pub-1dcc2499",
        "data-pc-label": "Sort by",
      }, 
        React.createElement("div", {
          "className": "_19342e6c _1dcc2499 _pub-1dcc2499",
        }, 
          "\n        Sort by\n      "
          
        ),
        React.createElement("div", {
          "className": "_803d7fd6 _1dcc2499 _pub-1dcc2499",
          "data-pc-label": "Filters",
        }, 
          props["sortByInputs"]
        )
      ),
      props["searchInput"],
      props["pagination"],
      props["activeFilters"]&&React.createElement("div", {
        "className": "_4903f51d _1dcc2499 _pub-1dcc2499",
      }, 
        props["activeFilters"]
      ),
      React.createElement("div", {
        "className": "_12df1066 _1dcc2499 _pub-1dcc2499",
      }, 
        React.createElement("div", {
          "className": "_693ab626 _1dcc2499 _pub-1dcc2499",
        }, 
          props["results"]
        )
      )
    )
  )
}));
export { Container };

var SearchIcon = React.memo(React.forwardRef(function SearchIcon(props, ref) {
  return React.createElement("div", {
    "className": "_dd6d822b _1dcc2499 _pub-1dcc2499",
    "ref": ref,
  }, null)
}));
export { SearchIcon };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(Container, {
    "class": "_3363e307",
    "ref": ref,
    "activeFilters": React.createElement(React.Fragment, {
    }, 
      React.createElement(_pub4435Fe58, {
        "class": "_7b386ee3 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_c3f5e75 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_95360fcf _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_e2313f59 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_7c55aafa _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_b529a6c _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_925bcbd6 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_e55cfb40 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_75e3e6d1 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_2e4d647 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_d4b865cc _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_a3bf555a _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_3ab604e0 _1dcc2499 _pub-1dcc2499",
        "info": true,
      }, 
        "filter"
        
      )
    ),
    "sortByInputs": React.createElement(React.Fragment, {
    }, 
      React.createElement(_pub8C547Ae7_Preview, {
        "class": "_7e777866 _1dcc2499 _pub-1dcc2499",
        "v3": true,
      }, null),
      React.createElement(_pub2Dbb4493, {
        "class": "_97048f0 _1dcc2499 _pub-1dcc2499",
      }, 
        "Filters"
        
      )
    ),
    "searchInput": React.createElement(React.Fragment, {
    }, 
      React.createElement(_pub5Ab99334, {
        "class": "_7fb51251 _1dcc2499 _pub-1dcc2499",
        "placeholder": "Search",
        "icon": React.createElement(SearchIcon, {
          "class": "_9fcf3bc7",
        }, null),
      }, null)
    ),
    "resultInfo": "52 results",
    "pagination": React.createElement(_pubF6B296A8_Preview, {
      "class": "_f4e8bcc2 _1dcc2499 _pub-1dcc2499",
    }, null),
  }, null)
}));
export { Preview };

var ModalWrapper = React.memo(React.forwardRef(function ModalWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_d4dbce00 _1dcc2499 _pub-1dcc2499",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { ModalWrapper };

var FilterModal = React.memo(React.forwardRef(function FilterModal(props, ref) {
  return React.createElement("div", {
    "className": "_f12d15f1 _1dcc2499 _pub-1dcc2499",
    "ref": ref,
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { FilterModal };

var PreviewOuter = React.memo(React.forwardRef(function PreviewOuter(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_1f2374dd _1dcc2499 _pub-1dcc2499" + " " + "v3",
    "ref": ref,
    "humDark": props["humDark"],
  }, 
    React.createElement(Preview, {
      "class": "_24d469e8",
    }, null)
  )
}));


