import React from 'react';
import {
  Button,
  ButtonType,
  ButtonSize,
  Icon,
  StickyFooter,
  Spinner,
} from '@hum/ui-library';

export function FooterCTA({
  onChatWithUs,
  onExploreOpportunity,
  testId,
  showSpinnerButton,
}: {
  testId?: string;
  onChatWithUs: () => void;
  onExploreOpportunity: () => void;
  showSpinnerButton: boolean;
}) {
  return (
    <StickyFooter testId={testId}>
      <Button
        onClick={onChatWithUs}
        type={ButtonType.SECONDARY}
        className="sm:mr-4"
        size={ButtonSize.LG}
      >
        <Icon.User className="mr-2 inline-block" />
        Chat with us
      </Button>
      <Button
        onClick={onExploreOpportunity}
        type={ButtonType.PRIMARY}
        className="mt-4 sm:mt-0 sm:ml-4"
        size={ButtonSize.LG}
        disabled={showSpinnerButton}
      >
        {showSpinnerButton ? (
          <Spinner className="mr-2 inline-block" />
        ) : (
          <Icon.Check className="mr-2 inline-block" />
        )}
        Explore this opportunity
      </Button>
    </StickyFooter>
  );
}
