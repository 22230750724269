import React, { useState } from 'react';
import * as Yup from 'yup';
import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';
import { Box, Form, TextInputField, InputMode } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { Company } from '@hum/types';
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { balanceSheetPartnerFormSaved } from '../../../../actions';

const formatPayload = (
  payload: UpdateSyndicationInvestorInformationFormPayload
) => normalize.company.out(payload.company);

type UpdateSyndicationInvestorInformationFormPayload = {
  company: Pick<Company, 'description' | 'publicDescription'>;
};

export const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    description: Yup.string().required('Description is required'),
    publicDescription: Yup.string().required('Public description is required'),
  }),
});

interface UpdateBalanceSheetPartnerInformationProps {
  company: Company;
  onSuccess: () => void;
  onCancel: () => void;
  'data-testid': string;
}

const prepareForUpdate = (company: Partial<Company> = {}) => {
  return {
    company: {
      description: company.description || '',
      publicDescription: company.publicDescription || '',
    },
  };
};

export const BalanceSheetPartner: React.FC<UpdateBalanceSheetPartnerInformationProps> = ({
  company: currentCompany,
  onSuccess,
  onCancel,
  'data-testid': dataTestId,
}) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAppStore();
  const updateInfo = async (
    payload: UpdateSyndicationInvestorInformationFormPayload
  ) => {
    try {
      setLoading(true);

      const companyId = currentCompany.id;
      const response = await backend.patch(
        `/companies/${companyId}`,
        formatPayload(payload)
      );

      if (response.id) {
        dispatch(balanceSheetPartnerFormSaved({ companyId }));
        toast.success('Updated balance sheet partner successfully!');
        onSuccess();
      }
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.message || 'Failed to balance sheet partner investor.'
      );
    } finally {
      setLoading(false);
    }
  };
  const initialValues = React.useMemo(() => prepareForUpdate(currentCompany), [
    currentCompany,
  ]);

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: updateInfo,
  });

  return (
    <div data-testid={dataTestId}>
      <Form onSubmit={form.handleSubmit}>
        <TextInputField
          inputMode={InputMode.TEXT_AREA}
          label="Private Description"
          inputTestId="company-private-description"
          placeholder="Description that may contain sensitive information"
          {...useField('company.description', 'text', form).input}
        />
        <TextInputField
          inputMode={InputMode.TEXT_AREA}
          label="Public Description"
          inputTestId="company-public-description"
          placeholder="Description of the company that can be made public"
          {...useField('company.publicDescription', 'text', form).input}
        />

        <Box justifySpaceBetween>
          <Button
            type={ButtonType.SECONDARY}
            onClick={() => {
              form.resetForm();
              onCancel();
            }}
            disabled={loading || form.isSubmitting}
            testId="update-company-general-information:cancel-button"
          >
            Cancel
          </Button>

          <Button
            submit
            disabled={loading || form.isSubmitting}
            loading={form.isSubmitting}
            testId="update-company-general-information:submit-button"
          >
            Update
          </Button>
        </Box>
      </Form>
    </div>
  );
};
