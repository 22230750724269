import React, { useState } from 'react';
import clsx from 'clsx';

import { Account, ConnectorOption, ConnectorStatus } from '@hum/types';
import { MoreButton, Text, TextType } from '@hum/ui-library';

import { DeleteConfirmationModal } from '.';

export type ConnectionCardItemProps = {
  connection: ConnectorOption;
};

export const ConnectionCardItem = ({ connection }: ConnectionCardItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const connectorIdentifier = connection.meta?.connectorIdentifier ?? {
    institutionName: '',
    accounts: [],
  };
  const { institutionName, accounts = [] } = connectorIdentifier;
  const accountNames = accounts
    ? `account${accounts.length > 1 ? 's' : ''} ending ${accounts
        .map((account: Account) => account.accountNumber)
        .join(', ')}`
    : '';
  const accountLabel = institutionName || connection.name;

  return (
    <>
      <DeleteConfirmationModal
        connection={connection}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <div className="flex px-4 py-2 items-center border-[#29303D] border-b-2 last:border-0">
        <div className="mr-8 basis-1/3 truncate" title={accountNames}>
          <Text className="truncate" type={TextType.HEADING_S}>
            {accountLabel}
          </Text>
          {accounts.length > 0 && (
            <Text className="truncate" type={TextType.SUBHEADING}>{`${
              accounts.length
            } account${
              accounts.length > 1 ? 's' : ''
            } (${accountNames})`}</Text>
          )}
        </div>
        <div className="flex items-center m-4 grow">
          <div
            className={clsx('m-4 rounded-full w-1.5 h-1.5', {
              'bg-iconOn-positive':
                connection.status === ConnectorStatus.Active,
              'bg-red-400': connection.status === ConnectorStatus.Disconnected,
            })}
          />
          <div className="uppercase">{connection.status}</div>
        </div>
        <MoreButton
          items={[
            {
              label: 'Remove',
              className: '[&>p]:text-red-400',
              onClick: () => setIsOpen(true),
            },
          ]}
        />
      </div>
    </>
  );
};
