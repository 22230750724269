import React from 'react';
import * as styles from './outer.pc';
import { Topbar } from '@hum/icm-app/src/components/Topbar';

export const AdminPage = ({ children }) => {
  return (
    <styles.Container>
      <Topbar />
      <styles.AdminContent>{children}</styles.AdminContent>
    </styles.Container>
  );
};
