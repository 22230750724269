import { getFundingOpportunities, useApiQuery } from '@hum/api';
import { NotificationType } from '@hum/ui-library';
import { useState, useCallback, useEffect } from 'react';

export function useSmartRaiseNotification() {
  const { data: opportunities } = useApiQuery(getFundingOpportunities);
  const showcasedOpportunity = opportunities?.showcased;
  const [notification, setNotification] = useState<NotificationType | null>(
    null
  );
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [closedByUser, setClosedByUser] = useState(false);

  const showNotification = (notification: NotificationType) => {
    setNotification(notification);
    setIsNotificationVisible(true);
  };

  const hideNotification = useCallback(() => {
    setClosedByUser(true);
  }, [setClosedByUser]);

  useEffect(() => {
    if (showcasedOpportunity?.isArchived)
      showNotification(NotificationType.WARNING);
    if (showcasedOpportunity?.isInterested)
      showNotification(NotificationType.COMPLETED);
    if (showcasedOpportunity?.isActive) setIsNotificationVisible(false);
  }, [showcasedOpportunity]);

  return {
    notificationType: notification,
    notificationText:
      notification === NotificationType.COMPLETED
        ? `Your funding expert will reach out shortly to guide you through next steps.`
        : `These preliminary terms are no longer available.`,
    hideNotification,
    isNotificationVisible: isNotificationVisible && !closedByUser,
  };
}
