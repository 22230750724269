import { User, Company } from '@hum/types';
import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

// Entity: Both companies and investors
export enum AdminEntityActionType {
  // Test accounts
  ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED = 'ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED',
  ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_SUCCESS = 'ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_SUCCESS',
  ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_ERROR = 'ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_ERROR',
  // Manage users page
  ADMIN_MANAGE_USERS_RESEND_INVITE_BUTTON_CLICKED = 'ADMIN_MANAGE_USERS_RESEND_INVITE_BUTTON_CLICKED',
  RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS',
  RESEND_INVITE_ERROR = 'RESEND_INVITE_ERROR',
  ADMIN_MANAGE_USERS_DISABLE_USER_BUTTON_CLICKED = 'ADMIN_MANAGE_USERS_DISABLE_USER_BUTTON_CLICKED',
  DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS',
  DISABLE_USER_ERROR = 'DISABLE_USER_ERROR',
  ADMIN_MANAGE_USERS_ENABLE_USER_BUTTON_CLICKED = 'ADMIN_MANAGE_USERS_ENABLE_USER_BUTTON_CLICKED',
  ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS',
  ENABLE_USER_ERROR = 'ENABLE_USER_ERROR',
  ADMIN_MANAGE_USERS_DELETE_USER_BUTTON_CLICKED = 'ADMIN_MANAGE_USERS_DELETE_USER_BUTTON_CLICKED',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',
  ADMIN_MANAGE_USERS_SET_AS_PRIMARY_CONTACT_BUTTON_CLICKED = 'ADMIN_MANAGE_USERS_SET_AS_PRIMARY_CONTACT_BUTTON_CLICKED',
  SET_AS_PRIMARY_CONTACT_SUCCESS = 'SET_AS_PRIMARY_CONTACT_SUCCESS',
  SET_AS_PRIMARY_CONTACT_ERROR = 'SET_AS_PRIMARY_CONTACT_ERROR',
}

// Test accounts
export type AdminEntityTestAccountToggled = BaseAction<
  AdminEntityActionType.ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED,
  {
    companyId: number;
    isTestAccount: boolean;
  }
>;
export type AdminEntityTestAccountToggledSuccess = BaseAction<AdminEntityActionType.ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_SUCCESS>;
export type AdminEntityTestAccountToggledError = BaseAction<AdminEntityActionType.ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_ERROR>;

// Manage users page
export type AdminManageUsersResendInviteButtonClicked = BaseAction<
  AdminEntityActionType.ADMIN_MANAGE_USERS_RESEND_INVITE_BUTTON_CLICKED,
  { user: User }
>;
export type ResendInviteSuccess = BaseAction<AdminEntityActionType.RESEND_INVITE_SUCCESS>;
export type ResendInviteError = BaseAction<AdminEntityActionType.RESEND_INVITE_ERROR>;

export type AdminManageUsersDisableUserButtonClicked = BaseAction<
  AdminEntityActionType.ADMIN_MANAGE_USERS_DISABLE_USER_BUTTON_CLICKED,
  { user: User; companyId: Company['id'] }
>;
export type DisableUserSuccess = BaseAction<
  AdminEntityActionType.DISABLE_USER_SUCCESS,
  { companyId: Company['id'] }
>;
export type DisableUserError = BaseAction<AdminEntityActionType.DISABLE_USER_ERROR>;
export type AdminManageUsersEnableUserButtonClicked = BaseAction<
  AdminEntityActionType.ADMIN_MANAGE_USERS_ENABLE_USER_BUTTON_CLICKED,
  { user: User; companyId: Company['id'] }
>;
export type EnableUserSuccess = BaseAction<
  AdminEntityActionType.ENABLE_USER_SUCCESS,
  { companyId: Company['id'] }
>;
export type EnableUserError = BaseAction<AdminEntityActionType.ENABLE_USER_ERROR>;
export type AdminManageUsersDeleteUserButtonClicked = BaseAction<
  AdminEntityActionType.ADMIN_MANAGE_USERS_DELETE_USER_BUTTON_CLICKED,
  { user: User; companyId: Company['id'] }
>;
export type DeleteUserSuccess = BaseAction<
  AdminEntityActionType.DELETE_USER_SUCCESS,
  { companyId: Company['id'] }
>;
export type DeleteUserError = BaseAction<AdminEntityActionType.DELETE_USER_ERROR>;
export type AdminManageUsersSetAsPrimaryContactButtonClicked = BaseAction<
  AdminEntityActionType.ADMIN_MANAGE_USERS_SET_AS_PRIMARY_CONTACT_BUTTON_CLICKED,
  { user: User; companyId: Company['id'] }
>;
export type SetAsPrimaryContactSuccess = BaseAction<
  AdminEntityActionType.SET_AS_PRIMARY_CONTACT_SUCCESS,
  { companyId: Company['id'] }
>;
export type SetAsPrimaryContactError = BaseAction<AdminEntityActionType.SET_AS_PRIMARY_CONTACT_ERROR>;

// Test accounts
export const adminEntityTestAccountToggled = actionCreator<AdminEntityTestAccountToggled>(
  AdminEntityActionType.ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED
);
export const adminEntityTestAccountToggledSuccess = actionCreator<AdminEntityTestAccountToggledSuccess>(
  AdminEntityActionType.ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_SUCCESS
);
export const adminEntityTestAccountToggledError = actionCreator<AdminEntityTestAccountToggledError>(
  AdminEntityActionType.ADMIN_ENTITY_TEST_ACCOUNT_TOGGLED_ERROR
);

// Manage users page
export const adminManageUsersResendInviteButtonClicked = actionCreator<AdminManageUsersResendInviteButtonClicked>(
  AdminEntityActionType.ADMIN_MANAGE_USERS_RESEND_INVITE_BUTTON_CLICKED
);
export const resendInviteSuccess = actionCreator<ResendInviteSuccess>(
  AdminEntityActionType.RESEND_INVITE_SUCCESS
);
export const resendInviteError = actionCreator<ResendInviteError>(
  AdminEntityActionType.RESEND_INVITE_ERROR
);
export const adminManageUsersDisableUserButtonClicked = actionCreator<AdminManageUsersDisableUserButtonClicked>(
  AdminEntityActionType.ADMIN_MANAGE_USERS_DISABLE_USER_BUTTON_CLICKED
);
export const disableUserSuccess = actionCreator<DisableUserSuccess>(
  AdminEntityActionType.DISABLE_USER_SUCCESS
);
export const disableUserError = actionCreator<DisableUserError>(
  AdminEntityActionType.DISABLE_USER_ERROR
);
export const adminManageUsersEnableUserButtonClicked = actionCreator<AdminManageUsersEnableUserButtonClicked>(
  AdminEntityActionType.ADMIN_MANAGE_USERS_ENABLE_USER_BUTTON_CLICKED
);
export const enableUserSuccess = actionCreator<EnableUserSuccess>(
  AdminEntityActionType.ENABLE_USER_SUCCESS
);
export const enableUserError = actionCreator<EnableUserError>(
  AdminEntityActionType.ENABLE_USER_ERROR
);
export const adminManageUsersDeleteUserButtonClicked = actionCreator<AdminManageUsersDeleteUserButtonClicked>(
  AdminEntityActionType.ADMIN_MANAGE_USERS_DELETE_USER_BUTTON_CLICKED
);
export const deleteUserSuccess = actionCreator<DeleteUserSuccess>(
  AdminEntityActionType.DELETE_USER_SUCCESS
);
export const deleteUserError = actionCreator<DeleteUserError>(
  AdminEntityActionType.DELETE_USER_ERROR
);
export const adminManageUsersSetAsPrimaryContactButtonClicked = actionCreator<AdminManageUsersSetAsPrimaryContactButtonClicked>(
  AdminEntityActionType.ADMIN_MANAGE_USERS_SET_AS_PRIMARY_CONTACT_BUTTON_CLICKED
);
export const setAsPrimaryContactSuccess = actionCreator<SetAsPrimaryContactSuccess>(
  AdminEntityActionType.SET_AS_PRIMARY_CONTACT_SUCCESS
);
export const setAsPrimaryContactError = actionCreator<SetAsPrimaryContactError>(
  AdminEntityActionType.SET_AS_PRIMARY_CONTACT_ERROR
);

export type AdminActionType = AdminEntityActionType;
export type AdminActions =
  | AdminEntityTestAccountToggled
  | AdminEntityTestAccountToggledSuccess
  | AdminEntityTestAccountToggledError
  | AdminManageUsersResendInviteButtonClicked
  | ResendInviteSuccess
  | ResendInviteError
  | AdminManageUsersDisableUserButtonClicked
  | DisableUserSuccess
  | DisableUserError
  | AdminManageUsersEnableUserButtonClicked
  | EnableUserSuccess
  | EnableUserError
  | AdminManageUsersDeleteUserButtonClicked
  | DeleteUserSuccess
  | DeleteUserError
  | AdminManageUsersSetAsPrimaryContactButtonClicked
  | SetAsPrimaryContactSuccess
  | SetAsPrimaryContactError;
