import { useServiceDataPull } from '@hum/icm-app/src/hooks/useService';
import {
  ConnectorSource,
  Connector,
  ConnectorType,
  CodatConnectorType,
} from '@hum/icm-app/src/state';
import { ConnectorStatus } from '@hum/icm-app/src/backend/api/models/connectorModels';
import { Button, ButtonSize } from '@hum/ui-library';
import React from 'react';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import {
  serviceStartDataPull,
  serviceStartDataPullAirbyte,
  useApiMutation,
} from '@hum/api';

export function DataPullButton({ connector }: { connector: Connector }) {
  const flags = useFlags();
  const { mutate: startDataPull } = useApiMutation(serviceStartDataPull);
  const { mutate: startDataPullAirbyte } = useApiMutation(
    serviceStartDataPullAirbyte
  );
  const { handleStartPullClick: sagaHandleStartPullClick } = useServiceDataPull(
    {
      connector,
    }
  );

  const handleStartPullClick = () => {
    if (connector.connectorSource === ConnectorSource.Airbyte) {
      startDataPullAirbyte({
        companyId: connector.companyId,
        connectorId: connector.id,
      });
    } else {
      startDataPull({
        companyId: connector.companyId,
        connectorId: connector.id,
      });
    }
  };

  // Airbyte connectors do not support any actions
  if (connector.connectorSource === ConnectorSource.Airbyte) return null;

  if (
    connector.connectorSource === ConnectorSource.Codat &&
    (connector.connectorType === ConnectorType.Plaid ||
      connector.connectorType === ConnectorType.Stripe ||
      connector.connectorType === CodatConnectorType.QuickbooksDesktop)
  )
    return null;

  return (
    <Button
      size={ButtonSize.SLIM}
      onClick={
        flags.enabled('use-react-query')
          ? handleStartPullClick
          : sagaHandleStartPullClick
      }
      loading={connector.dataPullInProgress}
      disabled={
        connector.status !== ConnectorStatus.Active ||
        connector.dataPullInProgress
      }
      className="mt-1"
    >
      {connector.dataPullInProgress ? 'Pulling' : 'Start Pull'}
    </Button>
  );
}

export default DataPullButton;
