import React from "react";
import _pub8C547Ae7 from "../../../../../design-system/src/Select.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pubF6B296A8 from "../../../components/Pagination/styles.pc";
import _pub1Dcc2499, {Preview as _pub1Dcc2499_Preview} from "./Filters/styles.pc";
import _pub96C81C86, {Preview as _pub96C81C86_Preview} from "./CompanyList/styles.pc";
import _pubC68937F5, {ScrollablePreview as _pubC68937F5_ScrollablePreview} from "../../../../../design-system/src/Scrollable.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_fbda7ea8 _4cf6a2f7 _pub-4cf6a2f7",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Wrapper };

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_fdef95cc _4cf6a2f7 _pub-4cf6a2f7" + " " + "_4cf6a2f7_v3 _pub-4cf6a2f7_v3 v3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Container };

var SearchIcon = React.memo(React.forwardRef(function SearchIcon(props, ref) {
  return React.createElement("div", {
    "className": "_13e1f4e0 _4cf6a2f7 _pub-4cf6a2f7",
    "ref": ref,
  }, null)
}));
export { SearchIcon };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_fa8251d5 _4cf6a2f7 _pub-4cf6a2f7" + " " + "_4cf6a2f7_v3 _pub-4cf6a2f7_v3 v3",
    "ref": ref,
    "humDark": props["humDark"],
  }, 
    React.createElement(Container, {
      "class": "_a709d206",
    }, 
      React.createElement(_pub1Dcc2499_Preview, {
        "class": "_79adcbcb _4cf6a2f7 _pub-4cf6a2f7",
      }, null),
      React.createElement(_pubC68937F5_ScrollablePreview, {
        "class": "_eaafb5d _4cf6a2f7 _pub-4cf6a2f7",
      }, 
        React.createElement(_pub96C81C86_Preview, {
          "class": "_62507e9b _4cf6a2f7 _pub-4cf6a2f7",
        }, null)
      )
    )
  )
}));


