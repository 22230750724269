import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { adminCompanyInvestorListRequested } from '@hum/icm-app/src/actions';
import { Company } from '@hum/types';
import { Empty } from '@hum/ui-library';
import { Table, TableCellConfig } from '@hum/legacy-ui';
import * as styles from './styles.pc';

const useCompanyInvestors = (companyId: number) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (companyId) {
      dispatch(adminCompanyInvestorListRequested({ companyId }));
    }
  }, [companyId]);

  return state.companyInvestorList;
};
const TABLE_CELLS: Record<string, TableCellConfig> = {
  service: {
    label: `Investor Name`,
    renderer: (investor: any) => {
      return (
        <styles.PortfolioInvestorLink>
          <Link
            to={{
              pathname: 'admin',
              search: `company_id=${investor.item.id}`,
            }}
            className="ui-link"
          >
            {investor.item.name}
          </Link>
        </styles.PortfolioInvestorLink>
      );
    },
  },
};

export const CompanyInvestors: React.FC<{ company: Company }> = ({
  company,
}) => {
  const { data: investors = [] } = useCompanyInvestors(company?.id);

  if (investors.length === 0) {
    return (
      <Empty message="There are no associated investors with this company." />
    );
  }

  return <Table cellConfig={TABLE_CELLS} itemKey="id" data={investors!} />;
};
