import React from 'react';
import { Icon, Text, TextType } from '@hum/ui-library';

export type ConnectionCardTitleProps = {
  icon?: string;
  label: string;
};

export const ConnectionCardTitle = ({
  icon,
  label,
}: ConnectionCardTitleProps) => {
  return (
    <div className="bg-surface-subdued flex">
      {icon && <Icon className="m-4" src={icon} />}
      <Text className="m-4 ml-0" type={TextType.BODY_S}>
        {label}
      </Text>
    </div>
  );
};
