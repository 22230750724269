import { AnalayticsActionType, AnalyticsEventTrackRequested } from './actions';
import { takeEvery } from 'redux-saga/effects';
import { trackAnalayticsEvent } from '../utils';

export function* analyticsSaga() {
  yield takeEvery(
    AnalayticsActionType.ANALYTICS_EVENT_TRACK_REQUESTED,
    handleAnalyticsEvents
  );
}

function* handleAnalyticsEvents({
  payload: { name, data, includeRoleScope },
}: AnalyticsEventTrackRequested) {
  yield trackAnalayticsEvent(name, data, includeRoleScope);
}
