import React from 'react';

import { TabNavigator, Tab } from '@hum/legacy-ui';
import { Company, CompanyType } from '@hum/types';

import { CompanyDetailsV5 } from './CompanyDetails/CompanyDetailsV5';
import { CapitalApplication } from './CapitalApplication';
import { SyndicationInvestor } from './SyndicationInvestor';
import { BalanceSheetPartner } from './BalanceSheetPartner';
import { Tags } from './Tags';

interface UpdateCompanyInformationProps {
  company: Company;
  onCancel: () => void;
}

export const UpdateCompanyInformation: React.FC<UpdateCompanyInformationProps> = React.memo(
  ({ company, onCancel }) => {
    const isNormalCompany = company.type.includes(CompanyType.Company);
    const isSyndicationInvestor = company.type.includes(
      CompanyType.SyndicationInvestor
    );
    const isBalanceSheetPartner = company.type.includes(
      CompanyType.BalanceSheetPartner
    );
    return (
      <TabNavigator
        id="update-company-information-tab"
        data-testid="update-company-information-tabs"
        horizontal
        paddingBottom
      >
        <Tab
          label="General"
          data-testid="update-company-information-tabs:general_information_tab:header"
        >
          <CompanyDetailsV5
            onSuccess={onCancel}
            onCancel={onCancel}
            company={company}
            data-testid="update-company-information-tabs:general_information_tab:body"
          />
        </Tab>

        {isNormalCompany && (
          <Tab
            label="Company Application"
            data-testid="update-company-information-tabs:capital_application_tab:header"
          >
            <CapitalApplication
              onSuccess={onCancel}
              onCancel={onCancel}
              company={company}
              data-testid="update-company-information-tabs:capital_application_information_tab:body"
            />
          </Tab>
        )}

        <Tab
          label="Tags"
          data-testid="update-company-information-tabs:tags_tab:header"
        >
          <Tags
            onSuccess={onCancel}
            onCancel={onCancel}
            company={company}
            data-testid="update-company-information-tabs:tags_tab:body"
          />
        </Tab>

        {isSyndicationInvestor && (
          <Tab
            label="Syndication Investor"
            data-testid="update-company-information-tabs:syndication_investor_information_tab:header"
          >
            <SyndicationInvestor
              onSuccess={onCancel}
              onCancel={onCancel}
              company={company}
              data-testid="update-company-information-tabs:syndication_investor_information_tab:body"
            />
          </Tab>
        )}
        {isBalanceSheetPartner && (
          <Tab
            label="Balance Sheet Partner"
            data-testid="update-company-information-tabs:balance_sheet_partner_information_tab:header"
          >
            <BalanceSheetPartner
              onSuccess={onCancel}
              onCancel={onCancel}
              company={company}
              data-testid="update-company-information-tabs:balance_sheet_partner_information_tab:body"
            />
          </Tab>
        )}
      </TabNavigator>
    );
  }
);
UpdateCompanyInformation.displayName = 'UpdateCompanyInformation';
