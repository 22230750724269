export const createDownloadFile = (
  file: Blob,
  extension: string,
  name?: string
) => {
  const url = URL.createObjectURL(file);
  const link = document.createElement('a');
  //We set date when user click the button
  const date = new Date();
  const day = `${date.getDate()}`.padStart(2, '0');
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const year = `${date.getFullYear()}`.substring(2);
  const secureName = name ? name?.replace(/[^a-z0-9]/gi, '') : 'Company';
  link.href = url;
  link.setAttribute(
    'download',
    `${secureName}_HumCapitalAnalytics${month}.${day}.${year}.${extension}`
  );
  document.body.appendChild(link);
  link.click();
};
