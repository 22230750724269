import React, { useState } from 'react';
import clsx from 'clsx';
import { Tab, TabProps } from './Tab';

export type TabsProps = {
  tabs: TabProps[];
  defaultTabIndex?: number;
};

export const Tabs = ({ tabs, defaultTabIndex = 0 }: TabsProps) => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  return (
    <div>
      <div className="flex">
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            {...tab}
            isActive={index === tabIndex}
            className={clsx({
              'ml-0': index === 0,
            })}
            onClick={() => setTabIndex(index)}
          />
        ))}
        <div className="border-borderColor border-b-2 grow" />
      </div>
      {tabs[tabIndex].children}
    </div>
  );
};
