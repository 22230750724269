import React from 'react';
import { Company } from '@hum/types';
import { createUrlFromTemplate } from '@hum/icm-app/src/state';
import { Detail } from '@hum/legacy-ui';
import { Routes } from '@hum/icm-app/src/state/routes';
import { formatDate } from '@hum/icm-app/src/utils';
import { useSession } from '@hum/icm-app/src/hooks/useSession';

export const AdminSidebar = ({ company }: { company: Company }) => {
  const { isAdmin } = useSession();
  const profileV2Uri =
    //eslint-disable-next-line
    process.env.V2_BASE_URL +
    createUrlFromTemplate(`${Routes.COMPANY_PROFILE_V2}`, {
      companyId: company.id.toString(),
    });

  return (
    <div className="border-l-1 p-7">
      {isAdmin && (
        <>
          <Detail
            title="Data Sharing"
            description={
              <a
                href={`${Routes.COMPANY_ACTIVATION_CONNECTORS_ACCOUNTING}/?company_id=${company.id}`}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="admin-page:company-sidebar:open-connectors-link"
              >
                Open →
              </a>
            }
            className="pb-7"
          />
          <Detail
            title="Profile"
            description={
              <a
                href={profileV2Uri}
                rel="noopener noreferrer"
                data-testid="admin-page:company-sidebar:profile-link"
              >
                Open →
              </a>
            }
            className="pb-7"
          />

          <Detail
            title="Insights"
            description={
              <a
                href={createUrlFromTemplate(
                  `${Routes.ADMIN_COMPANY_ANALYTICS}`,
                  { companyId: company.id.toString() }
                )}
                rel="noopener noreferrer"
                data-testid="admin-page:manage-insights:privacy-link"
              >
                Manage Insights →
              </a>
            }
            className="pb-7"
          />
          <Detail
            title="Privacy/MNDA"
            description={
              <a
                href={`${Routes.SETTINGS_DOCUMENTS}?company_id=${company.id}`}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="admin-page:company-sidebar:privacy-link"
              >
                Privacy/MNDA →
              </a>
            }
            className="pb-7"
          />
          <Detail
            title="Labeling UI"
            description={
              <a
                href={`${Routes.LABEL_MAPPER}?company_id=${company.id}`}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="admin-page:company-sidebar:privacy-link"
              >
                Labeling UI →
              </a>
            }
            className="pb-7"
          />
        </>
      )}

      <Detail
        title="Created"
        description={formatDate(company.createdAt)}
        className="pb-7"
      />
      <Detail title="Updated" description={formatDate(company.updatedAt)} />
    </div>
  );
};
