import React from 'react';

import { Company, CompanyContact } from '@hum/types';
import { DescriptionList, InfoSection, Text, TextType } from '@hum/ui-library';
import { InvestmentProfile } from './InvestmentProfile';
import { ContactDetails } from './ContactDetails';
import { BrandingDetails } from './BrandingDetails';
import { SyndicationLegalAgreements } from './SyndicationLegalAgreements';
import ReactMarkdown from 'react-markdown';

export const InvestorOverview = ({
  company,
  companyContact,
}: {
  company: Company;
  companyContact?: CompanyContact;
}) => {
  return (
    <>
      <InfoSection title="General Info">
        <DescriptionList>
          <dt>
            <Text type={TextType.BODY_S} subdued>
              Website
            </Text>
          </dt>
          <dd data-testid="admin-page:main-area:overview:investment-overview:website">
            <Text type={TextType.BODY_S}>
              {company.website ? (
                <a
                  href={company.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  {company.website}
                </a>
              ) : (
                '–'
              )}
            </Text>
          </dd>

          {company.syndicationInvestor && (
            <>
              <dt>
                <Text type={TextType.BODY_S} subdued>
                  Location
                </Text>
              </dt>
              <dd data-testid="admin-page:main-area:overview:investment-overview:location">
                <Text type={TextType.BODY_S}>
                  {company.syndicationInvestor.location || '-'}
                </Text>
              </dd>
            </>
          )}

          <dt>
            <Text type={TextType.BODY_S} subdued>
              Description
            </Text>
          </dt>
          <dd data-testid="admin-page:main-area:overview:investment-overview:private-description">
            {company.description ? (
              <ReactMarkdown
                children={company.description}
                components={{
                  p: ({ children }) => (
                    <Text type={TextType.BODY_S}>{children}</Text>
                  ),
                }}
              />
            ) : (
              '-'
            )}
          </dd>

          <dt>
            <Text type={TextType.BODY_S} subdued>
              Public description
            </Text>
          </dt>
          <dd data-testid="admin-page:main-area:overview:investment-overview:public-description">
            {company.publicDescription ? (
              <ReactMarkdown
                children={company.publicDescription}
                components={{
                  p: ({ children }) => (
                    <Text type={TextType.BODY_S}>{children}</Text>
                  ),
                }}
              />
            ) : (
              '-'
            )}
          </dd>
        </DescriptionList>
      </InfoSection>
      <ContactDetails companyContact={companyContact} />
      <InvestmentProfile company={company} />
      <BrandingDetails company={company} />
      <SyndicationLegalAgreements company={company} />
    </>
  );
};
