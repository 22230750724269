import React from 'react';

import * as Yup from 'yup';
import { useForm, useField } from '@hum/common/src/modules/form';
import { SelectInputField, Fieldset } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { useAvailableBalanceSheetPartners } from '@hum/icm-app/src/hooks/useBalanceSheetPartner';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { apiPrincipalInvestmentStateChanged } from '@hum/icm-app/src/actions';
import * as styles from './index.pc';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

const initialValues = {
  balanceSheetPartnerCompanyId: '',
};

// prettier-ignore
const validationSchema = Yup.object().shape({
  balanceSheetPartnerCompanyId: Yup.string().required('Required'),
})

declare type Props = {
  companyId: number;
  closeModal: () => void;
};

export const AddBalanceSheetPartner: React.FC<Props> = ({
  companyId,
  closeModal,
}) => {
  const api = useAPI();
  const { loaded, companies } = useAvailableBalanceSheetPartners(companyId);
  const { dispatch } = useAppStore();

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: async (payload, { resetForm }) => {
      await api.principalInvestment
        .addBalanceSheetPartnerToPrincipalInvestment(companyId, payload)
        .then((response) => {
          if (response) {
            dispatch(apiPrincipalInvestmentStateChanged({ companyId }));
          }
          closeModal();
          resetForm();
        });
    },
  });

  const closeForm = () => {
    closeModal();
    form.resetForm();
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <Fieldset>
        <SelectInputField
          inputTestId="search-balance-sheet-partner"
          inputAriaLabel="Company"
          options={companies}
          label="Company"
          disabled={!companies.length}
          loading={!loaded}
          {...useField('balanceSheetPartnerCompanyId', 'select', form).input}
        />
      </Fieldset>

      {loaded && companies.length == 0 ? (
        <styles.Error>
          There are not balance sheet partners available
        </styles.Error>
      ) : null}

      <Button submit disabled={form.isSubmitting} loading={form.isSubmitting}>
        Add
      </Button>

      <Button
        type={ButtonType.SECONDARY}
        onClick={() => closeForm()}
        disabled={form.isSubmitting}
      >
        Cancel
      </Button>
    </form>
  );
};
