import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Error = React.memo(React.forwardRef(function Error(props, ref) {
  return React.createElement("div", {
    "className": "_1177cd3d _357b9c2e _pub-357b9c2e _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Error };


