import React, { useState, useMemo, useEffect } from 'react';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import {
  FileManager,
  UploadProgress,
} from '../../../__DEPRECATED__modules/files';
import {
  CompanyFileDocumentType,
  Company,
  FileStatus,
  CompanyFile,
} from '@hum/types';
import { toast } from '@hum/common/src/modules/toast';
import { FileIcon, Pill } from '@hum/legacy-ui';
import { Button, ButtonSize, ButtonType } from '@hum/ui-library';
import * as styles from './styles.pc';
import { formatDate } from '../../../utils';
import { DataMapForm, DataMapInfo } from './DataMap';
import { useInterval } from '@hum/icm-app/src/hooks/useInterval';

type Props = {
  company: Company;
  title: string;
  documentType: CompanyFileDocumentType;
  fileManager: FileManager;
  dataMapInfo?: DataMapInfo;
  canDelete?: boolean;
};

const Documents: React.FC<Props> = ({
  company,
  title,
  documentType,
  fileManager,
  dataMapInfo,
  canDelete = false,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [dataMapModalOpen, setDataMapModalOpen] = useState(false);

  const {
    files: allFiles,
    progress,
    createFromLocalFile,
    downloadFile,
    removeFile,
    loadFiles,
  } = fileManager;

  const files = useMemo(
    () => allFiles.filter((file) => file.documentType === documentType),
    [allFiles]
  );

  const {
    external: externalFiles = [],
    internal: internalFiles = [],
  } = useMemo(
    () => groupBy(files, (file) => (file.isExternal ? 'external' : 'internal')),
    [files]
  );

  const internalFilesToCheckStatus = internalFiles.filter((file) =>
    [
      CompanyFileDocumentType.CleanedIncomeStatement,
      CompanyFileDocumentType.CleanedBalanceSheet,
      CompanyFileDocumentType.CleanedCustomerTransactions,
    ].includes(file.documentType)
  );

  const filesStillProcessing = internalFilesToCheckStatus.filter((file) =>
    [FileStatus.Processing, FileStatus.Pending].includes(file.ingestionStatus)
  );
  const areFilesStillProcessing = filesStillProcessing.length > 0;

  useInterval(
    () => {
      loadFiles();
    },
    areFilesStillProcessing ? 3000 : null
  ); // stop interval when all files are processed

  useEffect(() => {
    if (!areFilesStillProcessing && internalFilesToCheckStatus.length) {
      toast.success('All files have finished processing');
    }
  }, [areFilesStillProcessing]);

  const externalFilesBySource = groupBy(externalFiles, (file) =>
    file.source ? file.source.url : 'unknown'
  );

  const [currentFileId, setCurrentFileId] = useState<number | null>();

  const openLocalFileSelect = () =>
    fileInputRef.current && fileInputRef.current.click();

  const onLocalFileSelect: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedFiles = Array.from(e.target.files || []);

    // cleanup selection
    e.target.value = '';

    for (const file of selectedFiles) {
      createFromLocalFile({
        input: { name: file.name, type: file.type, documentType },
        file,
      });
    }
  };

  const mapFile = async (file: CompanyFile) => {
    setCurrentFileId(file.id);
    setDataMapModalOpen(true);
  };

  return (
    <>
      {currentFileId && dataMapModalOpen && (
        <DataMapForm
          dataMapInfo={dataMapInfo!}
          fileId={currentFileId}
          company={company}
          onSaved={() => setDataMapModalOpen(false)}
          onCancel={() => setDataMapModalOpen(false)}
        />
      )}
      <styles.Item
        header={
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={onLocalFileSelect}
            className="hidden"
          />
        }
        subtitle={title}
        onPlusButtonClick={openLocalFileSelect}
      >
        {internalFiles.map((file) => {
          const fileStatus = file.ingestionStatus;

          return (
            <styles.FileItem
              icon={<FileIcon size={20} mimetype={file.type} />}
              lower={
                <>
                  <UploadProgress progress={progress} id={file.id} />
                  {[
                    CompanyFileDocumentType.CleanedIncomeStatement,
                    CompanyFileDocumentType.CleanedBalanceSheet,
                    CompanyFileDocumentType.CleanedCustomerTransactions,
                  ].includes(file.documentType) ? (
                    <Pill
                      positive={fileStatus === FileStatus.Success}
                      negative={fileStatus === FileStatus.Failed}
                      info={fileStatus === FileStatus.Processing}
                      title={file.meta?.error_message || ''}
                    >
                      {fileStatus}
                    </Pill>
                  ) : null}
                </>
              }
              controls={
                <>
                  {dataMapInfo && (
                    <Button
                      size={ButtonSize.SLIM}
                      onClick={() => mapFile(file)}
                    >
                      map
                    </Button>
                  )}
                  <Button
                    size={ButtonSize.SLIM}
                    onClick={() => downloadFile(file.id)}
                  >
                    download
                  </Button>
                </>
              }
              key={file.id}
              title={`${file.name}.${file.ext}`}
              timestamp={formatDate(file.updatedAt)}
              fileName={
                <>
                  {file.name}.{file.ext}
                </>
              }
              deleteButton={
                canDelete && (
                  <>
                    <Button
                      size={ButtonSize.SLIM}
                      type={ButtonType.SECONDARY}
                      onClick={async () => {
                        await removeFile(file.id);
                      }}
                    >
                      <styles.XCircle />
                    </Button>
                  </>
                )
              }
            />
          );
        })}

        {toPairs(externalFilesBySource).map(([source, sourceFiles]) => (
          <>
            <styles.ExternalFileItem source={source}>
              {sourceFiles.map((file) => (
                <styles.ExternalFileItemLink
                  href={file.source ? file.source.url : undefined}
                  fileName={
                    <>
                      {file.source && file.source.path}/{file.name}.{file.ext}
                    </>
                  }
                  fileDate={formatDate(file.createdAt)}
                />
              ))}
            </styles.ExternalFileItem>
          </>
        ))}
      </styles.Item>
    </>
  );
};

export { Documents };
