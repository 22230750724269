import React from 'react';

import { CompanyType } from '@hum/types';
import { Pill } from '@hum/legacy-ui';

const CompanyTypeToText = {
  [CompanyType.Investor]: 'Portfolio Investor',
  [CompanyType.SyndicationInvestor]: 'Syndication Investor',
  [CompanyType.BalanceSheetPartner]: 'Balance Sheet Partner',
};

export const CompanyTypeTag: React.FC<{
  type: CompanyType[];
}> = ({ type }) => {
  const isPortfolioInvestor = type.includes(CompanyType.Investor);
  const isSyndicationInvestor = type.includes(CompanyType.SyndicationInvestor);
  const isBalanceSheetPartner = type.includes(CompanyType.BalanceSheetPartner);

  // Do not render tag if there isn't a match
  if (
    ![isPortfolioInvestor, isSyndicationInvestor, isBalanceSheetPartner].some(
      (t) => t
    )
  ) {
    return null;
  }

  return (
    <>
      {isPortfolioInvestor && (
        <Pill notice bold>
          {CompanyTypeToText[CompanyType.Investor]}
        </Pill>
      )}
      {isSyndicationInvestor && (
        <Pill info bold>
          {CompanyTypeToText[CompanyType.SyndicationInvestor]}
        </Pill>
      )}
      {isBalanceSheetPartner && (
        <Pill positive bold>
          {CompanyTypeToText[CompanyType.BalanceSheetPartner]}
        </Pill>
      )}
    </>
  );
};
