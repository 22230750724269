import { Action } from '@hum/icm-app/src/actions';
import { AppState } from '@hum/icm-app/src/state';
import { ManageInsightsActionType } from '../actions';
import { produce } from 'immer';

export const adminAnalyticsReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case ManageInsightsActionType.MANAGE_INSIGHTS_SETTINGS_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.manageInsightsSettings = action.payload;
      });
    }

    default:
      return state;
  }
};
