import React, { useState, useMemo } from 'react';
import * as Yup from 'yup';

import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';
import { Box, Form, SelectInputField } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { Company } from '@hum/types';
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

import { adminEditCompanyFormClosed } from '../../../../actions';
import { getTags, useApiQuery } from '@hum/api';

export const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    tags: Yup.array().nullable(),
  }),
});

interface UpdateCapitalApplicationInformationProps {
  company: Company;
  onSuccess: () => void;
  onCancel: () => void;
  'data-testid': string;
}

const prepareForUpdate = (company: Company) => ({
  company: {
    tags: company.tags || [],
  },
});

export const Tags: React.FC<UpdateCapitalApplicationInformationProps> = ({
  company: currentCompany,
  onSuccess,
  onCancel,
  'data-testid': dataTestId,
}) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAppStore();
  const { data: TAG_OPTIONS, isLoading: loadingTags } = useApiQuery(getTags);

  const initialValues = useMemo(() => prepareForUpdate(currentCompany), [
    currentCompany,
  ]);

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: async (payload) => {
      try {
        setLoading(true);

        await backend.patch(
          `/companies/${currentCompany.id}/tags`,
          normalize.tags.out(payload.company.tags)
        );

        dispatch(adminEditCompanyFormClosed({ companyId: currentCompany.id }));
        toast.success('Updated tags');
        onSuccess();
      } catch (error: any) {
        console.error(error);
        toast.error(error?.message || 'Failed to update tags.');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div data-testid={dataTestId}>
      <Form onSubmit={form.handleSubmit}>
        <SelectInputField
          label="Tags"
          inputAriaLabel="Tags"
          placeholder={loadingTags ? 'Loading tags...' : 'Select tags'}
          options={TAG_OPTIONS}
          isMulti
          isCreatable
          isMultiClearable
          {...useField('company.tags', 'select', form).input}
        />

        <Box justifySpaceBetween>
          <Button
            type={ButtonType.SECONDARY}
            onClick={() => {
              form.resetForm();
              onCancel();
            }}
            disabled={loading || form.isSubmitting}
            testId="update-company-information-tabs:capital_application_information_tab:body:cancel-button"
          >
            Cancel
          </Button>

          <Button
            submit
            disabled={loading || form.isSubmitting}
            loading={form.isSubmitting}
            testId="update-company-information-tabs:capital_application_information_tab:body:submit-button"
          >
            Update
          </Button>
        </Box>
      </Form>
    </div>
  );
};
