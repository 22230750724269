import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Tippy from '@tippy.js/react';

import { CompanyType } from '@hum/types';
import { AdminSidebar } from './CompanySidebar';
import { AdminTitle } from './AdminTitle';
import { Sidebar } from './styles.pc';
import { CompanyOverview } from './CompanyOverview';
import { InvestorOverview } from './InvestorOverview';
import { CompanySyndication } from './tabs/Syndication/CompanySyndication';
import { CompanyPrincipalInvestment } from './CompanyPrincipalInvestment';
import { AdminPage } from './outer';
import { Settings } from './tabs/Settings';
import { format } from 'url';
import * as Icons from '@hum/icm-app/src/components/Icons';
import { Modal } from '@hum/design-system';
import { TabNavigator, Tab, MetaTitle } from '@hum/legacy-ui';
import {
  Button,
  ButtonType,
  Scrollable,
  Spinner,
  Text,
  TextType,
} from '@hum/ui-library';
import AddCompanyV5 from './AddCompany/AddCompanyV5';
import { AddPortfolioInvestor } from './AddPortfolioInvestor';
import AddBalanceSheetPartner from './AddBalanceSheetPartner';
import { Users } from './tabs/Users';
import { CompanyManager } from './CompanyManager';
import { useCompany } from '@hum/icm-app/src/hooks/useCompany';
import { useCompanyContact } from '@hum/icm-app/src/hooks/useCompanyContact';
import { useUrlQuery } from '@hum/common/src/hooks/useUrlQuery';
import { DataTab } from './tabs/Data';
import { Analytics, AnalyticsTab } from './tabs/Analytics';
import { InviteCompanies } from './tabs/InviteCompanies';
import { UpdateCompanyInformation } from './UpdateCompanyInformation';
import { InvestorSyndication } from './tabs/Syndication/InvestorSyndication';
import { AdminLeftSidebar } from './LeftSidebar';
import { analytics } from '@hum/common';
import { getCompanyContact, useApiQuery } from '@hum/api';
import { useFlags } from '../../hooks/useFlags';

dayjs.extend(relativeTime);

export const ADMIN_COMPANY_DETAIL_TAB_ID = 'admin-company-detail';

export const Admin = () => {
  const flags = useFlags();
  const history = useHistory();
  const [{ company_id }] = useUrlQuery();
  const companyId = Number(company_id) || 0;
  const { data: company } = useCompany(companyId);
  const sagasCompanyContact = useCompanyContact(companyId);
  const {
    data: queryCompanyContact,
    isLoading: companyContactIsLoading,
  } = useApiQuery(getCompanyContact, {
    params: { companyId },
    enabled: flags.enabled('use-react-query') && companyId > 0,
  });
  const companyContact = flags.enabled('use-react-query')
    ? {
        data: queryCompanyContact,
        loaded: !companyContactIsLoading,
      }
    : sagasCompanyContact;

  // Add company form
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [companyUpdateModalOpen, setCompanyUpdateModal] = useState(false);
  const isCreditAnalystDemo = flags.enabled('demo:credit-analyst');
  const isCompanyDemo = flags.enabled('demo:company');
  const isPortfolioInvestorDemo = flags.enabled('demo:portfolio-investor');
  const isDemo =
    isCreditAnalystDemo || isCompanyDemo || isPortfolioInvestorDemo;

  const removeQuery = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('update-company-information-tab');
    history.push(format({ search: params.toString() }));
  };
  const openCompanyUpdateForm = () => {
    setCompanyUpdateModal(true);
    // @ts-ignore
    analytics.track('admin:open_update_company_modal');
  };

  const onCloseUpdateCompany = () => {
    setCompanyUpdateModal(false);
    removeQuery();
  };

  const openCompanyForm = () => {
    setCompanyModalOpen(true);
    // @ts-ignore
    analytics.track('admin:open_add_company_modal');
  };

  const onCloseAddCompany = () => {
    setCompanyModalOpen(false);
  };

  const companyIsPortfolioInvestor =
    company?.type.includes(CompanyType.Investor) || false;
  const companyIsSyndicationInvestor =
    company?.type.includes(CompanyType.SyndicationInvestor) || false;
  const companyIsBalanceSheetPartner =
    company?.type.includes(CompanyType.BalanceSheetPartner) || false;
  const companyIsCompany = company?.type.includes(CompanyType.Company) || false;
  const companyIsAdmin = company?.type.includes(CompanyType.Admin) || false;

  // Add portfolio investor form
  const [portfolioInvestorModalOpen, setPortfolioInvestorModal] = useState(
    false
  );

  const openPortfolioInvestorForm = () => {
    setPortfolioInvestorModal(true);
  };

  const onClosePortfolioInvestor = () => {
    setPortfolioInvestorModal(false);
  };

  // Add balance sheet partner form
  const [
    balanceSheetPartnerModalOpen,
    setBalanceSheetPartnerModalOpen,
  ] = useState(false);

  const openBalanceSheetPartnerForm = () => {
    setBalanceSheetPartnerModalOpen(true);
  };

  const onCloseBalanceSheetPartner = () => {
    setBalanceSheetPartnerModalOpen(false);
  };

  // remove hubspot if we're rendering the admin page
  // this should not show up but may exist in the login page, so we
  // make sure we remove it when we're redirected here because the hubspot
  // client is not listening for pathname changes
  useEffect(() => {
    document.getElementById('hubspot-messages-iframe-container')?.remove();
  }, []);

  useEffect(() => {
    if (isDemo) {
      //eslint-disable-next-line
      window.location.assign(`${process.env.V2_BASE_URL}/`);
    }
  }, []);

  if (!flags.ready) return <Spinner fullScreen />;

  return (
    <>
      <MetaTitle>{company?.name || 'Select a company'}</MetaTitle>
      <AdminPage>
        <Sidebar testId="admin-page:side-bar">
          <>
            <div className="flex px-3 pt-3" style={{ minHeight: '52px' }}>
              <Button
                testId="add-company-button"
                onClick={() => openCompanyForm()}
                fullWidth
              >
                <Icons.Plus /> Add company
              </Button>
              <Tippy
                interactive
                hideOnClick
                trigger="click"
                aria={null}
                role="menu"
                appendTo="parent"
                distance={0}
                animateFill={false}
                animation="shift-toward"
                placement="bottom-end"
                content={
                  <div
                    className="px-3 py-5"
                    data-testid="admin-page:side-bar:create-companies-menu"
                  >
                    <Button
                      fullWidth
                      onClick={() => openPortfolioInvestorForm()}
                      testId="admin-page:side-bar:create-companies-menu:create-portfolio-investor-button"
                      className="mb-3"
                    >
                      <Icons.Plus /> Add portfolio investor
                    </Button>
                    <br />
                    <Button
                      fullWidth
                      onClick={() => openBalanceSheetPartnerForm()}
                      className="mb-3"
                      testId="admin-page:side-bar:create-companies-menu:create-balance-sheet-partner-button"
                    >
                      <Icons.Plus /> Add balance sheet partner
                    </Button>
                  </div>
                }
              >
                {/* Tippy does not support React 18 elements */}
                <button
                  className="inline-flex items-center px-3 ml-2 bg-cta-primary-default hover:bg-cta-primary-hover rounded-cta"
                  data-testid="admin-page:side-bar:create-companies-menu:open-button"
                >
                  <Icons.Plus />
                </button>
              </Tippy>
            </div>
            {companyUpdateModalOpen && (
              <Modal
                onClose={onCloseUpdateCompany}
                testId="update-company-information-modal"
                header="Update company information"
                veryWide
                fullHeight
                scrollable
              >
                <UpdateCompanyInformation
                  company={company!}
                  onCancel={onCloseUpdateCompany}
                />
              </Modal>
            )}
            <AddCompanyV5
              title="Add a new company"
              onClose={onCloseAddCompany}
              visible={companyModalOpen}
            />

            <AddPortfolioInvestor
              title="Add portfolio investor"
              onClose={onClosePortfolioInvestor}
              visible={portfolioInvestorModalOpen}
            />

            <AddBalanceSheetPartner
              title="Add balance sheet partner"
              onClose={onCloseBalanceSheetPartner}
              visible={balanceSheetPartnerModalOpen}
            />
          </>

          <AdminLeftSidebar currentCompanyId={companyId} />
        </Sidebar>
        <Scrollable className="relative w-full adminContent">
          <main
            className="flex flex-grow h-full"
            data-testid="admin-page:main-area"
          >
            {companyId === 0 && (
              <div
                className="flex flex-col items-center justify-center w-full h-full"
                data-testid="admin:empty-companies-message"
              >
                <Text type={TextType.BODY_L}>
                  Please, select a company to the left
                </Text>
              </div>
            )}
            {companyId && company?.id ? (
              <>
                <Scrollable className="flex-grow w-3/4 adminCompany">
                  <div className="h-full px-12 py-8">
                    <div className="flex items-start justify-between mb-8">
                      <AdminTitle
                        name={company.name}
                        website={company.website}
                        type={company.type}
                        tags={company.tags}
                        segment={company.application.segment}
                      />
                      <Button
                        type={ButtonType.QUIET}
                        testId="admin:update_company_button"
                        onClick={() => {
                          openCompanyUpdateForm();
                        }}
                      >
                        <Icons.Edit />
                      </Button>
                    </div>

                    <TabNavigator
                      horizontal
                      id={ADMIN_COMPANY_DETAIL_TAB_ID}
                      data-testid="admin-page:main-area:company-tab-list"
                    >
                      {!companyIsAdmin && (
                        <Tab label="Overview" data-testid="admin:overview_tab">
                          <div data-testid="admin-page:main-area:overview">
                            {companyIsCompany && (
                              <CompanyOverview
                                companyContact={companyContact}
                                company={company}
                              />
                            )}
                            {(companyIsSyndicationInvestor ||
                              companyIsBalanceSheetPartner ||
                              companyIsPortfolioInvestor) && (
                              <InvestorOverview
                                company={company}
                                companyContact={companyContact.data}
                              />
                            )}
                          </div>
                        </Tab>
                      )}
                      {/* Note, this is rendered individually instead of in a fragment because the TabList component, when iterating, considers the React.Fragment element as a single element and cannot iterate it directly, breaking the index-based nature of this tabber */}

                      {companyIsCompany && (
                        <Tab label="Data" data-testid="admin:data_tab">
                          <DataTab company={company} />
                        </Tab>
                      )}
                      <Tab label="Users" data-testid="admin:users_tab">
                        <Users />
                      </Tab>

                      {companyIsCompany && (
                        <Tab
                          label={({ activateTab }) => (
                            <AnalyticsTab activateTab={activateTab} />
                          )}
                          data-testid="admin:analytics_tab"
                        >
                          {company && <Analytics company={company} />}
                        </Tab>
                      )}
                      {(companyIsPortfolioInvestor ||
                        companyIsBalanceSheetPartner) && (
                        <Tab
                          label="Portfolio"
                          data-testid="admin:portfolio_tab"
                        >
                          <CompanyManager
                            companyID={Number(company?.id)}
                            companyName={company?.name}
                            companyIsPortfolioInvestor={
                              companyIsPortfolioInvestor
                            }
                          />
                        </Tab>
                      )}

                      {companyIsPortfolioInvestor && (
                        <Tab
                          label="Invite Companies"
                          data-testid="admin:invite_companies_tab"
                        >
                          <InviteCompanies companyId={Number(company?.id)} />
                        </Tab>
                      )}

                      {companyIsCompany && (
                        <Tab label="Deals" data-testid="admin:syndication_tab">
                          <div data-testid="admin-page:main-area:syndication">
                            {companyIsCompany && (
                              <CompanySyndication company={company} />
                            )}
                            {companyIsSyndicationInvestor && (
                              <InvestorSyndication
                                companyIsCompany={companyIsCompany}
                              />
                            )}
                          </div>
                        </Tab>
                      )}

                      {companyIsCompany && (
                        <Tab
                          label="Principal Investment"
                          data-testid="admin:principal_investment_tab"
                        >
                          <CompanyPrincipalInvestment company={company} />
                        </Tab>
                      )}

                      {companyIsAdmin && (
                        <Tab label="Settings" data-testid="admin:settings_tab">
                          <Settings />
                        </Tab>
                      )}
                    </TabNavigator>
                  </div>
                </Scrollable>
                {companyIsCompany && <AdminSidebar company={company} />}
              </>
            ) : null}
          </main>
        </Scrollable>
      </AdminPage>
    </>
  );
};
