import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { toast } from '@hum/common/src/modules/toast';
import { Button, ButtonSize } from '@hum/ui-library';
import { client as backend } from '@hum/common/src/api/client';
import { Company } from '@hum/types';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { BUSINESS_TYPES } from '@hum/icm-app/src/components/constants';
import { investorCompanyRemoved } from '@hum/icm-app/src/actions';
import { TableCell, TableRow } from '@hum/design-system/src/Table.pc';

export const Investee = ({
  investorID,
  investee,
  showActions = true,
}: {
  investorID: number;
  investee: Company;
  showActions?: boolean;
}) => {
  const { dispatch } = useAppStore();
  const [loading, setLoading] = useState(false);

  const removeFromPortfolio = async (investeeID: number) => {
    try {
      setLoading(true);
      await backend.delete(`companies/${investorID}/portfolio/${investeeID}`);
      dispatch(investorCompanyRemoved({ companyId: investorID }));
      toast.success('Removed company from portfolio');
    } catch (error: any) {
      toast.error(error.message || 'Failed to remove company from portfolio');
    } finally {
      setLoading(false);
    }
  };

  return (
    <TableRow key={investee.id}>
      <TableCell>
        <Link
          to={{
            pathname: '/admin',
            search: `?company_id=${investee.id}`,
          }}
          className="ui-link"
        >
          {investee.name}
        </Link>
      </TableCell>
      <TableCell>{BUSINESS_TYPES[investee.businessType!]}</TableCell>
      <TableCell data-testid="action">
        {showActions ? (
          <Button
            size={ButtonSize.SLIM}
            onClick={() => removeFromPortfolio(investee.id)}
            disabled={loading}
            loading={loading}
          >
            Unlink
          </Button>
        ) : null}
      </TableCell>
    </TableRow>
  );
};
