import { Action, HistoryActionType } from '@hum/icm-app/src/actions';
import { AppState } from '@hum/icm-app/src/state';
import produce from 'immer';

export const analyticsReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case HistoryActionType.HISTORY_INITIALIZED:
    case HistoryActionType.HISTORY_CHANGED:
    case HistoryActionType.ROUTER_URL_PUSHED: {
      return produce(state, (newState) => {
        // name may be email address for set-password form, so fetch that if email isn't
        // there
        newState.analyticsIdentity =
          action.payload.query?.email || action.payload.query?.name;
      });
    }
  }

  return state;
};
