import React from 'react';
import { Button, ButtonType, Modal, ModalFooter } from '@hum/ui-library';
import { ConnectorOption } from '@hum/types';
import { useApiMutation, disconnectConnector, deleteConnector } from '@hum/api';

export const WHY_CONNECT_URL =
  'https://info.humcapital.com/hum-help-center/why-should-i-connect-my-business-system-to-hum';

export type DeleteConfirmationModalProps = {
  connection: ConnectorOption;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteConfirmationModal = ({ connection, isOpen, onClose }) => {
  const { mutate: deleteConnection } = useApiMutation(deleteConnector);
  const { mutate: disconnectConnection } = useApiMutation(disconnectConnector, {
    onSuccess: () => {
      deleteConnection({
        connectorId: connection.id,
      });
      onClose();
    },
  });

  return (
    <Modal
      title={`Are you sure you want to remove your ${connection.name} account?`}
      isOpen={isOpen}
      description="This account will no longer sync data to Hum and may cause you to lose some of the benefits of connecting your systems."
    >
      <div className="px-8">
        <div
          className="mb-4 ui-link"
          onClick={() => window.open(WHY_CONNECT_URL, '_blank')}
        >
          Learn more about connections
        </div>
      </div>
      <ModalFooter>
        <Button type={ButtonType.QUIET} onClick={onClose}>
          Cancel
        </Button>
        <Button
          testId="delete-connection-button"
          type={ButtonType.DESTRUCTIVE}
          onClick={() => disconnectConnection({ connectorId: connection.id })}
        >
          Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
};
