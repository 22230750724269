import * as base from '@hum/icm-app/src/pages/syndication-v2/base.pc';
import * as matches from '@hum/icm-app/src/pages/syndication-v2/matches.pc';
import * as sidebar from '@hum/icm-app/src/pages/syndication-v2/sidebar.pc';
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CompanyMatchInvestor, getUserCompanyId } from '@hum/icm-app/src/state';
import { toast } from '@hum/common/src/modules/toast';
import { SelectOption } from '@hum/legacy-ui';
import { useQuery } from '@hum/common/src/hooks/useUrlQuery';
import { Routes } from '@hum/icm-app/src/state/routes';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { client } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { EmptyPage } from '@hum/icm-app/src/pages/SmartMatchPage/EmptyPage';
import { Spinner, Scrollable } from '@hum/ui-library';
import { useApiQuery, getFundingOpportunities } from '@hum/api';

export const CompanyInvestorsPage = () => {
  const { state } = useAppStore();
  const companyId = getUserCompanyId(state);
  const { isLoading } = useApiQuery(getFundingOpportunities, {
    toastsEnabled: false,
  });

  return (
    <base.Container>
      {isLoading && <Spinner fullScreen />}
      {!isLoading && (
        <>
          <Topbar />
          <ListInvestors companyId={companyId} />
        </>
      )}
    </base.Container>
  );
};

type ListInvestorsProps = {
  companyId: number;
};

export const ListInvestors = memo(({ companyId }: ListInvestorsProps) => {
  const [investorsList, setInvestorsList] = useState<CompanyMatchInvestor[]>(
    []
  );
  const [investorListLoaded, setInvestorListLoaded] = useState(false);

  const getInvestorList = async () => {
    setInvestorListLoaded(false);
    try {
      const response = await client
        .get(`/companies/${companyId}/syndication/match_investors`, {
          withCredentials: true,
        })
        .then((r) => r.map(normalize.syndicationInvestorDetail.in));
      setInvestorsList(response);
    } catch (error: any) {
      console.error(error);
      toast.error(
        error.message || 'Failed to get investors, contact the administrator'
      );
    } finally {
      setInvestorListLoaded(true);
    }
  };

  useEffect(() => {
    getInvestorList();
  }, []);

  if (!investorListLoaded) return <Spinner fullScreen />;

  const titleToolTipMessage =
    'Here is a list of investors that are interested in you. You can adjust what data investors can see by clicking on the individual fund profile.';

  return (
    <Scrollable>
      {investorsList.length === 0 ? (
        <EmptyPage />
      ) : (
        <sidebar.PageContent
          title="Investors"
          tooltip={titleToolTipMessage}
          fixedWidth
          center
          padded
        >
          {investorsList.map((investor, index) => (
            <InvestorMatch key={index} item={investor} index={index} />
          ))}
        </sidebar.PageContent>
      )}
    </Scrollable>
  );
});
ListInvestors.displayName = 'ListInvestors';

type InvestorMatchProps = {
  item: CompanyMatchInvestor;
  index: number;
};

const InvestorMatch = memo(
  ({
    item: { uuid, name, location, website, investmentTypes, investmentRanges },
    index,
  }: InvestorMatchProps) => {
    const { company_id: companyId = '' }: { company_id?: string } = useQuery();
    const notAvailable = 'N/A';

    const history = useHistory();

    const onClick = () => {
      if (companyId) {
        history.push(
          `${Routes.INTRODUCTIONS_INVESTOR}?uuid=${uuid}&company_id=${companyId}`
        );
      } else {
        history.push(`${Routes.INTRODUCTIONS_INVESTOR}?uuid=${uuid}`);
      }
    };

    return (
      <matches.Match
        bigtitle
        testId={`syndication:investor-deals-card-${index + 1}`}
        onClick={onClick}
        title={name}
        details={
          <>
            <base.Detail
              label="Location"
              value={location || notAvailable}
              light
            />
            <base.Detail label="Website" value={website || '–'} light />
            <base.Detail
              label="Investment range"
              value={
                (investmentRanges &&
                  investmentRanges
                    .map((item: SelectOption) => item.label)
                    .join(', ')) ||
                '–'
              }
              light
            />
            <base.Detail
              label="Investment type"
              value={
                (investmentTypes &&
                  investmentTypes
                    .map((item: SelectOption) => item.label)
                    .join(', ')) ||
                '–'
              }
              light
            />
          </>
        }
      />
    );
  }
);
InvestorMatch.displayName = 'InvestorMatch';
