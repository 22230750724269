import {
  Filter,
  FilterType,
  FilterCategory,
  QueryStringEntry,
  CheckboxFilter,
  OptionsFilter,
} from '@hum/icm-app/src/state';

export enum AnyOptions {
  Any = 'any',
}

type SelectedFilter = {
  [key in FilterType]?: string | boolean;
};

const filterCategories = (filter: Filter) => {
  return (
    (filter.type === FilterType.Checkbox && filter.checked) ||
    (filter.type === FilterType.Text && filter.value.length) ||
    (filter.type === FilterType.Toggle && filter.selected.length) ||
    (filter.type === FilterType.Options &&
      filter.selected.length &&
      filter.selected !== AnyOptions.Any) ||
    false
  );
};

const generateNamedValue = (filter: Filter) => {
  return {
    [filter.key]:
      (filter.type === FilterType.Checkbox && filter.checked) ||
      (filter.type === FilterType.Text && filter.value) ||
      (filter.type === FilterType.Toggle && filter.selected) ||
      (filter.type === FilterType.Options && filter.selected),
  };
};

// only pass categories that have checked | selected (i.e. non default) filters
// TODO: this needs to be moved in app/state directory since it's
// used in global saga code (CC)
export const getSelectedFilters = (categories: FilterCategory[]) => {
  const selectedCategories = categories.map((category) =>
    category.filters.filter(filterCategories).map(generateNamedValue)
  );

  // remove empty arrays from results (so we don't have trash data)
  const selectedFilters = selectedCategories.reduce(
    (acc, filter) => (filter.length ? [...acc, ...filter] : acc),
    []
  );

  return selectedFilters;
};

const OVERRIDES = {
  score_info: '1', // todo: remove when backend is ready @Edgar @Jorge
  report: '1',
  model: 'success',
};

// Generate query string with the following format
// TODO: this needs to be moved in app/state directory since it's
// used in global saga code (CC)
export const createQueryString = (filters: QueryStringEntry[]) =>
  filters
    .map((filter) => {
      const key = Object.getOwnPropertyNames(filter)[0];
      const value = OVERRIDES[key] || Object.values(filter)[0];
      return `${key}=${value}`;
    })
    .join('&');

// standard filters are the filters that always are visible in the sidebar
const standardFilters = ['page', 'status', 'order', 'q'];

// return all categories (filters) that are not considered standard
const filterOutStandardFilters = (categories: SelectedFilter[]) =>
  categories.filter(
    (category) =>
      !standardFilters.includes(Object.getOwnPropertyNames(category)[0])
  );

// Custom filters are filters inside the filters box, excluding standardFilters
export const getActiveFilters = (categories: FilterCategory[]) => {
  const selectedFilters = getSelectedFilters(categories);
  return filterOutStandardFilters(selectedFilters);
};

export const describeActiveFilters = (
  categories: FilterCategory[]
): string[] => {
  const selectedCategories = categories
    .map((category) => category.filters.filter(filterCategories))
    .reduce((categories, category) => [...categories, ...category], [])
    .filter(({ key }) => !standardFilters.includes(key)) as (
    | CheckboxFilter
    | OptionsFilter
  )[];

  const checkboxFilters = selectedCategories
    .filter(
      (filter): filter is CheckboxFilter => filter.type === FilterType.Checkbox
    )
    .map((filter) => filter.label || '');

  const valueFilters = selectedCategories
    .filter(
      (filter): filter is OptionsFilter => filter.type === FilterType.Options
    )
    .map((filter) => {
      const selected = filter.options.find(
        (option) => filter.selected === option.value
      );
      return selected!.label || '';
    });

  if (!(checkboxFilters.length || valueFilters.length)) {
    return [];
  }

  return [...checkboxFilters, ...valueFilters];
};
