import React from "react";
import _pub8C547Ae7 from "../../../../../../design-system/src/Select.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pub4435Fe58 from "../../../../../../design-system/src/Pill.pc";
import _pubF6B296A8 from "../../../../components/Pagination/styles.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_ac42927a _96c81c86 _pub-96c81c86",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Wrapper };

var CompanyFilterItem = React.memo(React.forwardRef(function CompanyFilterItem(props, ref) {
  return React.createElement("div", {
    "className": "_4bfabf7d _96c81c86 _pub-96c81c86" + (props["active"] ? " " + "_96c81c86_active _pub-96c81c86_active active" : "") + " " + "_96c81c86_company-filter-item _pub-96c81c86_company-filter-item company-filter-item",
    "ref": ref,
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { CompanyFilterItem };

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_be69356 _96c81c86 _pub-96c81c86" + " " + "_96c81c86_v3 _pub-96c81c86_v3 v3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Container };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(Container, {
    "class": "_e5e8f27a",
    "ref": ref,
  }, 
    React.createElement(CompanyFilterItem, {
      "class": "_c1499f18",
    }, 
      React.createElement("span", {
        "className": "_a581af79 _96c81c86 _pub-96c81c86",
      }, 
        "Company with many rolws"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_d2869fef _96c81c86 _pub-96c81c86",
        "info": true,
        "bold": true,
      }, 
        "Syndication investor"
        
      ),
      React.createElement(_pub4435Fe58, {
        "class": "_4b8fce55 _96c81c86 _pub-96c81c86",
        "info": true,
        "bold": true,
      }, 
        "\n      Syndication investor\n    "
        
      )
    ),
    React.createElement(CompanyFilterItem, {
      "class": "_b64eaf8e",
      "active": true,
    }, 
      "Company 2"
      
    ),
    React.createElement(CompanyFilterItem, {
      "class": "_2f47fe34",
    }, 
      "Company 3"
      
    ),
    React.createElement(CompanyFilterItem, {
      "class": "_5840cea2",
    }, 
      "Company 4"
      
    ),
    React.createElement(CompanyFilterItem, {
      "class": "_c6245b01",
    }, 
      "Company 5"
      
    ),
    React.createElement(CompanyFilterItem, {
      "class": "_b1236b97",
    }, 
      "\n    Company 6 "
      ,
      React.createElement(_pub4435Fe58, {
        "class": "_d44d5d04 _96c81c86 _pub-96c81c86",
        "notice": true,
        "bold": true,
      }, 
        "Syndication"
        
      )
    ),
    React.createElement(CompanyFilterItem, {
      "class": "_282a3a2d",
    }, 
      "Company 7"
      
    )
  )
}));
export { Preview };

var PreviewOuter = React.memo(React.forwardRef(function PreviewOuter(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_c8b574f _96c81c86 _pub-96c81c86" + " " + "v3",
    "ref": ref,
    "humDark": props["humDark"],
  }, null)
}));


