import React from 'react';
import {
  InlineNotification,
  Icon,
  Text,
  TextType,
  NotificationType,
} from '@hum/ui-library';
import { useSmartRaiseNotification } from './useSmartRaiseNotification';

export function SmartRaiseHero({ testId }: { testId?: string }) {
  const {
    notificationType,
    isNotificationVisible,
    notificationText,
    hideNotification,
  } = useSmartRaiseNotification();

  return (
    <>
      {notificationType === NotificationType.WARNING &&
        isNotificationVisible && (
          <div
            data-testid="smartraise::overlay"
            className="h-screen w-full fixed bg-gray-800 opacity-40 z-10 -translate-y-12 mt-12"
          />
        )}
      <div
        className="mx-auto max-w-3xl pt-20 pb-20 px-4 sm:px-2"
        data-testid={testId}
      >
        {notificationType && isNotificationVisible && (
          <div className="mx-auto max-w-3xl px-4 sm:px-0">
            <InlineNotification
              type={notificationType}
              onClick={hideNotification}
              testId="smartraise::notification"
            >
              {notificationText}
            </InlineNotification>
          </div>
        )}

        <Text type={TextType.DISPLAY_M} className="text-left">
          Welcome to the SmartRaise process—the smarter, more streamlined way to
          raise.
        </Text>
        <div className="inline-grid grid-cols-6 mt-8 px-4 md:grid-cols-12 md:gap-0 sm:px-2">
          <Icon.CircleStar className="col-span-1 md:col-span-1" />
          <Text
            type={TextType.BODY_M}
            subdued
            className="col-span-5 md:col-span-11"
          >
            An investor on Hum’s Intelligent Capital Market is interested in
            offering you preliminary terms. Explore this opportunity to be
            introduced to the investment team and take the next steps in getting
            capital for your business.
            <Text type={TextType.BODY_MICO} disabled className="mt-3">
              These terms are non-binding and subject to change upon further
              verification.
            </Text>
          </Text>
        </div>
      </div>
    </>
  );
}
