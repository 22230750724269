import React, { ReactElement } from 'react';
import { Text, TextType, IconProps } from '@hum/ui-library';

interface CardProps {
  icon: ReactElement<IconProps>;
  title: string;
  description: string;
  testId?: string;
}

export function Card({ icon, title, description, testId }: CardProps) {
  return (
    <div
      className="bg-gray-700 border border-borderColor rounded-default p-5"
      data-testid={testId}
    >
      {React.cloneElement(icon, { className: '-translate-y-11 mx-auto' })}
      <Text
        type={TextType.HEADING_L}
        className="text-center -mt-5 mx-auto max-w-[192px]"
      >
        {title}
      </Text>
      <Text type={TextType.BODY_S} className="mt-3 text-center" subdued>
        {description}
      </Text>
    </div>
  );
}
