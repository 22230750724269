import React from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from '@hum/icm-app/src/state';
import { Text, TextType } from '@hum/ui-library';
import { ConnectorOption, ConnectorStatus, PlatformType } from '@hum/types';
import { useApiQuery, getConnectors, getConnectorConfigs } from '@hum/api';

import { ConnectorTab, Tabs } from './components';
import accountingIcon from './assets/accounting.png';
import paymentProcessorIcon from './assets/payment-processor.png';
import bankIcon from './assets/bank.png';

const TAB_INDEXES = {
  'accounting-system': 0,
  'payment-processor': 1,
  bank: 2,
};

export const ConnectorsPage = () => {
  const location = useLocation();
  const defaultTabIndex =
    TAB_INDEXES[location.pathname.split('/').slice(-1)[0]];
  const { data: connections = [] } = useApiQuery(getConnectors);
  const { data: options = [] } = useApiQuery(getConnectorConfigs);
  if (connections.length && options.length) {
    connections.forEach((connection: ConnectorOption) => {
      if (!connection.config) {
        const option = options.find(
          (option) => option.config.connectorType === connection.connectorType
        );
        if (option) {
          connection.config = option.config;
        } else {
        }
      }
    });
  }
  const filteredConnections = connections.filter(
    (connection) =>
      connection.status && connection.status === ConnectorStatus.Active
  );
  const accountingSystemConnections = filteredConnections.filter(
    (connection) =>
      connection.config?.platformType === PlatformType.AccountingSystem
  );
  const paymentProcessorConnections = filteredConnections.filter(
    (connection) =>
      connection.config?.platformType === PlatformType.PaymentProcessor
  );
  const bankConnections = filteredConnections.filter(
    (connection) => connection.config?.platformType === PlatformType.Bank
  );

  const connectorPageTabs = [
    {
      label: 'Accounting system',
      children: (
        <ConnectorTab
          connections={accountingSystemConnections}
          icon={accountingIcon}
          title="Gain investor's trust"
          description="Investors are more likely to fund companies that share their data in a standard format. Connect your software and let Hum do all the formating for you."
        />
      ),
      path: Routes.COMPANY_ACTIVATION_CONNECTORS_ACCOUNTING,
    },
    {
      label: 'Payment processor',
      children: (
        <ConnectorTab
          connections={paymentProcessorConnections}
          icon={paymentProcessorIcon}
          title="Get better terms"
          description="Increase your likelihood of getting better terms by giving investors a perspective of your financial health. All while getting insights on customer trends and product market fit."
        />
      ),
      path: Routes.COMPANY_ACTIVATION_CONNECTORS_PAYMENT,
    },
    {
      label: 'Bank',
      children: (
        <ConnectorTab
          connections={bankConnections}
          icon={bankIcon}
          title="Access unique funding opportunities"
          description="Hum securely connects to your bank with Plaid. By connecting your bank you will increase the number of funding opportunities your business will be eligible for."
        />
      ),
      path: Routes.COMPANY_ACTIVATION_CONNECTORS_BANK,
    },
  ];

  return (
    <div data-testid="company-activation:connectors">
      <Text type={TextType.DISPLAY_S}>Connectors</Text>
      <Tabs tabs={connectorPageTabs} defaultTabIndex={defaultTabIndex} />
    </div>
  );
};
