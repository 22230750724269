import React from 'react';
import { toNumber, isEmpty } from 'lodash';
import { HashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import * as styles from './admin-analytics.pc';
import * as stylesTab from '@hum/icm-app/src/pages/settings/manage-users/TeamTab/styles.pc';
import * as CompanyOverview from '@hum/icm-app/src/pages/syndication-v2/company-overview.pc';
import * as sidebar from '@hum/icm-app/src/pages/syndication-v2/sidebar.pc';
import * as containerStyles from '@hum/icm-app/src/components/Split/styles.pc';
import { PageContent } from '@hum/legacy-ui';
import { CompanyChart, CompanyFileDocumentType } from '@hum/types';
import { AppState } from '@hum/icm-app/src/state';
import {
  INSIGHTS_TABS,
  INSIGHTS_TABS_V2,
} from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail';
import { useCompany } from '@hum/icm-app/src/hooks/useCompany';
import { useSyndicationInsightsCharts } from '@hum/icm-app/src/hooks/useSyndicationInsightsCharts';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useFinancialSummaryVisiblity } from '@hum/icm-app/src/hooks/useFinancialSummaryVisibility';
import { downloadCompanyFinancialButtonClicked } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';

import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { Scrollable, Spinner } from '@hum/ui-library';
import { ChartList } from '@hum/icm-app/src/components/ChartList';
import {
  getCompanyFinancialSummary,
  getSyndicationInsightsCharts,
  useApiQuery,
} from '@hum/api';

export const AdminAnalyticsWithCharts = () => {
  const {
    showCompanyFinancialSummary,
    company,
    investorAnalyticsV1FeatureFlag,
    isDownloadingCompanyFinancialsFile,
    onClickDownloadButton,
    chartsData,
    financialAnalysisCharts,
    customerAnalysisCharts,
    cohortAnalysisCharts,
    hasFinancialAnalysis,
    hasCustomerAnalysis,
    hasCohortAnalysis,
    clickGoBack,
  } = useAdminAnalyticsWithCharts();

  const content =
    chartsData && investorAnalyticsV1FeatureFlag ? (
      <containerStyles.CobrandingLayout>
        <containerStyles.NavSidebar narrow lighterBg>
          <styles.BackArrowTitle
            title={company?.name}
            clickGoBack={clickGoBack}
          />
          <stylesTab.CobrandingMenu>
            <HashLink smooth to="#company-financials" activeClassName="active">
              Company Financials
            </HashLink>
            {hasFinancialAnalysis && (
              <HashLink
                smooth
                to="#financial-analysis"
                activeClassName="active"
              >
                Financial Analysis
              </HashLink>
            )}
            {hasCustomerAnalysis && (
              <HashLink smooth to="#customer-analysis" activeClassName="active">
                Customer Analysis
              </HashLink>
            )}
            {hasCohortAnalysis && (
              <HashLink smooth to="#cohort-analysis" activeClassName="active">
                Cohort Analysis
              </HashLink>
            )}
          </stylesTab.CobrandingMenu>
        </containerStyles.NavSidebar>
        <containerStyles.CobrandingRight>
          <Scrollable>
            <PageContent fixedWidth padded center>
              <styles.TitleBar title="Insights" />
              {showCompanyFinancialSummary && company && (
                <>
                  <styles.SectionHeading
                    subtitle={
                      "Downloadable Excel file containing an overview of companies' accounting systems"
                    }
                    title="Company financials"
                    id="company-financials"
                  />

                  <CompanyOverview.DownloadFinancialBar
                    isDownloadingFile={
                      isDownloadingCompanyFinancialsFile.status
                    }
                    companyName={company?.name}
                    clickDownloadButton={onClickDownloadButton}
                  />
                </>
              )}
              {chartsData.data ? (
                <>
                  {hasFinancialAnalysis && (
                    <>
                      <styles.SectionHeading
                        subtitle=""
                        title="Financial Analysis"
                        id="financial-analysis"
                      />
                      <ChartList charts={financialAnalysisCharts} />
                    </>
                  )}
                  {hasCustomerAnalysis && (
                    <>
                      <styles.SectionHeading
                        subtitle=""
                        title="Customer Analysis"
                        id="customer-analysis"
                      />
                      <ChartList charts={customerAnalysisCharts} />
                    </>
                  )}
                  {hasCohortAnalysis && (
                    <>
                      <styles.SectionHeading
                        subtitle=""
                        title="Cohort Analysis"
                        id="cohort-analysis"
                      />
                      <ChartList charts={cohortAnalysisCharts} />
                    </>
                  )}
                </>
              ) : (
                <sidebar.EmptyInsights />
              )}
            </PageContent>
          </Scrollable>
        </containerStyles.CobrandingRight>
      </containerStyles.CobrandingLayout>
    ) : (
      <Spinner fullScreen />
    );
  return (
    <containerStyles.Wrapper>
      <Topbar />
      {content}
    </containerStyles.Wrapper>
  );
};

const useAdminAnalyticsWithCharts = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId?: string }>();
  const { data: company } = useCompany(toNumber(companyId));
  const flags = useFlags();
  const investorAnalyticsV1FeatureFlag = flags.enabled(
    'show-investor-analytics-v1'
  );
  const { data: companyFinancialSummary } = useApiQuery(
    getCompanyFinancialSummary,
    {
      params: { companyId: toNumber(companyId) },
    }
  );
  const { data: syndicationInsightsCharts } = useApiQuery(
    getSyndicationInsightsCharts,
    {
      params: {
        companyId: toNumber(companyId),
      },
    }
  );
  const isDownloadingCompanyFinancialsFile = useSelector(
    (state: AppState) => state.isDownloadingCompanyFinancialsFile
  );
  const sagasShowCompanyFinancialSummary = useFinancialSummaryVisiblity(
    toNumber(companyId)
  );
  const showCompanyFinancialSummary = flags.enabled('use-react-query')
    ? companyFinancialSummary?.status
    : sagasShowCompanyFinancialSummary;

  const sagasChartsData = useSyndicationInsightsCharts(toNumber(companyId));
  const chartsData = flags.enabled('use-react-query')
    ? { data: syndicationInsightsCharts }
    : sagasChartsData;
  const financialAnalysisCharts = chartsData.data
    ? (chartsData.data[INSIGHTS_TABS.FINANCIAL_ANALYSIS] as CompanyChart[]) ||
      (chartsData.data[INSIGHTS_TABS_V2.FINANCIAL_ANALYSIS] as CompanyChart[])
    : [];
  const customerAnalysisCharts = chartsData.data
    ? (chartsData.data[INSIGHTS_TABS.CUSTOMER_ANALYSIS] as CompanyChart[]) ||
      (chartsData.data[INSIGHTS_TABS_V2.CUSTOMER_ANALYSIS] as CompanyChart[])
    : [];
  const cohortAnalysisCharts = chartsData.data
    ? (chartsData.data[INSIGHTS_TABS.COHORT_ANALYSIS] as CompanyChart[]) ||
      (chartsData.data[INSIGHTS_TABS_V2.COHORT_ANALYSIS] as CompanyChart[])
    : [];
  const hasFinancialAnalysis = !isEmpty(financialAnalysisCharts);
  const hasCustomerAnalysis = !isEmpty(customerAnalysisCharts);
  const hasCohortAnalysis = !isEmpty(cohortAnalysisCharts);
  const onClickDownloadButton = () => {
    dispatch(
      downloadCompanyFinancialButtonClicked({
        companyId: company!.id,
        docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
      })
    );
  };
  const clickGoBack = () => {
    history.back();
  };
  return {
    showCompanyFinancialSummary,
    company,
    investorAnalyticsV1FeatureFlag,
    isDownloadingCompanyFinancialsFile,
    onClickDownloadButton,
    clickGoBack,
    chartsData,
    financialAnalysisCharts,
    customerAnalysisCharts,
    cohortAnalysisCharts,
    hasFinancialAnalysis,
    hasCustomerAnalysis,
    hasCohortAnalysis,
  };
};
