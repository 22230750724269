// Components
import React, { useState, useEffect, useContext } from 'react';
import produce from 'immer';
// Components
import { DocumentType } from '@hum/icm-app/src/components/types';
import { useDebounce } from 'use-debounce';
import {
  COUNTRY_SELECT_OPTIONS,
  BUSINESS_TYPES,
  ECONOMIC_TIER_OPTIONS,
  MONTHS_OF_RUNWAY_OPTIONS,
  COMPANY_TYPE_OPTIONS,
} from '@hum/icm-app/src/components/constants';
import { ModalContext } from '@hum/design-system';
import {
  Pill,
  FieldStyles as Field,
  Toggle,
  SelectOption,
  selectOptionsFromMap,
  SelectInput,
  TextInput,
  CheckboxField,
  Fieldset,
} from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';

import { Pagination } from '@hum/icm-app/src/components/Pagination';
import {
  FilterType,
  FilterCategory,
  QueryStringEntry,
  Filter,
  CheckboxFilter,
  TextFilter,
  OptionsFilter,
  ToggleFilter,
} from '@hum/icm-app/src/state';
import { Result } from '@hum/common/src/ducks/state';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useAvailableConnectors } from '@hum/icm-app/src/hooks/useAvailableConnectors';
import { companyListFiltersChanged } from '@hum/icm-app/src/actions';
import {
  getSelectedFilters,
  createQueryString,
  getActiveFilters,
  describeActiveFilters,
  AnyOptions,
} from '../createQueryString';

import { StyledFilters, StyledFilterBox } from './styles/index.pc';
import * as styles from './styles.pc';
import { createPortal } from 'react-dom';

const SORT_CATEGORY_KEY = 'sort';
const SEARCH_CATEGORY_KEY = 'search';
const SURVEY_CATEGORY_KEY = 'survey';
const PAGINATION_CATEGORY_KEY = 'pagination';

const generateConnectorFilter = (name: string) => ({
  key: name,
  label: name,
  checked: false,
  type: FilterType.Checkbox,
});

const generateConnectorFilters = ({ connectors }: { connectors: string[] }) =>
  connectors.map(generateConnectorFilter);

const generateConnectorsCategory = (
  availableConnectors: Result<string[]>
): FilterCategory | undefined => {
  if (availableConnectors.loaded && availableConnectors.data) {
    const connectorsCategory = {
      key: 'connectors',
      label: 'Connectors',
      filters: generateConnectorFilters({
        connectors: availableConnectors.data,
      }),
    } as FilterCategory;
    return connectorsCategory;
  }
};

const defaultFilters: FilterCategory[] = [
  {
    key: 'files',
    label: 'Uploaded files',
    filters: [
      {
        key: DocumentType.IncomeStatement,
        checked: false,
        label: 'Income Statement',
        type: FilterType.Checkbox,
      },
      {
        key: DocumentType.BalanceSheet,
        checked: false,
        label: 'Balance Sheet',
        type: FilterType.Checkbox,
      },
      {
        key: DocumentType.CustomerTape,
        checked: false,
        label: 'Transaction Data',
        type: FilterType.Checkbox,
      },
      {
        key: DocumentType.CapTable,
        checked: false,
        label: 'Capitalization Table',
        type: FilterType.Checkbox,
      },
    ],
  },
  {
    key: 'milestones',
    label: 'Milestones',
    filters: [
      {
        key: 'model',
        checked: false,
        label: 'Has Model',
        type: FilterType.Checkbox,
      },
    ],
  },
  {
    key: SURVEY_CATEGORY_KEY,
    label: 'Survey',
    filters: [
      {
        key: 'country',
        label: 'Country',
        selected: AnyOptions.Any,
        options: [
          { label: 'Any', value: AnyOptions.Any },
          ...COUNTRY_SELECT_OPTIONS,
        ],
        type: FilterType.Options,
      },
      {
        key: 'business_type',
        label: 'Business Type',
        selected: AnyOptions.Any,
        options: selectOptionsFromMap({
          [AnyOptions.Any]: 'Any',
          ...BUSINESS_TYPES,
        }),
        type: FilterType.Options,
      },
      {
        key: 'yearly_revenue',
        label: 'Economic Tier',
        selected: AnyOptions.Any,
        options: selectOptionsFromMap({
          [AnyOptions.Any]: 'Any',
          ...ECONOMIC_TIER_OPTIONS,
        }),
        type: FilterType.Options,
      },
      {
        key: 'months_of_runway',
        label: 'Months of runway',
        selected: AnyOptions.Any,
        options: selectOptionsFromMap({
          [AnyOptions.Any]: 'Any',
          ...MONTHS_OF_RUNWAY_OPTIONS,
        }),
        type: FilterType.Options,
      },
      {
        key: 'company_type',
        label: 'Company Type',
        selected: AnyOptions.Any,
        options: selectOptionsFromMap({
          [AnyOptions.Any]: 'Any',
          ...COMPANY_TYPE_OPTIONS,
        }),
        type: FilterType.Options,
      },
    ],
  },
  {
    key: SORT_CATEGORY_KEY,
    filters: [
      {
        key: 'order',
        selected: 'updated_at',
        options: selectOptionsFromMap({
          name: 'Alphabetically',
          created_at: 'Most recently added',
          updated_at: 'Last modified',
        }),
        type: FilterType.Options,
      },
    ],
  },
  {
    key: SEARCH_CATEGORY_KEY,
    label: 'Search',
    filters: [
      {
        key: 'q',
        label: 'Search',
        value: '',
        type: FilterType.Text,
      },
    ],
  },
  {
    key: PAGINATION_CATEGORY_KEY,
    label: 'Page',
    filters: [
      {
        key: 'page',
        label: 'Search',
        value: '1',
        type: FilterType.Text,
      },
    ],
  },
];

type OptionSetter = (
  category: FilterCategory['key'],
  filter: Filter['key'],
  value: string
) => void;

type ToggleSetter = (
  category: FilterCategory['key'],
  filter: Filter['key'],
  value: boolean
) => void;

const getCategory = (
  categories: FilterCategory[],
  key: FilterCategory['key']
) => categories.findIndex((category) => category.key === key);

const getFilter = (filters: Filter[], key: Filter['key']) =>
  filters.findIndex((filter) => filter.key === key);

const renderSelect = (
  { key: category }: FilterCategory,
  { key: filter, selected, options, label }: OptionsFilter,
  setOption: OptionSetter
) => {
  const input = (
    <SelectInput
      key={filter}
      data-testid="admin-page:company-list:sort-by"
      inputId={label}
      aria-label={label}
      options={options}
      onValueChange={(value: any) => {
        if (value === null) {
          return setOption(category, filter, '');
        }
        if (typeof value === 'string') {
          return setOption(category, filter, value as string);
        }
        const multiValue = value as SelectOption;
        return setOption(category, filter, multiValue.value);
      }}
      value={selected}
    />
  );

  if (label) {
    return (
      <Field label={label} htmlFor={label}>
        {input}
      </Field>
    );
  }

  return input;
};

const renderCheckboxCategory = (
  { key: category }: FilterCategory,
  { key: filter, checked, label }: CheckboxFilter,
  toggle: ToggleSetter
) => (
  <CheckboxField
    key={filter}
    value={checked}
    onValueChange={(value) => toggle(category, filter, value)}
    data-testid={`company_list_filter:${filter}`}
    name={filter}
    label={label}
  />
);

const renderText = (
  { key: category }: FilterCategory,
  { key: filter, value, label }: TextFilter,
  setText: OptionSetter
) => {
  return (
    <TextInput
      placeholder={label}
      icon={<styles.SearchIcon />}
      key={filter}
      value={value}
      onValueChange={(value) => setText(category, filter, value)}
    />
  );
};

const renderToggle = (
  { key: category }: FilterCategory,
  { key: filter, selected, options }: ToggleFilter,
  setOption: OptionSetter
) => (
  <Toggle
    options={options}
    key={filter}
    onValueChange={(value) => setOption(category, filter, value)}
    value={selected}
  />
);

export const Filters = () => {
  const { dispatch, state } = useAppStore();
  const availableConnectors = useAvailableConnectors();
  const pagination = state.companies?.data?.pagination || {
    pageCount: 0,
    currentPage: 0,
    results: 0,
    pageSize: 0,
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [visible, setVisible] = useState(false);

  const generateQuery = () => {
    const selectedFilters = getSelectedFilters(filters);
    return createQueryString(selectedFilters as QueryStringEntry[]);
  };

  const instantQuery = generateQuery();
  const [query] = useDebounce(instantQuery, 500);

  const addConnectorsToFilters = () => {
    // do not add connectors category if it already exists (next.js fast refresh usually does this)
    if (filters.findIndex((category) => category.key === 'connectors') >= 0) {
      return;
    }
    // else, add connectors category to filters, if it exists
    const connectorsCategory = generateConnectorsCategory(availableConnectors);
    if (connectorsCategory) {
      setFilters([connectorsCategory, ...filters]);
    }
  };

  const resetFilters = () => {
    const connectorsCategory = generateConnectorsCategory(availableConnectors);
    if (connectorsCategory) {
      setFilters([connectorsCategory, ...defaultFilters]);
    } else {
      setFilters(defaultFilters);
    }
  };

  useEffect(() => addConnectorsToFilters(), [availableConnectors]);

  const toggle = (category: string, filter: string, value: boolean) => {
    const categoryIndex = getCategory(filters, category);
    const filterIndex = getFilter(filters[categoryIndex].filters, filter);

    setFilters(
      produce(filters, (newFilters: CheckboxFilter) => {
        newFilters[categoryIndex].filters[filterIndex].checked = value;
      })
    );
  };

  const setOption = (category: string, filter: string, value: string) => {
    const categoryIndex = getCategory(filters, category);
    const filterIndex = getFilter(filters[categoryIndex].filters, filter);

    setFilters(
      produce(filters, (newFilters: OptionsFilter | ToggleFilter) => {
        newFilters[categoryIndex].filters[filterIndex].selected = value;
      })
    );
  };

  const setText = (category: string, filter: string, value: string) => {
    const categoryIndex = getCategory(filters, category);
    const filterIndex = getFilter(filters[categoryIndex].filters, filter);

    setFilters(
      produce(filters, (newFilters: TextFilter) => {
        newFilters[categoryIndex].filters[filterIndex].value = String(value);
      })
    );
  };

  // This in here because query already has a debouncer here, should we eventually move
  // this direclty to global state or is this good enough?
  // I'm guessing it's ok because we don't want to pollute state with every keystroke
  // when typing into the search box but instead only update the value we are truly interested in
  // Also... filters does not have a proper default value (it's just an empty array)
  // should we move the default value over to the app/state file?
  // It would make the default app state significantly larger than it is right now
  useEffect(() => {
    dispatch(companyListFiltersChanged({ filters }));
  }, [query]);

  const renderCategory = (category: FilterCategory, showTitle = true) => {
    const content = category.filters.map((filter) => {
      switch (filter.type) {
        case FilterType.Checkbox:
          return renderCheckboxCategory(category, filter, toggle);
        case FilterType.Text:
          return renderText(category, filter, setText);
        case FilterType.Toggle:
          return renderToggle(category, filter, setOption);
        case FilterType.Options:
          return renderSelect(category, filter, setOption);
        default: {
          throw new Error('Unknown filter type');
        }
      }
    });

    if (!showTitle) {
      return content;
    }

    return (
      <Fieldset label={category.label} key={category.key} squished>
        {content}
      </Fieldset>
    );
  };

  // Pull sort filter out of filters, will render independently
  const sortIndex = getCategory(filters, SORT_CATEGORY_KEY);
  const sortFilter = filters[sortIndex];

  const surveyIndex = getCategory(filters, SURVEY_CATEGORY_KEY);
  const surveyFilter = filters[surveyIndex];

  const searchIndex = getCategory(filters, SEARCH_CATEGORY_KEY);
  const searchFilter = filters[searchIndex];

  const paginationIndex = getCategory(filters, PAGINATION_CATEGORY_KEY);

  const otherFilters = filters.filter(
    (_, i) =>
      ![sortIndex, surveyIndex, searchIndex, paginationIndex].includes(i)
  );

  const activeFilters = getActiveFilters(filters);
  const activeFiltersCount = activeFilters.length;

  const [searchFilterQuery]: Filter[] = searchFilter.filters;

  // Set page to 1 whenever search query changes
  useEffect(() => {
    if (searchFilterQuery.type === FilterType.Text) {
      setText('pagination', 'page', String(1));
    }
  }, [searchFilterQuery]);

  const activeFilters2 = describeActiveFilters(filters);

  const modalMount = useContext(ModalContext);

  return (
    <>
      <styles.Container
        sortByInputs={
          <>
            {renderCategory(sortFilter, false)}
            <Button onClick={() => setVisible(!visible)} testId="show-filters">
              Filters {activeFiltersCount ? `(${activeFiltersCount})` : ''}
            </Button>
          </>
        }
        searchInput={renderCategory(searchFilter, false)}
        pagination={
          <Pagination
            pagination={pagination}
            setPage={(page: string) =>
              setText('pagination', 'page', String(page))
            }
          />
        }
        activeFilters={
          activeFilters2.length
            ? activeFilters2.map((filter: string) => <Pill info>{filter}</Pill>)
            : null
        }
        results={null}
      ></styles.Container>
      {visible &&
        createPortal(
          <styles.FilterModal onClick={() => setVisible(false)}>
            <StyledFilters onClick={(e: any) => e.stopPropagation()}>
              <styles.ModalWrapper>
                <StyledFilterBox>
                  {otherFilters.map((category) => renderCategory(category))}
                </StyledFilterBox>
                <StyledFilterBox>
                  {renderCategory(surveyFilter)}
                  <Button
                    onClick={() => resetFilters()}
                    testId="clear-filters"
                    loading={query !== instantQuery}
                    type={ButtonType.DESTRUCTIVE}
                    disabled={activeFiltersCount === 0}
                  >
                    Clear
                    {activeFiltersCount === 1 &&
                      ` ${activeFiltersCount} filter`}
                    {activeFiltersCount > 1 && ` ${activeFiltersCount} filters`}
                  </Button>
                </StyledFilterBox>
              </styles.ModalWrapper>
            </StyledFilters>
          </styles.FilterModal>,
          modalMount!
        )}
    </>
  );
};
