import React from 'react';

import { Icon, Text, TextType } from '@hum/ui-library';

export interface QuestionType {
  text: string;
  link: string;
}

export function Question({ text, link }: QuestionType) {
  return (
    <div className="px-4 py-6 border-b border-borderColor hover:bg-surface-subdued last:border-none">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="hover:no-underline"
      >
        <Text
          type={TextType.BODY_M}
          className="cursor-pointer flex items-center justify-between"
          subdued
        >
          {text}
          <Icon.ExternalLink />
        </Text>
      </a>
    </div>
  );
}
