import React from "react";
import "./outer.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_f8c923c4 _7c56837 _pub-7c56837",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Container };

var AdminContent = React.memo(React.forwardRef(function AdminContent(props, ref) {
  return React.createElement("div", {
    "className": "_16c742e8 _7c56837 _pub-7c56837",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { AdminContent };


