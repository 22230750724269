import React from "react";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_94fb89d5 _a0e20bfd _pub-a0e20bfd",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Container };

var Topbar = React.memo(React.forwardRef(function Topbar(props, ref) {
  return React.createElement("div", {
    "className": "_7af5e8f9 _a0e20bfd _pub-a0e20bfd",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Topbar };


