// Libraries
import React, { useMemo } from 'react';
import * as Yup from 'yup';
// Components
import { useField, useForm } from '@hum/common/src/modules/form';
import { Box, SelectInputField, Fieldset } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { apiPrincipalInvestmentStateChanged } from '@hum/icm-app/src/actions';
import { PrincipalInvestmentStatus } from '@hum/icm-app/src/state';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

const transformValues = (status: string) => ({
  status: status,
});

// prettier-ignore
const validationSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
})

declare type Props = {
  companyId: number;
  status: string;
  closeModal: () => void;
};

export const UpdateStatusPrincipalInvestment: React.FC<Props> = ({
  companyId,
  status,
  closeModal,
}) => {
  const api = useAPI();
  const { dispatch } = useAppStore();
  const initialValues = useMemo(() => transformValues(status), [status]);
  const statuses = [
    {
      label: 'Claimed',
      value: PrincipalInvestmentStatus.Claimed,
    },
    {
      label: 'Term sheet signed',
      value: PrincipalInvestmentStatus.Term_sheet_signed,
    },
    {
      label: 'Closed',
      value: PrincipalInvestmentStatus.Closed,
    },
    {
      label: 'Success',
      value: PrincipalInvestmentStatus.Success,
    },
    {
      label: 'Failed',
      value: PrincipalInvestmentStatus.Failed,
    },
  ];

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: async (payload, { resetForm }) => {
      await api.principalInvestment
        .updateStatusPrincipalInvestment(companyId, payload)
        .then(() => {
          dispatch(apiPrincipalInvestmentStateChanged({ companyId }));
          closeModal();
          resetForm();
        });
    },
  });

  const closeForm = () => {
    closeModal();
    form.resetForm();
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <Fieldset>
        <SelectInputField
          data-testid="change-status-principal-investment"
          aria-label="Status"
          options={statuses}
          value={status}
          label="Status"
          disabled={!statuses.length}
          loading={!statuses.length}
          {...useField('status', 'select', form).input}
        />
      </Fieldset>

      <Box justifySpaceBetween>
        <Button submit disabled={form.isSubmitting} loading={form.isSubmitting}>
          Add
        </Button>

        <Button
          type={ButtonType.SECONDARY}
          onClick={() => closeForm()}
          disabled={form.isSubmitting}
        >
          Cancel
        </Button>
      </Box>
    </form>
  );
};
