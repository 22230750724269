import { actionCreator, BaseAction } from '@hum/common/src/ducks/actions/base';

export type ManageInsightsSettingsRequest = {
  [key: string]: boolean;
};
export type ManageInsightsSettingsResponse = {
  permissionKey: string;
  permissionName: string;
  permissionValue: boolean;
};

export enum ManageInsightsActionType {
  MANAGE_INSIGHTS_SETTINGS_HOOK_USED = 'MANAGE_INSIGHTS_SETTINGS_HOOK_USED',
  MANAGE_INSIGHTS_SETTINGS_STATE_CHANGED = 'MANAGE_INSIGHTS_SETTINGS_STATE_CHANGED',
  MANAGE_INSIGHTS_SAVE_BUTTON_CLICKED = 'MANAGE_INSIGHTS_SAVE_BUTTON_CLICKED',
  MANAGE_INSIGHTS_CANCEL_BUTTON_CLICKED = 'MANAGE_INSIGHTS_CANCEL_BUTTON_CLICKED',
  SUCCESSFULLY_SAVED_MANAGE_INSIGHTS_SETTINGS = 'SUCCESSFULLY_SAVED_MANAGE_INSIGHTS_SETTINGS',
  FAILED_TO_SAVE_MANAGE_INSIGHTS_SETTINGS = 'FAILED_TO_SAVE_MANAGE_INSIGHTS_SETTINGS',
}

export type ManageInsightsSettingsHookUsed = BaseAction<
  ManageInsightsActionType.MANAGE_INSIGHTS_SETTINGS_HOOK_USED,
  {
    companyId: number;
  }
>;

export type ManageInsightsSaveButtonClicked = BaseAction<
  ManageInsightsActionType.MANAGE_INSIGHTS_SAVE_BUTTON_CLICKED,
  ManageInsightsSettingsRequest
>;

export type ManageInsightsCancelButtonClicked = BaseAction<
  ManageInsightsActionType.MANAGE_INSIGHTS_CANCEL_BUTTON_CLICKED,
  ManageInsightsSettingsRequest
>;

export type ManageInsightsSettingsStateChanged = BaseAction<
  ManageInsightsActionType.MANAGE_INSIGHTS_SETTINGS_STATE_CHANGED,
  ManageInsightsSettingsResponse[]
>;

export type FailedToSavedManageInsightsSettings = BaseAction<ManageInsightsActionType.FAILED_TO_SAVE_MANAGE_INSIGHTS_SETTINGS>;
export type SuccessfullySavedManageInsightsSettings = BaseAction<ManageInsightsActionType.SUCCESSFULLY_SAVED_MANAGE_INSIGHTS_SETTINGS>;

export const manageInsightsSettingsHookUsed = actionCreator<ManageInsightsSettingsHookUsed>(
  ManageInsightsActionType.MANAGE_INSIGHTS_SETTINGS_HOOK_USED
);

export const manageInsightsSettingsStateChanged = actionCreator<ManageInsightsSettingsStateChanged>(
  ManageInsightsActionType.MANAGE_INSIGHTS_SETTINGS_STATE_CHANGED
);
export const manageInsightsSaveButtonClicked = actionCreator<ManageInsightsSaveButtonClicked>(
  ManageInsightsActionType.MANAGE_INSIGHTS_SAVE_BUTTON_CLICKED
);
export const manageInsightsCancelButtonClicked = actionCreator<ManageInsightsCancelButtonClicked>(
  ManageInsightsActionType.MANAGE_INSIGHTS_CANCEL_BUTTON_CLICKED
);
export const successfullySavedManageInsightsSettings = actionCreator<SuccessfullySavedManageInsightsSettings>(
  ManageInsightsActionType.SUCCESSFULLY_SAVED_MANAGE_INSIGHTS_SETTINGS
);
export const failedToSavedManageInsightsSettings = actionCreator<FailedToSavedManageInsightsSettings>(
  ManageInsightsActionType.FAILED_TO_SAVE_MANAGE_INSIGHTS_SETTINGS
);

export type ManageInsightsSettingsActions =
  | ManageInsightsSettingsHookUsed
  | ManageInsightsSettingsStateChanged
  | FailedToSavedManageInsightsSettings
  | SuccessfullySavedManageInsightsSettings
  | ManageInsightsSaveButtonClicked
  | ManageInsightsCancelButtonClicked;
