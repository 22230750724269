import React from 'react';
import { Empty, Spinner, Table } from '@hum/ui-library';
import { useConnectors } from '@hum/icm-app/src/hooks/useConnectors';
import ConnectionRow from './ConnectionRow';

export const CompanyServiceConnections = ({
  companyId,
}: {
  companyId: number;
}) => {
  const { data: connectors = [] } = useConnectors(companyId);

  if (!connectors) return <Spinner />;

  if (connectors.length === 0) {
    return <Empty message="There are no services connected yet." />;
  }

  return (
    <Table>
      <Table.Head
        className="bg-defaultBg"
        labels={[
          'Service',
          'Created',
          'Status',
          'Health',
          'Last Pull',
          'Pull ETA',
          '',
          '',
        ]}
      />
      <Table.Body>
        {connectors.map((connector) => (
          <ConnectionRow connector={connector} />
        ))}
      </Table.Body>
    </Table>
  );
};
