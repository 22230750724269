import React from 'react';
import { ConnectorSource, Connector } from '@hum/icm-app/src/state';
import { useDisconnectService } from '@hum/icm-app/src/hooks/useService';
import { ButtonType, MoreButton } from '@hum/ui-library';

const ConnectionActions = ({ connector }: { connector: Connector }) => {
  const { handleDisconnectClick, loading } = useDisconnectService({
    connector,
  });

  // Airbyte connectors do not support any actions
  if (connector.connectorSource === ConnectorSource.Airbyte) return null;

  return (
    <MoreButton
      items={[
        {
          label: 'Disconnect',
          type: ButtonType.DESTRUCTIVE,
          onClick: handleDisconnectClick,
          loading,
        },
      ]}
    />
  );
};

export default ConnectionActions;
