import React from 'react';
import { CompanyContact } from '@hum/types';
import { DescriptionList, InfoSection, Text, TextType } from '@hum/ui-library';

export const ContactDetails = ({
  companyContact,
}: {
  companyContact?: CompanyContact;
}) => {
  if (!companyContact) {
    return null;
  }

  return (
    <InfoSection title="Contact Details">
      <DescriptionList>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Contact name
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>
            {`${companyContact.firstName} ${companyContact.lastName}` || '–'}
          </Text>
        </dd>

        <dt>
          <Text type={TextType.BODY_S} subdued>
            Contact email
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>
            <a
              href={`mailto:${companyContact.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-green hover:underline"
            >
              {companyContact.email || '–'}
            </a>
          </Text>
        </dd>

        <dt>
          <Text type={TextType.BODY_S} subdued>
            Contact phone number
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>{companyContact.phone || '–'}</Text>
        </dd>
      </DescriptionList>
    </InfoSection>
  );
};
