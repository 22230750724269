import React from 'react';

import {
  REVENUE_MODEL_V6_SELECT_OPTIONS,
  USE_OF_FUNDS_OPTIONS,
  CAPITAL_DEPLOYMENT_OPTIONS,
} from '@hum/types';
import * as ds from '@hum/design-system';
import {
  Box,
  Grid,
  Field,
  Fieldset,
  TextInputField,
  SelectInputField,
} from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import {
  MNDA_SELECT_OPTIONS,
  LAST_FUNDING_ROUND_OPTIONS,
  EQUITY_RAISED_SELECT_OPTIONS,
  FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS,
  FUNDING_TYPE_OPTIONS,
  US_STATES_SELECT_OPTIONS,
  COUNTRY_SELECT_OPTIONS_GROUPED,
} from '@hum/icm-app/src/components/constants';
import { IndustrySelect } from '@hum/icm-app/src/components/IndustrySelect';
import { useAddCompanyForm } from './useAddCompanyForm';

interface AddCompanyProps {
  onClose: () => void;
  onCancel: () => void;
  title: string;
  visible: boolean;
}

export const AddCompanyForm: React.FC<AddCompanyProps> = ({
  onClose,
  title,
  visible,
}) => {
  const UNITED_STATES = 'US';
  const { form, fields } = useAddCompanyForm({
    onSubmit: onClose,
  });
  return (
    <ds.Modal
      fullHeight
      testId="edit-company-form"
      visible={visible}
      onSubmit={form.handleSubmit}
      onClose={onClose}
      veryWide
      scrollable
      header={title}
      footer={
        <Box fill justifySpaceBetween>
          <Button
            type={ButtonType.SECONDARY}
            onClick={() => {
              form.resetForm();
              onClose();
            }}
            disabled={form.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            submit
            disabled={form.isSubmitting}
            loading={form.isSubmitting}
          >
            Add
          </Button>
        </Box>
      }
    >
      <Fieldset>
        <Grid templateColumns="1fr">
          <TextInputField
            {...fields.contact.email}
            label="Work email"
            autoFocus
            inputTestId="email"
          />
          <Grid templateColumns="1fr 1fr">
            <TextInputField
              {...fields.contact.firstName}
              label="First Name"
              secondary
              inputTestId="firstname"
            />
            <TextInputField
              {...fields.contact.lastName}
              label="Last Name"
              secondary
              inputTestId="lastname"
            />
          </Grid>
          <TextInputField
            {...fields.contact.position}
            label="Job title"
            inputAriaLabel="position"
            inputTestId="position"
          />
          <Fieldset label="Company info">
            <SelectInputField
              {...fields.company.pendingMndaSignOffline}
              label="MNDA"
              inputAriaLabel="mnda"
              infoText={
                'Custom MNDA requires a manual upload to mark it as complete'
              }
              options={MNDA_SELECT_OPTIONS}
              inputTestId="mnda"
            />
            <TextInputField
              {...fields.company.name}
              label="Legal business name"
              inputTestId="companyName"
            />
            <SelectInputField
              v3
              label="Headquarters country"
              {...fields.company.country}
              data-testid="country"
              options={COUNTRY_SELECT_OPTIONS_GROUPED}
            />
            <SelectInputField
              {...fields.company.state}
              v3
              label="Headquarters state"
              data-testid="state"
              options={US_STATES_SELECT_OPTIONS}
              disabled={
                fields.company.country.value !== UNITED_STATES ? true : false
              }
            />
            <TextInputField
              {...fields.company.website}
              label="Website"
              inputTestId="website"
            />
            <TextInputField
              {...fields.company.application.yearFounded}
              aria-label="Year Founded"
              label="Year Founded"
              inputTestId="yearFounded"
            />
            <Field
              {...fields.company.application.selfReportedIndustry}
              v3
              aria-label="Self-reported Industry"
              label="Self-reported NAICS Industry"
              data-testid="industry"
            >
              {(props) => <IndustrySelect {...props} />}
            </Field>
            <SelectInputField
              {...fields.company.application.revenueModel}
              aria-label="Revenue Model"
              label="Revenue Model"
              options={REVENUE_MODEL_V6_SELECT_OPTIONS}
              inputTestId="revenueModel"
            />
            <TextInputField
              {...fields.company.application.investmentAmount}
              label="Target raise amount"
              inputAriaLabel="Target raise amount"
              data-testid="signup:investment-amount"
              inputTestId="investmentAmount"
              inputMode={ds.InputMode.DOLLAR}
            />
            {/* New field */}
            <SelectInputField
              {...fields.company.application.fundingType}
              label="Funding type"
              inputAriaLabel="Funding type"
              data-testid="signup:funding-type"
              options={FUNDING_TYPE_OPTIONS}
              inputTestId="fundingType"
            />
            <SelectInputField
              {...fields.company.application.fundraisingTimeFrame}
              label="Fundraising timeframe"
              inputAriaLabel="Fundraising timeframe"
              data-testid="signup:funding-timeframe"
              options={FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS}
              inputTestId="fundraisingTimeFrame"
            />
            <SelectInputField
              {...fields.company.application.useOfFunds}
              label="What do you plan to use the funds for?"
              inputAriaLabel="What do you plan to use the funds for?"
              inputTestId="useOfFunds"
              data-testid="useOfFunds"
              testId="useOfFunds"
              options={USE_OF_FUNDS_OPTIONS}
            />
            <SelectInputField
              {...fields.company.application.capitalDeployment}
              label="How would you like to receive the funds?"
              inputAriaLabel="How would you like to receive the funds?"
              inputTestId="capitalDeployment"
              data-testid="capitalDeployment"
              testId="capitalDeployment"
              options={CAPITAL_DEPLOYMENT_OPTIONS}
            />

            <SelectInputField
              {...fields.company.application.lastStageOfFunding}
              label="Most recent financing round"
              inputAriaLabel="Most recent financing round"
              options={LAST_FUNDING_ROUND_OPTIONS}
              inputTestId="lastStageOfFunding"
            />
            <TextInputField
              {...fields.company.application.dateOfLastRaise}
              inputMode={ds.InputMode.MONTH_YEAR_DATE}
              label="Date of last raise (optional)"
              infoText="MM/YYYY"
              inputAriaLabel="Date of last raise"
              inputTestId="dateOfLastRaise"
            />
            <SelectInputField
              {...fields.company.application.equityRaised}
              label="Total equity raised to date (optional)"
              isClearable
              inputAriaLabel="Total equity raised to date (optional)"
              options={EQUITY_RAISED_SELECT_OPTIONS}
              inputTestId="equityRaised"
            />
            <TextInputField
              {...fields.company.application.lastValuation}
              label="Last valuation (optional)"
              inputMode={ds.InputMode.DOLLAR}
              inputAriaLabel="Last valuation (optional)"
              inputTestId="lastValuation"
            />
          </Fieldset>
        </Grid>
      </Fieldset>
    </ds.Modal>
  );
};
