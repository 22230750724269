import React from 'react';
import { DescriptionList, InfoSection, Text, TextType } from '@hum/ui-library';
import { getCompanyExtras, useApiQuery } from '@hum/api';

export const CompanyExtrasSection = () => {
  const { data: companyExtras } = useApiQuery(getCompanyExtras);

  if (!companyExtras) return null;

  const {
    industry,
    subIndustry,
    sicCodes,
    primaryNAICS,
    secondaryNAICS,
    yearFounded,
  } = companyExtras;

  return (
    <InfoSection title="Zoominfo data">
      <DescriptionList>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Industry
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>{industry || '-'}</Text>
        </dd>

        <dt>
          <Text type={TextType.BODY_S} subdued>
            Sub-industry
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>{subIndustry || '-'}</Text>
        </dd>

        <dt>
          <Text type={TextType.BODY_S} subdued>
            SIC codes
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>{sicCodes || '-'}</Text>
        </dd>

        <dt>
          <Text type={TextType.BODY_S} subdued>
            NAICS classification
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>
            {primaryNAICS || secondaryNAICS || '-'}
          </Text>
        </dd>
        {primaryNAICS && secondaryNAICS && (
          <>
            <dt></dt>
            <dd>
              <Text type={TextType.BODY_S}>{secondaryNAICS}</Text>
            </dd>
          </>
        )}

        <dt>
          <Text type={TextType.BODY_S} subdued>
            Year founded
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>{yearFounded || '-'}</Text>
        </dd>
      </DescriptionList>
    </InfoSection>
  );
};
