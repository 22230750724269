import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import { Question, QuestionType } from './Question';

export function FAQs({
  questions,
  title,
  description,
}: {
  questions: QuestionType[];
  title: string;
  description: string;
}) {
  return (
    <div
      className="mx-auto max-w-3xl py-10 px-4 sm:px-2 border-b border-borderColor z-20 relative"
      data-testid="smartraise::faqs"
    >
      <div className="grid grid-cols-1 gap-5 md:grid-cols-10 ">
        <div className="md:col-span-4">
          <Text type={TextType.HEADING_S} subdued>
            {title}
          </Text>
          <Text type={TextType.DISPLAY_S} className="mt-4" subdued>
            {description}
          </Text>
        </div>
        <div className="md:col-span-6">
          {questions.map((item) => (
            <Question key={item.text} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
}
