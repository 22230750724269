import React, { useEffect, useState } from 'react';

import * as ci from './company-investors.pc';
import { PageContent, SelectOption } from '@hum/legacy-ui';

import { Modal } from '@hum/design-system';
import { Button, ButtonType, Spinner } from '@hum/ui-library';
import {
  CompanyMatchInvestor,
  getUserCompanyId,
  BlockPermissionsPayloadType,
  SyndicationPermissions,
} from '@hum/icm-app/src/state';

import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useGetInvestorDetails } from '@hum/icm-app/src/hooks/useGetInvestorDetails';
import { useHistory } from 'react-router';
import { MatchPassModal } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/MatchPassModal';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import * as baseStyles from '@hum/icm-app/src/pages/syndication-v2/base.pc';

type ModalMessage = {
  title: string;
  message: string;
};

const DATA_ROOM_PERM = 'dataroom';
const FINANCIAL_ANALYSIS_PERM = 'financial_analysis';

export const CompanyInvestorsDetailsPage = () => {
  const api = useAPI();
  const { state } = useAppStore();
  const history = useHistory();
  const companyId = getUserCompanyId(state);
  const matchUuid = state.history?.query.uuid;

  const notAvailable = 'N/A';

  const [archiveSharedSpacesModal, setArchiveSharedSpacesModal] = useState(
    false
  );
  const [investorDetail, setInvestorDetail] = useState<CompanyMatchInvestor>();
  const [
    investorCompanyPerms,
    setInvestorCompanyPerms,
  ] = useState<SyndicationPermissions>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState<ModalMessage>();
  const [
    payloadModal,
    setPayLoadModal,
  ] = useState<BlockPermissionsPayloadType>();

  const handleClose = () => {
    setShowConfirmModal(false);
  };

  const { data: investorDetailData } = useGetInvestorDetails(
    companyId,
    matchUuid
  );
  const { data: permsData } = state.syndicationPermissions;

  useEffect(() => {
    setInvestorCompanyPerms(permsData);
  }, [permsData]);

  useEffect(() => {
    setInvestorDetail(investorDetailData);
  }, [investorDetailData]);

  const postInvestorCompanyPerms = async (payload: {
    permissions_set_name: string;
    enable: boolean;
  }) => {
    api.syndication
      .updateInvestorPermissions(
        companyId,
        investorDetail?.investorUserId,
        payload
      )
      .then((res) => {
        if (res) {
          setInvestorCompanyPerms((prev) => ({
            ...prev,
            [payload.permissions_set_name]: payload.enable,
          }));
        }
        setShowConfirmModal(false);
      })
      .catch((error) => {
        throw error;
      });
  };

  const askForConfirmation = async (payload: {
    permissions_set_name: string;
    enable: boolean;
  }) => {
    const action =
      investorCompanyPerms && investorCompanyPerms[payload.permissions_set_name]
        ? 'disable'
        : 'enable';
    const permission = payload.permissions_set_name as keyof typeof options;
    const options = {
      [DATA_ROOM_PERM]: {
        enable: {
          title: 'Enable the Data room?',
          message:
            'By enabling the Data room you allow this investor to view and download all of your Data room documents',
        },
        disable: {
          title: 'Disable Data room?',
          message:
            'By disabling the Data room you revoke this investors access to view and download your Data room documents',
        },
      },
      [FINANCIAL_ANALYSIS_PERM]: {
        enable: {
          title: 'Enable insights?',
          message:
            'By enabling insights you allow this investor to view all of the charts generated by Hum Capital based off your documents and connectors',
        },
        disable: {
          title: 'Disable insights?',
          message:
            'By disabling insights you revoke this investors access to view charts generated by Hum Capital based off your documents and connectors',
        },
      },
    };
    setShowConfirmModal(true);
    setMessageModal({
      title: options[permission][action].title,
      message: options[permission][action].message,
    });
    setPayLoadModal(payload);
  };

  const ArchiveSharedSpacesModal = () => (
    <MatchPassModal
      title="Archiving your shared space will permanently remove you as a investment oppurtunity for this investor. \
      The investor will no longer have access to any of your permissioned data."
      subtitle="Let us know why you’ve decided to archive this investor"
      userType="investors"
      isOpen={archiveSharedSpacesModal}
      onClose={() => {
        setArchiveSharedSpacesModal(false);
      }}
    />
  );
  return (
    <baseStyles.Container>
      <Topbar />
      {companyId && matchUuid && investorDetail ? (
        <PageContent fixedWidth>
          <ci.InvestorDetail
            clickGoBack={() => {
              history.goBack();
            }}
            title={investorDetail.name || notAvailable}
            description={investorDetail.description || notAvailable}
            location={investorDetail.location || notAvailable}
            website={investorDetail.website || notAvailable}
            investment_types={
              (investorDetail.investmentTypes &&
                investorDetail.investmentTypes
                  .map((item: SelectOption) => item.label)
                  .join(', ')) ||
              notAvailable
            }
            investment_ranges={
              (investorDetail.investmentRanges &&
                investorDetail.investmentRanges
                  .map((item: SelectOption) => item.label)
                  .join(', ')) ||
              notAvailable
            }
            dataroomEnabled={investorCompanyPerms?.dataroom}
            onClickDataroom={() =>
              askForConfirmation({
                permissions_set_name: DATA_ROOM_PERM,
                enable: !investorCompanyPerms?.dataroom,
              })
            }
            insightsEnabled={investorCompanyPerms?.financial_analysis}
            onClickInsights={() =>
              askForConfirmation({
                permissions_set_name: FINANCIAL_ANALYSIS_PERM,
                enable: !investorCompanyPerms?.financial_analysis,
              })
            }
            handleArchiveSharedSpacesModal={() =>
              setArchiveSharedSpacesModal(true)
            }
          />
          {archiveSharedSpacesModal && <ArchiveSharedSpacesModal />}

          {payloadModal && (
            <Modal
              visible={showConfirmModal}
              onClose={handleClose}
              header={messageModal?.title}
              wide
              footer={
                <>
                  <Button type={ButtonType.SECONDARY} onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type={
                      payloadModal.enable
                        ? ButtonType.PRIMARY
                        : ButtonType.DESTRUCTIVE
                    }
                    onClick={() => {
                      postInvestorCompanyPerms(payloadModal);
                    }}
                  >
                    {payloadModal.enable ? `Allow Access` : `Revoke Access`}
                  </Button>
                </>
              }
            >
              <p>{messageModal?.message}</p>
            </Modal>
          )}
        </PageContent>
      ) : (
        <Spinner fullScreen />
      )}
    </baseStyles.Container>
  );
};
