import React, { useEffect, useState } from 'react';
import * as radioInputStyles from '@hum/icm-app/src/pages/onboarding/RadioInput/index.pc';
import {
  Company,
  CompanyFileDocumentType,
  CompanyType,
  CompanyFile,
} from '@hum/types';
import { InfoSection } from '@hum/ui-library';
import { Documents } from '../../DocumentManagement/Documents';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { confirm } from '@hum/common/src/modules/confirm';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

export const SyndicationLegalAgreements = ({
  company,
}: {
  company: Company;
}) => {
  const isSyndicationInvestor = company?.type.includes(
    CompanyType.SyndicationInvestor
  );
  const api = useAPI();
  const fileManager = useFileManager({
    companyId: company.id,
    filter: (file: CompanyFile) => !file?.meta?.uploadedByAdmin,
  });
  const { dispatch } = useAppStore();
  const [
    skipLegalAgreementsRequest,
    setSkipLegalAgreementsRequest,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (company.syndicationInvestor?.skipLegalAgreementsRequest !== undefined)
      setSkipLegalAgreementsRequest(
        company.syndicationInvestor?.skipLegalAgreementsRequest
      );
  }, [company.syndicationInvestor?.skipLegalAgreementsRequest]);

  const askForConfirmation = async () => {
    const message = skipLegalAgreementsRequest
      ? 'Are you sure you want to disable this option?'
      : 'Are you sure you want to enable this option?';
    if (await confirm({ message })) {
      await api.syndication.updateSkipSyndicationLegalAgreementsRequestStatus(
        dispatch
      )(company.id, {
        skipRequest: !skipLegalAgreementsRequest,
      });
    }
  };

  return isSyndicationInvestor ? (
    <InfoSection title="Syndication">
      <radioInputStyles.Container
        label={'Skip legal agreements acceptance'}
        onClick={() => askForConfirmation()}
        className="pb-4"
        testId="skip-legal-agreements-toggleadmin-page:main-area:overview:investment-profile:skip-legal-agreements-toggle"
      >
        <radioInputStyles.Input on={skipLegalAgreementsRequest} />{' '}
      </radioInputStyles.Container>

      <Documents
        title="Legal Agreements"
        company={company}
        documentType={CompanyFileDocumentType.SyndicationLegalAgreement}
        fileManager={fileManager}
        canDelete
      />
    </InfoSection>
  ) : null;
};
