import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum ConfettiActionType {
  CONFETTI_DISPLAY = 'CONFETTI_DISPLAY',
  CONFETTI_DISPLAYED = 'CONFETTI_DISPLAYED',
}

export const confettiDisplay = actionCreator<
  BaseAction<ConfettiActionType.CONFETTI_DISPLAY>
>(ConfettiActionType.CONFETTI_DISPLAY);

export const confettiDisplayed = actionCreator<
  BaseAction<ConfettiActionType.CONFETTI_DISPLAYED>
>(ConfettiActionType.CONFETTI_DISPLAYED);

export type ConfettiAction = ReturnType<
  typeof confettiDisplay | typeof confettiDisplayed
>;
