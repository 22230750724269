import React from 'react';
import { ConnectorOption } from '@hum/types';
import { ConnectionCardTitle, ConnectionCardItem } from '.';

export type ConnectionCardProps = {
  connections: ConnectorOption[];
};

export const ConnectionCard = ({ connections }: ConnectionCardProps) => (
  <div className="mb-10 rounded-lg border-borderColor border-2">
    <ConnectionCardTitle
      icon={connections[0].config.icon}
      label={connections[0].config.label}
    />
    {connections.map((connection, index) => (
      <ConnectionCardItem
        key={`${connection.id}-${index}`}
        connection={connection}
      />
    ))}
  </div>
);
