import React from "react";
import _pubDa25D269 from "../../../../../design-system/src/__DEPRECATED__tailwind.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var FileItem = React.memo(React.forwardRef(function FileItem(props, ref) {
  return React.createElement("div", {
    "className": "_8c6f38fb _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_FileItem _pub-c4abb4aa_FileItem _pub-a97d53f3_FileItem FileItem",
    "ref": ref,
    "title": props["title"],
  }, 
    props["icon"],
    React.createElement("div", {
      "className": "_ef6744ad _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_content _pub-c4abb4aa_content _pub-a97d53f3_content content",
    }, 
      React.createElement("div", {
        "className": "_2d32d0f6 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_upper _pub-c4abb4aa_upper _pub-a97d53f3_upper upper",
      }, 
        React.createElement("span", {
          "className": "_831fc161 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_file_name _pub-c4abb4aa_file_name _pub-a97d53f3_file_name file_name _c4abb4aa_flex _pub-c4abb4aa_flex _pub-a97d53f3_flex flex _c4abb4aa_items-center _pub-c4abb4aa_items-center _pub-a97d53f3_items-center items-center",
        }, 
          props["fileName"],
          props["deleteButton"]
        )
      ),
      React.createElement("div", {
        "className": "_5a35e060 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_lower _pub-c4abb4aa_lower _pub-a97d53f3_lower lower",
      }, 
        React.createElement("span", {
          "className": "_82ddab56 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_file_date _pub-c4abb4aa_file_date _pub-a97d53f3_file_date file_date",
        }, 
          props["timestamp"]
        ),
        props["lower"]
      )
    ),
    props["controls"]
  )
}));
export { FileItem };

var ExternalFileItem = React.memo(React.forwardRef(function ExternalFileItem(props, ref) {
  return React.createElement("div", {
    "className": "_6bd715fc _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("a", {
      "className": "_e5f969a7 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
      "href": props["source"],
      "target": "_blank",
      "rel": "noopener noreferrer",
    }, 
      props["source"]
    ),
    props["children"]
  )
}));
export { ExternalFileItem };

var ExternalFileItemLink = React.memo(React.forwardRef(function ExternalFileItemLink(props, ref) {
  return React.createElement("a", {
    "className": "_91790d4a _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
    "ref": ref,
    "href": props["href"],
    "target": "_blank",
    "rel": "noopener noreferrer",
  }, 
    React.createElement("svg", {
      "className": "_440a0261 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
      "viewBox": "0 0 24 24",
      "size": "16",
      "width": "16",
      "height": "16",
      "xmlns": "http://www.w3.org/2000/svg",
    }, 
      React.createElement("path", {
        "className": "_a2c537ea _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
        "d": "m13 9h5.5l-5.5-5.5v5.5m-7-7h8l6 6v12a2 2 0 0 1 -2 2h-12c-1.11 0-2-.9-2-2v-16c0-1.11.89-2 2-2m5 2h-5v16h5 7v-9h-7v-7z",
        "fill": "#42a5f5",
      }, null)
    ),
    React.createElement("div", {
      "className": "_dd0353db _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_a08389b3 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_upper _pub-c4abb4aa_upper _pub-a97d53f3_upper upper",
      }, 
        React.createElement("span", {
          "className": "_1ca61ec0 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_file_name _pub-c4abb4aa_file_name _pub-a97d53f3_file_name file_name",
        }, 
          props["fileName"]
        )
      ),
      React.createElement("div", {
        "className": "_398ad809 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_lower _pub-c4abb4aa_lower _pub-a97d53f3_lower lower",
      }, 
        React.createElement("span", {
          "className": "_1ee0a099 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
        }, 
          props["fileDate"]
        )
      )
    )
  )
}));
export { ExternalFileItemLink };

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_7f776c66 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_v3 _pub-c4abb4aa_v3 _pub-a97d53f3_v3 v3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Wrapper };

var Item = React.memo(React.forwardRef(function Item(props, ref) {
  return React.createElement("div", {
    "className": "_9614c953 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa__container _pub-c4abb4aa__container _pub-a97d53f3__container _container " + " " + "_da25d269_flex _pub-da25d269_flex flex" + " " + " " + " " + "_da25d269_flex-col _pub-da25d269_flex-col flex-col" + " " + " " + " " + "_da25d269_relative _pub-da25d269_relative relative" + " " + " " + " " + "_da25d269_overflow-hidden _pub-da25d269_overflow-hidden overflow-hidden",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_34049a2b _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa__header _pub-c4abb4aa__header _pub-a97d53f3__header _header " + " " + "_da25d269_flex _pub-da25d269_flex flex" + " " + " " + " " + "_da25d269_justify-between _pub-da25d269_justify-between justify-between" + " " + " " + " " + "_da25d269_px-4 _pub-da25d269_px-4 px-4" + " " + " " + " " + "_da25d269_py-2 _pub-da25d269_py-2 py-2" + " " + " " + " " + "_da25d269_mb-px _pub-da25d269_mb-px mb-px",
    }, 
      props["header"],
      React.createElement("span", {
        "className": "_5687fb1d _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
      }, 
        props["subtitle"]
      ),
      React.createElement("div", {
        "className": "_cf8eaaa7 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa_plus_button _pub-c4abb4aa_plus_button _pub-a97d53f3_plus_button plus_button",
        "onClick": props["onPlusButtonClick"],
      }, null)
    ),
    React.createElement("div", {
      "className": "_4303aabd _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "_c4abb4aa__content _pub-c4abb4aa__content _pub-a97d53f3__content _content " + " " + "_da25d269_flex-1 _pub-da25d269_flex-1 flex-1" + " " + " " + " " + "_da25d269_p-3 _pub-da25d269_p-3 p-3",
    }, 
      props["children"]
    )
  )
}));
export { Item };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_781aa87f _c4abb4aa _pub-c4abb4aa _pub-a97d53f3" + " " + "v3",
    "ref": ref,
    "humDark": props["humDark"],
  }, 
    React.createElement(Wrapper, {
      "class": "_37804e45",
    }, 
      React.createElement(Item, {
        "class": "_5b4098eb",
        "subtitle": "subtitle",
        "header": "",
      }, 
        React.createElement(FileItem, {
          "class": "_47412ad",
          "icon": React.createElement(React.Fragment, {
          }, null),
          "fileName": "file.png",
          "timestamp": "Feb 11, 2021, 9:07 PM",
          "controls": React.createElement(React.Fragment, {
          }, 
            React.createElement(_pub2Dbb4493, {
              "class": "_21a3e359 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
              "primary": true,
              "xsmall": true,
              "outline": true,
            }, 
              "Map"
              
            ),
            React.createElement(_pub2Dbb4493, {
              "class": "_56a4d3cf _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
              "primary": true,
              "xsmall": true,
              "outline": true,
            }, 
              "Download"
              
            )
          ),
          "lower": React.createElement(React.Fragment, {
          }, 
            "Lower item"
            
          ),
        }, null),
        React.createElement(ExternalFileItem, {
          "class": "_7373223b",
          "source": "file",
        }, 
          React.createElement(ExternalFileItemLink, {
            "class": "_aef8a513",
            "fileDate": "date",
            "fileName": "file name",
          }, null)
        )
      ),
      React.createElement(Item, {
        "class": "_2c47a87d",
        "subtitle": "subtitle",
        "header": "",
      }, 
        React.createElement(FileItem, {
          "class": "_5b6789a",
          "icon": React.createElement(React.Fragment, {
          }, null),
          "fileName": "file.png",
          "timestamp": "Feb 11, 2021, 9:07 PM",
          "controls": React.createElement(_pub2Dbb4493, {
            "class": "_7d0d42b9 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
            "primary": true,
            "xsmall": true,
            "outline": true,
          }, 
            "Download"
            
          ),
          "lower": React.createElement(React.Fragment, {
          }, null),
        }, null)
      ),
      React.createElement(Item, {
        "class": "_b54ef9c7",
        "subtitle": "subtitle",
        "header": "",
      }, 
        React.createElement(FileItem, {
          "class": "_7f0c6c3",
          "icon": React.createElement(React.Fragment, {
          }, null),
          "fileName": "file.png",
          "timestamp": "Feb 11, 2021, 9:07 PM",
          "controls": React.createElement(_pub2Dbb4493, {
            "class": "_6fb8ed57 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
            "primary": true,
            "xsmall": true,
            "outline": true,
          }, 
            "Download"
            
          ),
          "lower": React.createElement(React.Fragment, {
          }, null),
        }, null)
      )
    )
  )
}));

var XCircle = React.memo(React.forwardRef(function XCircle(props, ref) {
  return React.createElement("div", {
    "className": "_ba545e89 _c4abb4aa _pub-c4abb4aa _pub-a97d53f3",
    "ref": ref,
  }, null)
}));
export { XCircle };


