import React from 'react';
import { useHistory } from 'react-router';
import isEqual from 'lodash/isEqual';
import { format } from 'url';
import { Company, CompanyType } from '@hum/types';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { ADMIN_COMPANY_DETAIL_TAB_ID } from '@hum/icm-app/src/pages/admin';
import { useQuery } from '@hum/common/src/hooks/useUrlQuery';
import { Empty } from '@hum/ui-library';
import { useCompanies } from '@hum/icm-app/src/hooks/useCompanies';
import { CompanyTypeTag } from '../../CompanyTypeTag';
import * as styles from './styles.pc';
import { analytics } from '@hum/common';

type CompanyListItem = Pick<Company, 'id' | 'name' | 'type'>;

const defaultCompanyState = {
  companies: [] as Company[],
  pagination: {
    pageCount: 1,
    results: 0,
    currentPage: 1,
    pageSize: 1,
  },
};

export const CompanyList: React.FC<{
  currentCompanyId?: number;
}> = ({ currentCompanyId }) => {
  const { data: { companies } = defaultCompanyState } = useCompanies();
  const { state } = useAppStore();
  const history = useHistory();

  const {
    [ADMIN_COMPANY_DETAIL_TAB_ID]: adminTab,
    ...params
  }: { [ADMIN_COMPANY_DETAIL_TAB_ID]?: string } = useQuery();

  const currentCompanyType = state.currentCompany?.data?.type || [];

  const onCompanyClick = (companyId: number, type: CompanyType[]) => {
    history.push(
      format({
        query: {
          ...params,
          company_id: companyId,
          // do not send tab if company type is different (falls back to 1st tab when swticing from a company to an investor)
          ...(isEqual(currentCompanyType?.slice().sort(), type.slice().sort())
            ? { [ADMIN_COMPANY_DETAIL_TAB_ID]: adminTab }
            : {}),
        },
      })
    );
    // @ts-ignore
    analytics.track('admin:view_company');
  };

  return companies.length ? (
    <styles.Container>
      {companies.map(({ id, name, type }: CompanyListItem) => (
        <styles.CompanyFilterItem
          key={id}
          active={currentCompanyId === id}
          onClick={() => onCompanyClick(id, type)}
        >
          {name}
          <CompanyTypeTag type={type} />
        </styles.CompanyFilterItem>
      ))}
    </styles.Container>
  ) : (
    <Empty isBoxless message="No companies match the current filters/search." />
  );
};
