import React from 'react';
import { Text, TextType, Icon } from '@hum/ui-library';
import { Card } from './Card';

export function NextSteps() {
  const cards = [
    {
      icon: <Icon.CircleUser />,
      title: 'Chat with your funding expert',
      description:
        "Our team will guide you through the next steps to ensure you're ready to meet your SmartRaise investor.",
    },
    {
      icon: <Icon.CircleSignature />,
      title: 'Sign our advisory agreement',
      description:
        'At Hum, we only succeed if you do. Our team will share our advisory agreement for you to review and sign.',
    },
    {
      icon: <Icon.CircleFileCheck />,
      title: 'Receive your SmartRaise terms!',
      description:
        'Our team will introduce you to an interested investor who’s ready to share more about your terms.',
    },
  ];
  return (
    <div
      className="max-w-3xl mx-5 py-10 md:mx-auto"
      data-testid="smartraise::next-steps"
    >
      <Text type={TextType.DISPLAY_S}>How to receive your terms</Text>
      <Text type={TextType.BODY_M} className="mt-5" subdued>
        The SmartRaise process can provide your business with access to capital,
        quickly and easily.
      </Text>
      <div className="my-12 grid grid-cols-1 gap-10 md:grid-cols-3 md:gap-5">
        {cards.map((card, index) => (
          <Card key={index} {...card} testId={`smartraise::card::${index}}`} />
        ))}
      </div>
    </div>
  );
}
