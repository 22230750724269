import React from 'react';
import { Text, TextType } from '@hum/ui-library';

export function Disclaimer({ testId }: { testId?: string }) {
  return (
    <div
      className="mx-auto max-w-3xl pt-12 mb-28 sm:mb-36 px-4 sm:px-2"
      data-testid={testId}
    >
      <Text type={TextType.BODY_MICO} disabled>
        This preliminary term is non-binding and subject to further due
        diligence. <br /> Securities are offered through ICM Capital Markets,
        LLC, Member FINRA/SIPC. Find out more about us on{' '}
        <a href="/" className="ui-link">
          FINRA's Brokercheck website
        </a>
        .The information summarized on this page represents the views of Hum
        Capital at this time; its views may change without notice. The
        information presented herein may not be accurate or complete. This
        information does not constitute an offer to sell or a solicitation to
        buy any securities. By clicking the Explore this opportunity button
        below, you acknowledge this disclaimer and agree to keep this
        information strictly confidential.
      </Text>
    </div>
  );
}
