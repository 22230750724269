import { Action } from '@hum/icm-app/src/actions';
import produce from 'immer';
import { AppState } from '@hum/icm-app/src/state';
import { ConfettiActionType } from './actions';

export const confettiReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case ConfettiActionType.CONFETTI_DISPLAY: {
      return produce(state, (newState) => {
        newState.showConfetti = true;
      });
    }
    case ConfettiActionType.CONFETTI_DISPLAYED: {
      return produce(state, (newState) => {
        newState.showConfetti = false;
      });
    }
  }

  return state;
};
