// Libraries
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { format } from 'url';
// Components
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { client as backend } from '@hum/common/src/api/client';
import { toast } from '@hum/common/src/modules/toast';
import { useForm } from '@hum/common/src/modules/form';
import { Button, ButtonType } from '@hum/ui-library';
import { Alert, Box } from '@hum/legacy-ui';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { apiBalanceSheetPartnerCreated } from '@hum/icm-app/src/actions';
import {
  AddBalanceSheetPartnerFormFields,
  initialValues as defaultValues,
  BalanceSheetPartner,
  validationSchema,
} from './FormFields';
import * as ds from '@hum/design-system';

type BalanceSheetPartnerFormPayload = {
  company: BalanceSheetPartner['company'];
  contact: BalanceSheetPartner['contact'];
};

const formatBalanceSheetPartner = (payload: BalanceSheetPartnerFormPayload) => {
  const company = normalize.company.out(payload.company);
  const contact = normalize.user.out(payload.contact);

  return {
    company,
    contact,
  };
};

const AddBalanceSheetPartner = ({
  onClose,
  title,
  visible,
}: {
  onClose: () => void;
  title: string;
  visible: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAppStore();
  const history = useHistory();

  const createBalanceSheetPartner = async (
    payload: BalanceSheetPartnerFormPayload
  ) => {
    try {
      setLoading(true);
      const formattedBalanceSheetPartner = formatBalanceSheetPartner(payload);
      const response = await backend.post(
        '/companies/balance-sheet-partners',
        formattedBalanceSheetPartner,
        { withCredentials: true }
      );
      if (response.company.id) {
        dispatch(apiBalanceSheetPartnerCreated());
        toast.success('Created balance sheet partner successfully!');
        // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
        closeForm();
        history.push(
          format({
            pathname: '/admin',
            query: { company_id: response.company.id },
          })
        );
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Failed to create balance sheet partner');
    } finally {
      setLoading(false);
    }
  };

  const initialValues = defaultValues;

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: (payload: BalanceSheetPartnerFormPayload) => {
      return createBalanceSheetPartner(payload);
    },
  });

  const error = form.status;

  const closeForm = () => {
    onClose();
    form.resetForm();
  };

  return (
    <ds.Modal
      visible={visible}
      veryWide
      onSubmit={form.handleSubmit}
      scrollable
      header={title}
      footer={
        <Box justifySpaceBetween fill>
          <div>
            <Button
              type={ButtonType.SECONDARY}
              onClick={() => closeForm()}
              disabled={loading || form.isSubmitting}
            >
              Cancel
            </Button>
          </div>

          <Button
            submit
            disabled={loading || form.isSubmitting}
            loading={form.isSubmitting}
          >
            Add
          </Button>
        </Box>
      }
    >
      <AddBalanceSheetPartnerFormFields form={form} />

      {error ? <Alert negative title={error} /> : null}
    </ds.Modal>
  );
};

export default AddBalanceSheetPartner;
