import React from 'react';
import { CONNECTOR_LABELS, Connector } from '@hum/icm-app/src/state';
import { Table, TextType } from '@hum/ui-library';
import { formatDate, formatDateFromNow } from '@hum/icm-app/src/utils';
import { ConnectorStatusBadge } from './ConnectorStatusBadge';
import { ConnectorHealthBadge } from './ConnectorHealthBadge';
import DataPullButton from './DataPullButton';
import ConnectionActions from './ConnectionActions';
import { Text } from '@hum/ui-library';

const ConnectionRow = ({ connector }: { connector: Connector }) => {
  return (
    <Table.Row key={connector.id} className="grid grid-cols-1 sm:table-row">
      <Table.Data className="px-4">
        {CONNECTOR_LABELS[connector.connectorType] || connector.connectorType}
        <DataPullButton connector={connector} />
      </Table.Data>
      <Table.Data subdued className="px-3">
        <Text type={TextType.BODY_S}>{formatDate(connector.createdAt)}</Text>
      </Table.Data>
      <Table.Data className="px-3">
        <ConnectorStatusBadge connector={connector} />
      </Table.Data>
      <Table.Data className="px-3 min-w-[50px]">
        <ConnectorHealthBadge connector={connector} />
      </Table.Data>
      <Table.Data subdued className="px-3">
        {connector.lastSuccessfulPullAt
          ? formatDateFromNow(connector.lastSuccessfulPullAt)
          : 'n/a'}
      </Table.Data>
      <Table.Data subdued className="px-3">
        {connector.eta || 'n/a'}
      </Table.Data>
      <Table.Data>
        <ConnectionActions connector={connector} />
      </Table.Data>
    </Table.Row>
  );
};

export default ConnectionRow;
