import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum AnalayticsActionType {
  ANALYTICS_EVENT_TRACK_REQUESTED = 'ANALYTICS_EVENT_TRACK_REQUESTED',
}

export type AnalyticsEventTrackRequested = BaseAction<
  AnalayticsActionType.ANALYTICS_EVENT_TRACK_REQUESTED,
  {
    name: string;
    includeRoleScope?: boolean;
    data: any;
  }
>;

export const analyticsEventTrackRequested = actionCreator<AnalyticsEventTrackRequested>(
  AnalayticsActionType.ANALYTICS_EVENT_TRACK_REQUESTED
);

export type AnalayticsAction = AnalyticsEventTrackRequested;
