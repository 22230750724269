import React from 'react';
import { Connector } from '@hum/icm-app/src/state';
import { getConnectedServiceStatus } from '@hum/icm-app/src/pages/onboarding-v5/components/OnboardingConnectStep_DeprecateAfterM2_5/connectServiceUtils';
import { Badge } from '@hum/ui-library';
import classNames from 'classnames';

export function ConnectorStatusBadge({ connector }: { connector: Connector }) {
  const { isActive, isPending, isCritical } = getConnectedServiceStatus(
    connector
  );
  const isUnknown = !isActive && !isPending && !isCritical;

  return (
    <Badge
      testId="serviceStatus"
      className={classNames(
        'bg-blue-600',
        isCritical && 'bg-red-600',
        isActive && 'bg-green-600',
        isUnknown && 'bg-orange-600'
      )}
    >
      {isCritical && (
        <span title="We lost access to this connection">
          {connector.status}
        </span>
      )}
      {isActive && 'connected'}
      {isPending && 'pending'}
      {isUnknown && connector.status}
    </Badge>
  );
}
