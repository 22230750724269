import React from "react";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pub2F51C399 from "../../../../../design-system/src/breakpoints.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import _pub23E6Ba1B, {DownloadFinancialBar as _pub23E6Ba1B_DownloadFinancialBar} from "../../syndication-v2/company-overview.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pub8D610C4 from "../../../../../design-system/src/PageContent.pc";
import _pub4435Fe58 from "../../../../../design-system/src/Pill.pc";
import "./admin-analytics.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var TitleBarWithButtons = React.memo(React.forwardRef(function TitleBarWithButtons(props, ref) {
  return React.createElement("div", {
    "className": "_e37a53fc _92816ae0 _pub-92816ae0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_7a4605af _92816ae0 _pub-92816ae0 _pub-a97d53f3" + " " + "_92816ae0_text-heading-small _pub-92816ae0_text-heading-small _pub-a97d53f3_text-heading-small text-heading-small",
    }, 
      props["title"]
    ),
    React.createElement("div", {
      "className": "_e34f5415 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_1c99e80a _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        "onClick": props["onClickCancel"],
        "v3": true,
        "disabled": !props["ableToEdit"],
        "testId": "admin:manage-insights:cancel-button",
      }, 
        "Cancel\n    "
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_6b9ed89c _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        "v3": true,
        "primary": true,
        "disabled": !props["ableToEdit"],
        "onClick": props["onClickSave"],
        "testId": "admin:manage-insights:save-button",
      }, 
        "\n      Save\n    "
        
      )
    )
  )
}));
export { TitleBarWithButtons };

var TitleBar = React.memo(React.forwardRef(function TitleBar(props, ref) {
  return React.createElement("div", {
    "className": "_91f1a00c _92816ae0 _pub-92816ae0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_4a4e353f _92816ae0 _pub-92816ae0 _pub-a97d53f3" + " " + "_92816ae0_text-heading-small _pub-92816ae0_text-heading-small _pub-a97d53f3_text-heading-small text-heading-small",
    }, 
      props["title"]
    )
  )
}));
export { TitleBar };

var BackArrowTitle = React.memo(React.forwardRef(function BackArrowTitle(props, ref) {
  return React.createElement("div", {
    "className": "_7fffc120 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_49cae151 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
      "testId": "preview-invite-back-button",
      "transparent": true,
      "small": true,
      "onClick": props["clickGoBack"],
    }, 
      React.createElement("img", {
        "className": "_4480bf1b _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        "src": getDefault(require("@hum/icm-app/src/pages/portfolio-monitoring/assets/icon.svg")),
      }, null)
    ),
    React.createElement("div", {
      "className": "_d0c3b0eb _92816ae0 _pub-92816ae0 _pub-a97d53f3" + " " + "_92816ae0_text-heading-X _pub-92816ae0_text-heading-X _pub-a97d53f3_text-heading-X text-heading-X",
      "id": props["id"],
    }, 
      props["title"]
    )
  )
}));
export { BackArrowTitle };

var SectionHeading = React.memo(React.forwardRef(function SectionHeading(props, ref) {
  return React.createElement("div", {
    "className": "_969c6415 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_3a40ad75 _92816ae0 _pub-92816ae0 _pub-a97d53f3" + " " + "_92816ae0_text-heading-X _pub-92816ae0_text-heading-X _pub-a97d53f3_text-heading-X text-heading-X",
      "id": props["id"],
    }, 
      props["title"]
    ),
    React.createElement("div", {
      "className": "_4d479de3 _92816ae0 _pub-92816ae0 _pub-a97d53f3" + " " + "_92816ae0_text-body-medium _pub-92816ae0_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
    }, 
      props["subtitle"]
    )
  )
}));
export { SectionHeading };

var ManageFinancialInsightsVisibility = React.memo(React.forwardRef(function ManageFinancialInsightsVisibility(props, ref) {
  return React.createElement("div", {
    "className": "_e19b5483 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_4c85f7d4 _92816ae0 _pub-92816ae0 _pub-a97d53f3" + " " + "_92816ae0_text-body-medium _pub-92816ae0_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
    }, 
      React.createElement("div", {
        "className": "_f6a0630b _92816ae0 _pub-92816ae0 _pub-a97d53f3",
      }, 
        React.createElement("span", {
          "className": "_70656735 _92816ae0 _pub-92816ae0 _pub-a97d53f3" + (props["toggleHidden"] ? " " + "_92816ae0_toggleHidden _pub-92816ae0_toggleHidden _pub-a97d53f3_toggleHidden toggleHidden" : ""),
        }, 
          props["sectionLabel"]||"N/A"
        ),
        props["toggleHidden"]&&React.createElement(_pub4435Fe58, {
          "class": "_bb034f24 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        }, 
          "\n        Hidden\n      "
          
        )
      )
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_d58ca66e _92816ae0 _pub-92816ae0 _pub-a97d53f3",
      "transparent": true,
      "small": true,
      "onClick": props["handleToggleVisibilityButton"],
      "testId": "investor-analitycs-v1:toggle-visibility-company-financials-button",
    }, 
      !props["toggleHidden"]&&React.createElement("svg", {
        "className": "_68513865 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        "width": "21",
        "height": "20",
        "viewBox": "0 0 21 20",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg",
      }, 
        React.createElement("g", {
          "className": "_77a23791 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
          "clipPath": "url(#clip0_1554_4986)",
        }, 
          React.createElement("path", {
            "className": "_97cd114a _92816ae0 _pub-92816ae0 _pub-a97d53f3",
            "d": "M1.41553 9.99967C1.41553 9.99967 4.74886 3.33301 10.5822 3.33301C16.4155 3.33301 19.7489 9.99967 19.7489 9.99967C19.7489 9.99967 16.4155 16.6663 10.5822 16.6663C4.74886 16.6663 1.41553 9.99967 1.41553 9.99967Z",
            "stroke": "#7E899A",
            "strokeWidth": "2",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
          }, null),
          React.createElement("path", {
            "className": "_e0ca21dc _92816ae0 _pub-92816ae0 _pub-a97d53f3",
            "d": "M10.582 12.5C11.9627 12.5 13.082 11.3807 13.082 10C13.082 8.61929 11.9627 7.5 10.582 7.5C9.20132 7.5 8.08203 8.61929 8.08203 10C8.08203 11.3807 9.20132 12.5 10.582 12.5Z",
            "stroke": "#7E899A",
            "strokeWidth": "2",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
          }, null)
        ),
        React.createElement("defs", {
          "className": "_a50707 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        }, 
          React.createElement("clipPath", {
            "className": "_960f7b7d _92816ae0 _pub-92816ae0 _pub-a97d53f3",
            "id": "clip0_1554_4986",
          }, 
            React.createElement("rect", {
              "className": "_1a6c41bc _92816ae0 _pub-92816ae0 _pub-a97d53f3",
              "width": "20",
              "height": "20",
              "fill": "white",
              "transform": "translate(0.582031)",
            }, null)
          )
        )
      ),
      props["toggleHidden"]&&React.createElement("svg", {
        "className": "_714a0924 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        "width": "21",
        "height": "20",
        "viewBox": "0 0 21 20",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg",
      }, 
        React.createElement("g", {
          "className": "_cf1e50f4 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
          "clipPath": "url(#clip0_1554_5439)",
        }, 
          React.createElement("path", {
            "className": "_5c91c2ef _92816ae0 _pub-92816ae0 _pub-a97d53f3",
            "d": "M8.83219 3.53303C9.4058 3.39876 9.99308 3.33164 10.5822 3.33303C16.4155 3.33303 19.7489 9.99969 19.7489 9.99969C19.243 10.946 18.6397 11.837 17.9489 12.658M12.3489 11.7664C12.12 12.012 11.844 12.209 11.5373 12.3456C11.2307 12.4823 10.8996 12.5557 10.5639 12.5617C10.2283 12.5676 9.89483 12.5058 9.58353 12.3801C9.27224 12.2544 8.98946 12.0672 8.75207 11.8298C8.51467 11.5924 8.32752 11.3096 8.20178 10.9984C8.07605 10.6871 8.0143 10.3536 8.02022 10.018C8.02614 9.68228 8.09962 9.35123 8.23626 9.04457C8.3729 8.7379 8.5699 8.4619 8.81553 8.23303M15.5322 14.9497C14.1077 16.0355 12.3731 16.6371 10.5822 16.6664C4.74886 16.6664 1.41553 9.99969 1.41553 9.99969C2.4521 8.06794 3.88981 6.3802 5.63219 5.0497L15.5322 14.9497Z",
            "stroke": "#7E899A",
            "strokeWidth": "2",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
          }, null),
          React.createElement("path", {
            "className": "_2b96f279 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
            "d": "M1.41553 0.833008L19.7489 19.1663",
            "stroke": "#7E899A",
            "strokeWidth": "2",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
          }, null)
        ),
        React.createElement("defs", {
          "className": "_b8196062 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        }, 
          React.createElement("clipPath", {
            "className": "_5d53a8d8 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
            "id": "clip0_1554_5439",
          }, 
            React.createElement("rect", {
              "className": "_d6c64122 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
              "width": "20",
              "height": "20",
              "fill": "white",
              "transform": "translate(0.582031)",
            }, null)
          )
        )
      )
    )
  )
}));
export { ManageFinancialInsightsVisibility };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_f9535af _92816ae0 _pub-92816ae0 _pub-a97d53f3",
    "ref": ref,
    "humDark": true,
  }, 
    React.createElement(_pub8D610C4, {
      "class": "_3806132c _92816ae0 _pub-92816ae0 _pub-a97d53f3",
      "fixedWidth": true,
      "center": true,
      "topSpace": true,
    }, 
      React.createElement(TitleBarWithButtons, {
        "class": "_faa56aca",
        "title": "Manage insights",
      }, null),
      React.createElement(SectionHeading, {
        "class": "_8da25a5c",
        "subtitle": "Download the file below to preview the changes on this file",
        "title": "Document preview",
      }, null),
      React.createElement(_pub23E6Ba1B_DownloadFinancialBar, {
        "class": "_14ab0be6 _92816ae0 _pub-92816ae0 _pub-a97d53f3",
        "companyName": "Company Name",
      }, null),
      React.createElement(SectionHeading, {
        "class": "_63ac3b70",
        "subtitle": "Use the components below to show and hide individual tabs within the Company Financials Excel download",
        "title": "Content customization",
      }, null),
      React.createElement(ManageFinancialInsightsVisibility, {
        "class": "_fdc8aed3",
        "sectionLabel": "EBITDA",
      }, null),
      React.createElement(ManageFinancialInsightsVisibility, {
        "class": "_8acf9e45",
        "hidden": true,
        "sectionLabel": "Disabled",
      }, null)
    )
  )
}));


