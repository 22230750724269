// Libraries
import React, { useState } from 'react';
// Components
import { Button, Empty } from '@hum/ui-library';
import { PageTitle, StyledTable, TableCell, TableRow } from '@hum/legacy-ui';
import { usePortfolioCompanies } from '@hum/icm-app/src/hooks/usePortfolioCompanies';
import { AddCompanyToPorfolio } from './AddCompanyToPorfolio';
import { Investee } from './Investee';

// CompanyManager is only going to be used by admins
// This component is designed to link investors with companies
// companyID, companyName are properties of company of type investor
export const CompanyManager: React.FC<{
  companyID: number;
  companyName: string;
  companyIsPortfolioInvestor: boolean;
}> = ({ companyID, companyName, companyIsPortfolioInvestor }) => {
  const [addCompanyToPortfolioOpen, setAddCompanyToPortfolioOpen] = useState(
    false
  );
  const companies = usePortfolioCompanies(companyID);

  return (
    <>
      <PageTitle
        title="Manage Companies"
        noCenter
        action={
          <>
            {companyIsPortfolioInvestor ? (
              <Button onClick={() => setAddCompanyToPortfolioOpen(true)}>
                Link Company
              </Button>
            ) : null}
          </>
        }
      />
      {companies.length === 0 ? (
        <Empty message="There are no companies linked to this investor." />
      ) : (
        <StyledTable secondary>
          <TableRow header>
            <TableCell>Company Name</TableCell>
            <TableCell>Business Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
          {companies.map((company: any) => (
            <Investee
              key={company.id}
              investorID={companyID}
              investee={company}
              showActions={companyIsPortfolioInvestor}
            />
          ))}
        </StyledTable>
      )}

      <AddCompanyToPorfolio
        isOpen={addCompanyToPortfolioOpen}
        companyName={companyName}
        companyID={companyID}
        handleClose={() => {
          setAddCompanyToPortfolioOpen(false);
        }}
      />
    </>
  );
};
