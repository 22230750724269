import React, { memo } from 'react';
import * as styles from './styles.pc';
import { Filters } from './Filters';
import { CompanyList } from './CompanyList';
import { Scrollable } from '@hum/ui-library';

export type AdminLeftSidebarProps = {
  currentCompanyId: number;
};

export const AdminLeftSidebar = memo(
  ({ currentCompanyId }: AdminLeftSidebarProps) => {
    return (
      <styles.Container>
        <Filters />
        <Scrollable>
          <CompanyList currentCompanyId={currentCompanyId} />
        </Scrollable>
      </styles.Container>
    );
  }
);
