import React, { useEffect, useState } from 'react';

import { Company } from '@hum/types';
import { AppState } from '@hum/icm-app/src/state';
import {
  Icon,
  InfoSection,
  ProgressDownload,
  SectionCard,
  Text,
  TextType,
} from '@hum/ui-library';
import {
  CompanyInfo,
  CompanySignatures,
} from '@hum/icm-app/src/components/CompanyInfo';
import { FinancialInsightsSection } from '@hum/icm-app/src/components/FinancialInsights';
import { CompanyServiceConnections } from './CompanyServiceConnections';
import { DataAssets } from './DataAssets';
import { CompanyFiles } from './CompanyFiles';
import { CompanyInvestors } from './CompanyInvestors';
import { InternalConfig } from './InternalConfig';
import { DownloadModelFile } from '@hum/icm-app/src/pages/syndication-v2/MatchDataRoom';
import { CompanyExtrasSection } from './CompanyExtrasSection';
import { BulkDownloadModal } from '@hum/icm-app/src/components/BulkDownloadModal';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

import { useBulkDownload } from '@hum/icm-app/src/hooks/useBulkDownload';
import { useBulkDownloadFromSelection } from '@hum/icm-app/src/hooks/useBulkDownloadFromSelection';
import { useAnalyticsEvent, getFinancialInsights, useApiQuery } from '@hum/api';

export const CompanyOverview = ({
  companyContact,
  company,
}: {
  companyContact: AppState['currentCompanyUser'];
  company: Company;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const flags = useFlags();
  const bulkDownloadModalEnabled = flags.enabled('enable-bulk-download-modal');
  const trackEvent = useAnalyticsEvent();
  const {
    handleDownloadAllFilesClick,
    taskStatus,
    text,
    polling,
    cancelPolling,
  } = useBulkDownload({
    companyId: company.id,
  });
  const { fileList, ...bulkDownloadModalProps } = useBulkDownloadFromSelection({
    companyId: company.id,
  });

  const { data: insights, isLoading: insightsLoading } = useApiQuery(
    getFinancialInsights,
    {
      params: {
        companyId: company.id,
      },
    }
  );

  useEffect(() => {
    trackEvent('insights:company_overview:visit', {}, true);
  }, []);

  return (
    <>
      {bulkDownloadModalEnabled && (
        <BulkDownloadModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          fileList={fileList}
          {...bulkDownloadModalProps}
        />
      )}
      <InfoSection title="Company Info">
        {companyContact.data && (
          <CompanyInfo contact={companyContact.data} company={company} />
        )}
      </InfoSection>

      <CompanyExtrasSection />

      <InfoSection title="Signatures">
        <CompanySignatures company={company} />
      </InfoSection>

      <InfoSection title="Investors">
        <CompanyInvestors company={company} />
      </InfoSection>

      <SectionCard className="overflow-x-auto custom-scrollbar my-4 rounded">
        <Text type={TextType.HEADING_M} className="mb-8">
          Service Connections
        </Text>
        <CompanyServiceConnections companyId={company?.id} />
      </SectionCard>

      <InfoSection title="Data Assets">
        <DataAssets company={company} />
      </InfoSection>

      {bulkDownloadModalEnabled ? (
        <InfoSection
          title="Financial Files"
          primaryAction={{
            text: 'Download',
            disabled:
              bulkDownloadModalProps.polling ||
              (!fileList?.customerFiles.length &&
                !fileList?.supportedFinancialDocuments.length),
            icon: (
              <Icon.Download className="mr-2" width="16" height="16" light />
            ),
            onClick: () => setIsOpen(true),
          }}
        >
          <CompanyFiles company={company} />
        </InfoSection>
      ) : (
        <InfoSection
          title="Financial Files"
          primaryAction={{
            text: 'Download All',
            disabled: polling,
            icon: (
              <Icon.Download className="mr-2" width="16" height="16" light />
            ),
            onClick: handleDownloadAllFilesClick,
          }}
        >
          <CompanyFiles company={company} />
        </InfoSection>
      )}
      <InfoSection title="Hum standardized data">
        <DownloadModelFile companyId={company.id} />
      </InfoSection>
      <InfoSection title="Financial Insights">
        <FinancialInsightsSection
          insights={insights}
          loading={insightsLoading}
        />
      </InfoSection>

      <InfoSection title="Internal Config">
        <InternalConfig />
      </InfoSection>

      {bulkDownloadModalEnabled ? (
        <ProgressDownload
          text={bulkDownloadModalProps.text}
          polling={bulkDownloadModalProps.polling}
          status={bulkDownloadModalProps.taskStatus?.state}
          onCancel={bulkDownloadModalProps.cancelPolling}
        />
      ) : (
        <ProgressDownload
          text={text}
          polling={polling}
          status={taskStatus?.state}
          onCancel={cancelPolling}
        />
      )}
    </>
  );
};
