import React from "react";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Company = React.memo(React.forwardRef(function Company(props, ref) {
  return React.createElement("button", {
    "className": "_cee9e599 _27bc4e0e _pub-27bc4e0e" + (props["selected"] ? " " + "_27bc4e0e_selected _pub-27bc4e0e_selected selected" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Company };

var StyledFilters = React.memo(React.forwardRef(function StyledFilters(props, ref) {
  return React.createElement("div", {
    "className": "_20e784b5 _27bc4e0e _pub-27bc4e0e",
    "ref": ref,
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { StyledFilters };

var StyledFilterBox = React.memo(React.forwardRef(function StyledFilterBox(props, ref) {
  return React.createElement("div", {
    "className": "_c9842180 _27bc4e0e _pub-27bc4e0e",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { StyledFilterBox };

var StyledCompanyList = React.memo(React.forwardRef(function StyledCompanyList(props, ref) {
  return React.createElement("nav", {
    "className": "_278a40ac _27bc4e0e _pub-27bc4e0e" + (props["loading"] ? " " + "_27bc4e0e_loading _pub-27bc4e0e_loading loading" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { StyledCompanyList };


