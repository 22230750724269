import React from 'react';
import * as styles from './styles.pc';
import { FileManager } from '../../../__DEPRECATED__modules/files';
import { Documents } from './Documents';
import { CompanyFileDocumentType, Company } from '@hum/types';

type Props = {
  fileManager: FileManager;
  company: Company;
};

export const DocumentManagement: React.FC<Props> = ({
  fileManager,
  company,
}) => (
  <styles.Wrapper>
    <Documents
      title="Financial Summary"
      company={company}
      documentType={CompanyFileDocumentType.FinancialSummary}
      fileManager={fileManager}
      canDelete
    />
    <Documents
      title="Income Statement"
      company={company}
      documentType={CompanyFileDocumentType.IncomeStatement}
      fileManager={fileManager}
      canDelete
    />
    <Documents
      title="Balance Sheet"
      company={company}
      documentType={CompanyFileDocumentType.BalanceSheet}
      fileManager={fileManager}
      canDelete
    />
    <Documents
      title="Capitalization Table"
      company={company}
      documentType={CompanyFileDocumentType.CapTable}
      fileManager={fileManager}
      canDelete
    />
    <Documents
      title="Transaction Data"
      company={company}
      documentType={CompanyFileDocumentType.CustomerTape}
      fileManager={fileManager}
      canDelete
    />
    <Documents
      title="Business overview deck"
      company={company}
      documentType={CompanyFileDocumentType.BusinessOverviewDeck}
      fileManager={fileManager}
      canDelete
    />
    <Documents
      title="Other files"
      company={company}
      documentType={CompanyFileDocumentType.Other}
      fileManager={fileManager}
      canDelete
    />
  </styles.Wrapper>
);
