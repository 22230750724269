import React from 'react';
import { CompanyType } from '@hum/types';
import { CompanyTypeTag } from '../CompanyTypeTag';
import { Pill, SelectOption } from '@hum/legacy-ui';
import { Text, TextType } from '@hum/ui-library';

export const AdminTitle: React.FC<{
  name: string;
  website?: string | null;
  type: CompanyType[];
  tags: SelectOption[];
  segment?: string;
}> = ({ name, website, type, tags, segment }) => (
  <div>
    <Text type={TextType.DISPLAY_S} testId="admin-page:main-area:company-name">
      {name}
      <CompanyTypeTag type={type} />
    </Text>
    {tags && tags.map((tag) => <Pill key={tag.value}>{tag.value}</Pill>)}
    {segment && <Pill>{segment}</Pill>}
    {website && (
      <Text type={TextType.BODY_S}>
        <a
          href={website}
          className="ui-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {website}
        </a>
      </Text>
    )}
  </div>
);
