import React from 'react';

import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { apiPrincipalInvestmentCreated } from '@hum/icm-app/src/actions';
import { InfoSection, Empty } from '@hum/ui-library';
import { usePrincipalInvestments } from '@hum/icm-app/src/hooks/usePrincipalInvestments';
import { PrincipalInvestmentItem } from '../PrincipalInvestmentItem';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import {
  createPrincipalInvestment,
  getPrincipalInvestments,
  useApiMutation,
  useApiQuery,
} from '@hum/api';

export const PrincipalInvestmentList = ({
  companyId,
}: {
  companyId: number;
}) => {
  const flags = useFlags();
  const {
    data: queryPrincipalInvestments,
    isLoading: princialInvestmentIsLoading,
  } = useApiQuery(getPrincipalInvestments, {
    params: { companyId },
  });
  const { mutate: create } = useApiMutation(createPrincipalInvestment);
  const { dispatch } = useAppStore();
  const {
    loaded: sagasLoadaded,
    data: sagasPrincipalInvestments,
  } = usePrincipalInvestments(companyId);
  const api = useAPI();

  const principalInvestments = flags.enabled('use-react-query')
    ? queryPrincipalInvestments
    : sagasPrincipalInvestments;

  const loaded = flags.enabled('use-react-query')
    ? !princialInvestmentIsLoading
    : sagasLoadaded;

  const sagasCreatePrincipalInvestment = async () => {
    await api.principalInvestment
      .createPrincipalInvestment(companyId)
      .then((response) => {
        if (response) {
          dispatch(apiPrincipalInvestmentCreated({ companyId }));
        }
      });
  };

  if (!loaded) {
    return null;
  }

  if (!principalInvestments?.length) {
    return (
      <InfoSection
        title="Principal Investment"
        primaryAction={{
          text: 'Create principal investment',
          onClick: () => {
            flags.enabled('use-react-query')
              ? create({ companyId })
              : sagasCreatePrincipalInvestment();
          },
        }}
      >
        <Empty message="There is no principal investment for this company." />
      </InfoSection>
    );
  }

  return (
    <InfoSection title="Principal Investment">
      {principalInvestments?.map((principalInvestment: any) => (
        <PrincipalInvestmentItem
          key={principalInvestment.id}
          principalInvestment={principalInvestment}
          companyId={companyId}
        />
      ))}
    </InfoSection>
  );
};
