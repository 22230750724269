import React, { useEffect, useState, useMemo } from 'react';
import { useForm, useField } from '@hum/common/src/modules/form';
import { Field, SelectInput } from '@hum/legacy-ui';
import { Modal } from '@hum/design-system';
import * as Yup from 'yup';
import { Button, ButtonType } from '@hum/ui-library';
import { Company } from '@hum/types';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

export type DataMapInfo = {
  propertyNames: string[];
  initialValues: Object;
  validationSchema: Yup.Schema<unknown>;
  getOptions(uri: string): Promise<string[]>;
};
type Props = {
  dataMapInfo: DataMapInfo;
  fileId: number;
  company: Company;
  onCancel: any;
  onSaved: any;
};

export const DataMapForm: React.FC<Props> = React.memo(
  ({
    fileId,
    company: { id: companyId },
    dataMapInfo: { propertyNames, getOptions, validationSchema, initialValues },
    onCancel,
    onSaved,
  }) => {
    const api = useAPI();
    const [options, setOptions] = useState<string[]>([]);
    const [initialFormValues, setInitialFormValues] = useState<Object>(
      initialValues
    );
    const selectOptions = useMemo(
      () => [
        { label: '-- Select column --', value: '' },
        ...options.map((value) => {
          return {
            label: value,
            value,
          };
        }),
      ],
      [options]
    );

    // Need to fetch field values from backend to for form
    useEffect(() => {
      api.legacy
        .getFile(companyId, fileId)
        .then(({ download_url, file: { meta } }) => {
          // Note we need to use raw meta values here to prevent customer tape
          // fields from being normalized. E.g: transaction_date -> transactionDate
          setInitialFormValues({
            ...initialValues,
            ...(meta.customer_tape_mapping || {}),
          });

          // after loading file, get mappable fields
          getOptions(download_url).then((options) => {
            setOptions(options.filter(Boolean));
          });
        });
    }, [propertyNames, fileId]);

    const form = useForm({
      initialValues: initialFormValues,
      validationSchema,
      async onSubmit(values: unknown) {
        await api.legacy.updateFile(companyId, fileId, {
          meta: {
            customerTapeMapping: values,
          },
        });

        onSaved();
      },
    });

    return (
      <Modal
        onSubmit={form.submitForm}
        header={<>Transaction Mapping</>}
        testId="customer-transaction-form"
        onClose={onCancel}
        scrollable
        fullHeight
        footer={
          <>
            {form.status}

            <Button
              type={ButtonType.SECONDARY}
              testId="cancel-button"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              testId="save-button"
              disabled={form.isSubmitting}
              onClick={() => {
                form.submitForm();
              }}
            >
              Save
            </Button>
          </>
        }
      >
        {propertyNames.map((propertyName) => {
          return (
            <Field
              label={propertyName}
              {...useField(propertyName, 'select', form).input}
            >
              {(props) => {
                return <SelectInput {...props} options={selectOptions} />;
              }}
            </Field>
          );
        })}
      </Modal>
    );
  }
);
