import React from 'react';
import * as Yup from 'yup';
import { useField, Form } from '@hum/common/src/modules/form';
import { URL_REGEX } from '@hum/icm-app/src/utils/url';
import { COUNTRY_SELECT_OPTIONS_GROUPED } from '@hum/icm-app/src/components/constants';
import {
  Grid,
  Fieldset,
  TextInputField,
  SelectInputField,
} from '@hum/legacy-ui';

export type AddPortfolioInvestorForm = Pick<any, 'company' | 'contact'>;

export const initialValues = {
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    phone: '',
  },
  company: {
    name: '',
    website: '',
    country: undefined,
  },
};

export const validationSchema = Yup.object().shape({
  contact: Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
    position: Yup.string().nullable(),
    phone: Yup.string().nullable(),
  }),

  company: Yup.object().shape({
    name: Yup.string().required('Company name is required'),
    website: Yup.string()
      .trim()
      .matches(URL_REGEX, 'Please enter a valid URL')
      .required('Website is required'),
    country: Yup.string().required('Investor country is required'),
  }),
});

type Props = {
  form: Form<AddPortfolioInvestorForm>;
  includePosition?: boolean;
  showDescriptionFields?: boolean;
};

export const FormFields = ({ form }: Props) => {
  return (
    <>
      <Fieldset label="Investor Overview">
        <TextInputField
          label="Company Name"
          placeholder="Hum Capital"
          {...useField('company.name', 'text', form).input}
          inputTestId="company-name"
        />
        <TextInputField
          label="Company Website"
          {...useField('company.website', 'text', form).input}
          inputTestId="company-website"
        />
      </Fieldset>
      <Fieldset label="Contact">
        <Grid templateColumns="1fr 1fr">
          <TextInputField
            label="First Name"
            {...useField('contact.firstName', 'text', form).input}
            inputTestId="firstname"
          />
          <TextInputField
            label="Last Name"
            {...useField('contact.lastName', 'text', form).input}
            inputTestId="lastname"
          />
        </Grid>
        <TextInputField
          label="Email Address"
          {...useField('contact.email', 'text', form).input}
          inputTestId="email"
        />
        <TextInputField
          label="Phone Number"
          {...useField('contact.phone', 'text', form).input}
          inputTestId="phone"
        />
        <TextInputField
          label="Position"
          {...useField('contact.position', 'text', form).input}
        />
      </Fieldset>
      <Fieldset label="Investor Detail">
        <SelectInputField
          label="Country"
          {...useField('company.country', 'select', form).input}
          inputAriaLabel="country"
          inputTestId="company-country"
          options={COUNTRY_SELECT_OPTIONS_GROUPED}
        />
      </Fieldset>
    </>
  );
};
