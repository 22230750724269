import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { URL_REGEX } from '@hum/icm-app/src/utils/url';

import { SignUpV5Form } from '@hum/icm-app/src/state';
import { useUrlQuery } from '@hum/common/src/hooks/useUrlQuery';
import { useField, useForm } from '@hum/common/src/modules/form';

import { MndaOptions } from '@hum/icm-app/src/components/types';
import { adminAddCompanyFormSubmitted } from '@hum/icm-app/src/actions';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { createCommpanyFromAdmin, useApiMutation } from '@hum/api';

const isDate = (date: Date) => {
  return new Date(date).toString() !== 'Invalid Date';
};

// User
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const EMAIL = 'email';
const COMPANY_POSITION = 'position';

// Company
const COMPANY_NAME = 'name';
const STATE = 'state';
const COUNTRY = 'country';
const WEBSITE = 'website';
const PENDING_MNDA_SIGN_OFFLINE = 'pendingMndaSignOffline';

// Application
const YEAR_FOUNDED = 'yearFounded';
const SELF_REPORTED_INDUSTRY = 'selfReportedIndustry';
const REVENUE_MODEL = 'revenueModel';
const INVESTMENT_AMOUNT = 'investmentAmount';
const FUNDRAISING_TIME_FRAME = 'fundraisingTimeFrame';
const FUNDING_TYPE = 'fundingType';
const USE_OF_FUNDS = 'useOfFunds';
const CAPITAL_DEPLOYMENT = 'capitalDeployment';
const LAST_FUNDING_ROUND = 'lastFundingRound';
const LAST_RAISED = 'lastRaised';
const TOTAL_EQUITY_RAISED = 'equityRaised';
const LATEST_POST_MONEY_VALUATION = 'latestPostMoneyValuation';

const MIN_INVESTMENT_AMOUNT = 1;
const MAX_INVESTMENT_AMOUNT = 999999999;
const MIN_YEAR_FOUNDED = 578;
const MAX_YEAR_FOUNDED = new Date().getFullYear();
const MIN_POST_MONEY_VALUATION = 100000;

const INITIAL_VALUES = {
  contact: {
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [EMAIL]: '',
    [COMPANY_POSITION]: '',
  },
  company: {
    [COMPANY_NAME]: undefined,
    [STATE]: undefined,
    [COUNTRY]: undefined,
    [WEBSITE]: '',
    pendingMndaSignOffline: MndaOptions.Default,
  },
  application: {
    [YEAR_FOUNDED]: undefined,
    [SELF_REPORTED_INDUSTRY]: '',
    [REVENUE_MODEL]: undefined,
    [FUNDRAISING_TIME_FRAME]: '',
    [INVESTMENT_AMOUNT]: undefined,
    [USE_OF_FUNDS]: undefined,
    [CAPITAL_DEPLOYMENT]: undefined,
    [FUNDING_TYPE]: undefined,
    [LAST_FUNDING_ROUND]: undefined,
    [LAST_RAISED]: '',
    [TOTAL_EQUITY_RAISED]: undefined,
    [LATEST_POST_MONEY_VALUATION]: undefined,
  },
  utmParams: {
    utmMedium: undefined,
    utmSource: undefined,
    utmCampaign: undefined,
    utmTerm: undefined,
    utmContent: undefined,
  },
};
const VALIDATION_SCHEMA = Yup.object().shape({
  contact: Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
    position: Yup.string().required('Job title is required'),
  }),
  company: Yup.object().shape({
    [COMPANY_NAME]: Yup.string().required('Legal business name required'),
    [STATE]: Yup.string(),
    [COUNTRY]: Yup.string(),
    [WEBSITE]: Yup.string()
      .trim()
      .matches(URL_REGEX, 'Please enter a valid URL')
      .required('Website is required'),
    pendingMndaSignOffline: Yup.string().required('Required'),
  }),
  application: Yup.object().shape({
    [YEAR_FOUNDED]: Yup.number()
      .typeError('Numbers only')
      .min(MIN_YEAR_FOUNDED)
      .max(MAX_YEAR_FOUNDED)
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    [SELF_REPORTED_INDUSTRY]: Yup.string().required('Required'),
    [REVENUE_MODEL]: Yup.string(),
    [INVESTMENT_AMOUNT]: Yup.number()
      .integer('Use whole numbers without notations like 1K, 1M, 1B')
      .typeError('Please enter a whole number')
      .min(MIN_INVESTMENT_AMOUNT, 'Please enter an amount greater than $0')
      .max(
        MAX_INVESTMENT_AMOUNT,
        'Please enter an amount less than or equal to $999,999,999'
      )
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    [FUNDRAISING_TIME_FRAME]: Yup.string(),
    [USE_OF_FUNDS]: Yup.string(),
    [CAPITAL_DEPLOYMENT]: Yup.string(),
    [FUNDING_TYPE]: Yup.string(),
    [LAST_FUNDING_ROUND]: Yup.string(),
    [LAST_RAISED]: Yup.string()
      .optional()
      .nullable(true)
      .default(null)
      .transform((_, val) => (val === val ? val.toString() : ''))
      .test({
        name: 'date',
        exclusive: true,
        message: 'Incorrect date',
        test: (value) => {
          if (value === null || value === '') return true;
          const textValue = value.toString();
          if (textValue.length < 5) return false;
          const year = textValue.substring(textValue.length - 4);
          const month = textValue.substring(0, textValue.length == 5 ? 1 : 2);
          const dateResult = new Date(`${month}-01-${year}`);

          if (!isDate(dateResult)) return false;
          const currentDate = new Date();
          if (dateResult > currentDate) return false;

          return true;
        },
      }),
    [LATEST_POST_MONEY_VALUATION]: Yup.number()
      .positive('Must be positive value')
      .min(MIN_POST_MONEY_VALUATION, 'Minimum amount is $100,000')
      .integer('Use whole numbers without notations like 1K, 1M, 1B')
      .nullable(true)
      .transform((_, val) => (val === val ? val : null)),
  }),
});

export function useAddCompanyForm({ onSubmit }: { onSubmit: () => void }) {
  const flags = useFlags();
  const { mutate: createCompany } = useApiMutation(createCommpanyFromAdmin);
  const [
    {
      first_name: contactFirstName,
      last_name: contactLastName,
      email: contactEmail,
      company_name: companyName,
    },
  ] = useUrlQuery();
  const dispatch = useDispatch();
  const form = useForm<SignUpV5Form>({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (payload) => {
      flags.enabled('use-react-query')
        ? createCompany(payload)
        : dispatch(adminAddCompanyFormSubmitted({ payload }));
      form.resetForm();
      onSubmit();
    },
  });

  // Replace form values with data from the URL if it's there
  useEffect(() => {
    if (contactFirstName) {
      form.setFieldValue(`contact.${FIRST_NAME}`, contactFirstName);
    }
    if (contactLastName) {
      form.setFieldValue(`contact.${LAST_NAME}`, contactLastName);
    }
    if (contactEmail) {
      form.setFieldValue(`contact.${EMAIL}`, contactEmail);
    }
    if (companyName) {
      form.setFieldValue(`company.${COMPANY_NAME}`, companyName);
    }
  }, []);

  return {
    form,
    fields: {
      contact: {
        firstName: useField(`contact.${FIRST_NAME}`, 'text', form).input,
        lastName: useField(`contact.${LAST_NAME}`, 'text', form).input,
        email: useField(`contact.${EMAIL}`, 'text', form).input,
        position: useField(`contact.${COMPANY_POSITION}`, 'text', form).input,
      },
      company: {
        name: useField(`company.${COMPANY_NAME}`, 'text', form).input,
        website: useField(`company.${WEBSITE}`, 'text', form).input,
        state: useField(`company.${STATE}`, 'select', form).input,
        country: useField(`company.${COUNTRY}`, 'select', form).input,
        pendingMndaSignOffline: useField(
          `company.${PENDING_MNDA_SIGN_OFFLINE}`,
          'select',
          form
        ).input,
        application: {
          investmentAmount: useField(
            `application.${INVESTMENT_AMOUNT}`,
            'text',
            form
          ).input,
          fundraisingTimeFrame: useField(
            `application.${FUNDRAISING_TIME_FRAME}`,
            'select',
            form
          ).input,
          useOfFunds: useField(`application.${USE_OF_FUNDS}`, 'text', form)
            .input,
          capitalDeployment: useField(
            `application.${CAPITAL_DEPLOYMENT}`,
            'text',
            form
          ).input,
          fundingType: useField(`application.${FUNDING_TYPE}`, 'select', form)
            .input,
          selfReportedIndustry: useField(
            `application.${SELF_REPORTED_INDUSTRY}`,
            'select',
            form
          ).input,
          yearFounded: useField(`application.${YEAR_FOUNDED}`, 'text', form)
            .input,
          revenueModel: useField(`application.${REVENUE_MODEL}`, 'select', form)
            .input,
          lastStageOfFunding: useField(
            `application.${LAST_FUNDING_ROUND}`,
            'select',
            form
          ).input,
          dateOfLastRaise: useField(`application.${LAST_RAISED}`, 'text', form)
            .input,
          equityRaised: useField(
            `application.${TOTAL_EQUITY_RAISED}`,
            'select',
            form
          ).input,
          lastValuation: useField(
            `application.${LATEST_POST_MONEY_VALUATION}`,
            'text',
            form
          ).input,
        },
      },
    },
  };
}
