import React from 'react';
import {
  Connector,
  ConnectorHealthStatus,
  ConnectorSource,
} from '@hum/icm-app/src/state';
import { Badge } from '@hum/ui-library';

export function ConnectorHealthBadge({ connector }: { connector: Connector }) {
  const connectorHealth =
    connector.connectorSource === ConnectorSource.Codat
      ? connector.healthStatus
      : connector.health;
  switch (connectorHealth) {
    case ConnectorHealthStatus.Pending:
      return <Badge className="bg-blue-600">Pending</Badge>;
    case ConnectorHealthStatus.Healthy:
      return <Badge className="bg-green-600">Healthy</Badge>;
    case ConnectorHealthStatus.InitialPullInProgress:
      return <Badge className="bg-blue-600">Initial Pull</Badge>;
    case ConnectorHealthStatus.PullInProgress:
      return <Badge className="bg-orange-600">Pull In Progress</Badge>;
    case ConnectorHealthStatus.Critical:
      return <Badge className="bg-red-600">Critical</Badge>;
    case ConnectorHealthStatus.Recovering:
      return <Badge className="bg-orange-600">Recovering</Badge>;
    case ConnectorHealthStatus.Stale:
      return <Badge className="text-white bg-gray-600">Stale</Badge>;
    default:
      return null;
  }
}
