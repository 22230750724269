// Libraries
import React from 'react';
import * as Yup from 'yup';
// Components
import { client as backend } from '@hum/common/src/api/client';
import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';
import { Modal } from '@hum/design-system';
import { Field, SelectInput } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useAvailableCompanies } from '@hum/icm-app/src/hooks/usePortfolioCompanies';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { investorCompanyAdded } from '@hum/icm-app/src/actions';

const initialValues = {
  investeeCompanyID: undefined,
};

// prettier-ignore
const validationSchema = Yup.object().shape({
  investeeCompanyID: Yup.string().required('Select a company'),
})

export const AddCompanyToPorfolio = ({
  isOpen,
  companyName,
  companyID: companyId,
  handleClose,
}: {
  isOpen: boolean;
  companyName: string;
  companyID: number;
  handleClose: () => void;
}) => {
  // const { companies, loading } = useAvailableCompanies({ companyID })
  const companies = useAvailableCompanies(companyId);
  const { dispatch } = useAppStore();

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: async (payload, { resetForm }) => {
      try {
        await backend.post(
          `companies/${companyId}/portfolio`,
          normalize.portfolioCompany.out({
            investeeCompanyID: Number(payload.investeeCompanyID),
          }),
          {
            withCredentials: true,
          }
        );
        toast.success('Added company to investor portfolio!');
        dispatch(investorCompanyAdded({ companyId }));
        handleClose();
        resetForm();
      } catch (error: any) {
        console.error(error);
        toast.error(
          error?.description ||
            error?.message ||
            `Failed to add company to investor portfolio.`
        );
      }
    },
  });

  const closeForm = () => {
    handleClose();
    form.resetForm();
  };

  const selectCompany = useField('investeeCompanyID', 'select', form).input;

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      onClose={handleClose}
      header={`Add company to ${companyName}'s portfolio`}
    >
      <form onSubmit={form.handleSubmit}>
        <Field v3 label="Select a company" {...selectCompany}>
          {(props) => (
            <SelectInput
              {...props}
              aria-label="Company"
              placeholder=""
              options={companies}
            />
          )}
        </Field>

        <Button submit disabled={form.isSubmitting} loading={form.isSubmitting}>
          Add
        </Button>
        <Button
          type={ButtonType.SECONDARY}
          onClick={() => closeForm()}
          disabled={form.isSubmitting}
        >
          Cancel
        </Button>
      </form>
    </Modal>
  );
};
