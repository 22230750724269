import React from 'react';
import { Disclaimer } from './Disclaimer';
import { FAQs } from './FAQs';
import { FooterCTA } from './FooterCTA';
import { SmartRaiseHero } from './SmartRaiseHero';
import { NextSteps } from './NextSteps';
import { FinancialHighlights } from './FinancialHighlights';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { QuestionType } from './FAQs/Question';
import { Spinner } from '@hum/ui-library';
import {
  expressOpportunityInterest,
  getFundingOpportunities,
  useApiMutation,
  useApiQuery,
} from '@hum/api';
import { toast } from '@hum/common/src/modules/toast';
import { scrollToTop } from '../../utils';

export function SmartRaisePageContent() {
  const { data: opportunities, isError, isLoading, isRefetching } = useApiQuery(
    getFundingOpportunities,
    {
      toastsEnabled: false,
      onError: () => toast.error('Something went wrong. Please try again.'),
      onRefetchSuccess: (data, prev) => {
        if (data?.showcased?.status !== prev?.showcased?.status) scrollToTop();
      },
    }
  );
  const {
    mutate: expressInterest,
    isLoading: isInterestPending,
  } = useApiMutation(expressOpportunityInterest, {
    toastsEnabled: false,
    onError: () => toast.error('Something went wrong. Please try again.'),
  });
  const showcasedOpportunity = opportunities?.showcased;

  if (isLoading) return <Spinner fullScreen />;
  if (isError) return null;
  if (!showcasedOpportunity) return null;

  const questions: QuestionType[] = [
    {
      text: 'What is the SmartRaise process?',
      link:
        'https://info.humcapital.com/hum-help-center/what-is-hums-smartraise-process',
    },
    {
      text: 'What financing options are available at Hum?',
      link:
        'https://info.humcapital.com/hum-help-center/what-financing-options-are-available-on-hums-intelligent-capital-market-icm',
    },
    {
      text: 'Prepare to meet with Investors',
      link:
        'https://info.humcapital.com/hum-help-center/what-should-i-do-to-prepare-to-meet-investors-via-hums-platform',
    },
    {
      text: 'Preparing for due dilligence',
      link:
        'https://info.humcapital.com/hum-help-center/how-can-i-use-hums-platform-to-help-with-the-due-diligence-process-with-investors',
    },
  ];

  const onChatWithUs = () =>
    (window.location.href = 'mailto:smartraise@humcapital.com');

  return (
    <>
      <SmartRaiseHero />
      {Object.values(showcasedOpportunity.financialHighlights).length > 1 && (
        <FinancialHighlights
          highlights={showcasedOpportunity.financialHighlights}
        />
      )}
      <NextSteps />
      <FAQs
        questions={questions}
        title="Have questions?"
        description="Learn more about Hum's fundraising process"
      />
      <Disclaimer />
      {showcasedOpportunity.isActive && (
        <FooterCTA
          onChatWithUs={onChatWithUs}
          onExploreOpportunity={() => expressInterest(showcasedOpportunity)}
          showSpinnerButton={isInterestPending || isRefetching}
        />
      )}
    </>
  );
}

function SmartMatchPage() {
  const { isLoading } = useApiQuery(getFundingOpportunities);

  if (isLoading) return <Spinner fullScreen />;

  return (
    <>
      <Topbar fixedBar />
      <SmartRaisePageContent />
    </>
  );
}

export default SmartMatchPage;
