import { call } from 'redux-saga/effects';

export const catchError = <
  TFun extends (...args: any) => Generator<any, any, any>
>(
  fn: TFun,
  onError: () => any
) =>
  function* (...args: any) {
    try {
      yield call(fn, ...args);
    } catch (error) {
      yield call(onError);
    }
  };
