import { catchError } from '@hum/icm-app/src/modules/sagas';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  ManageInsightsActionType,
  ManageInsightsSettingsRequest,
  failedToSavedManageInsightsSettings,
  manageInsightsSettingsStateChanged,
  successfullySavedManageInsightsSettings,
} from '../actions';
import { noop } from 'lodash';
import { companyDetailActions } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';
import { showCompanyFinancialSummary } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/sagas/companyDetail';
import { selectCompanyIdFromParams } from '@hum/labeling-ui/src/ducks/state';
import { API } from '@hum/icm-app/src/backend';
import { getAPI } from '@hum/icm-app/src/sagas/utils';

export function* getManageInsightsSettings(companyId: number) {
  const api: API = yield getAPI();
  try {
    const data = yield call(api.legacy.getManageInsightsSettings, companyId);
    yield put(manageInsightsSettingsStateChanged(data));
  } catch (error) {
    yield put(failedToSavedManageInsightsSettings());
    throw error;
  }
}

export function* updateManageInsightsSettings(
  data: ManageInsightsSettingsRequest
) {
  const api: API = yield getAPI();
  try {
    yield call(api.legacy.updateManageInsightsSettings, data);
    yield put(successfullySavedManageInsightsSettings());
  } catch (error) {
    yield put(failedToSavedManageInsightsSettings());
    throw error;
  }
}

export function* handleManageInsightsSettings() {
  yield takeLatest(
    [
      ManageInsightsActionType.MANAGE_INSIGHTS_SETTINGS_HOOK_USED,
      ManageInsightsActionType.SUCCESSFULLY_SAVED_MANAGE_INSIGHTS_SETTINGS,
    ],
    catchError(function* ({ payload: { companyId } }) {
      yield call(getManageInsightsSettings, companyId);
    }, noop)
  );
}

export function* handleFinancialSummaryVisibility() {
  yield takeLatest(
    [
      ManageInsightsActionType.MANAGE_INSIGHTS_SETTINGS_STATE_CHANGED,
      companyDetailActions.companyFinancialComponentShowed.type,
      ManageInsightsActionType.SUCCESSFULLY_SAVED_MANAGE_INSIGHTS_SETTINGS,
    ],
    function* () {
      const companyId = yield select(selectCompanyIdFromParams);
      yield call(showCompanyFinancialSummary, companyId);
    }
  );
}

export function* handleCustomizationSaveButtonClicked() {
  yield takeLatest(
    ManageInsightsActionType.MANAGE_INSIGHTS_SAVE_BUTTON_CLICKED,
    catchError(function* ({ payload: payload }) {
      yield call(updateManageInsightsSettings, payload);
    }, noop)
  );
}
