// definition: https://www.notion.so/capitaltechnology/Capital-s-Events-84b9eb30bb9e4eb9a39046af1399b9b6#b66ca1d2c547486b9f6e40ed89d2265d

import { Action } from 'redux';
import { call, fork, select, spawn, takeEvery } from 'redux-saga/effects';
import { AppState } from '@hum/icm-app/src/state';
import { Action as CapitalAction } from '@hum/icm-app/src/actions';
import { analytics } from '@hum/common';
import { API } from '../../backend';
import { getAPI } from '@hum/icm-app/src/sagas/utils';
import { AnalyticsEvent } from '../../backend/api/legacy';

/**
 * tracks an event that happens in the app - this can only be called in sagas.
 * @example yield trackAnalayticsEvent("login_page:visited")
 */

export function trackAnalayticsEvent(
  name: string,
  data: any = {},
  includeRoleScope = false
) {
  // We spawn here to prevent any errors from breaking the app
  return spawn(function* () {
    const api: API = yield getAPI();
    const state: AppState = yield select();

    console.debug(`@track▸${name}`);

    try {
      yield call(api.legacy.trackEvent, {
        name,
        includeRoleScope,
        email: state.session.data?.email || state.analyticsIdentity,
        payload: data,
      });
      analytics.debugAddTracked(name, data);
    } catch (e: any) {
      analytics.debugAddTracked(name, data, e);
      throw e;
    }
  });
}

export type AnalayticActionMapper =
  | string
  | ((action: CapitalAction) => string | AnalyticsEvent);
export type ActionTypeTester = string | ((action: Action) => boolean);

export type AnalayticsMapper = {
  actionType: ActionTypeTester;
  testState?: (state: any) => boolean;
  track: AnalayticActionMapper;
};

/**
 * yield trackActions([
 *   {
 *     actionType: ActionType.IMPORT_BUTTON_CLICKED,
 *     track: "company:user:login:visited"
 *   }
 *   {
 *     actionType: ActionType.IMPORT_BUTTON_CLICKED,
 *     track: "company:user:login:visited"
 *   }
 * ])
 */

export function trackActions(analyticsMappers: AnalayticsMapper[]) {
  function* handleMapper({ track, actionType, testState }: AnalayticsMapper) {
    yield takeEvery(actionType, function* (action: CapitalAction) {
      if (testState && !testState(yield select())) {
        return;
      }

      const mapResult = typeof track === 'string' ? track : track(action);
      const { name, payload, includeRoleScope }: AnalyticsEvent =
        typeof mapResult === 'string' ? { name: mapResult } : mapResult;

      yield trackAnalayticsEvent(name, payload, includeRoleScope);
    });
  }

  return fork(function* () {
    for (const mapper of analyticsMappers) {
      yield fork(handleMapper, mapper);
    }
  });
}
