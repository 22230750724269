import React, { useEffect } from 'react';
import { env } from '@hum/common';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

export const AnalayticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { state } = useAppStore();
  const currentUser = state.session.data;

  // update analytic clients once a user is available or logs out
  useEffect(() => {
    if (currentUser) {
      const username = `${currentUser?.firstName} ${currentUser?.lastName}`;

      // @ts-ignore
      analytics.identify(currentUser.email, {
        email: currentUser.email,
        userId: String(currentUser.id),
        username,
        release: env.NEXT_STATIC_RELEASE,
      });
    }
  }, [currentUser]);

  return <>{children}</>;
};
