import React from 'react';
import { useHistory } from 'react-router-dom';
import { Company } from '@hum/types';
import {
  CompanyDataAsset,
  ConnectorDataType,
  EMPTY_ARRAY,
} from '@hum/icm-app/src/state';
import { client } from '@hum/common/src/api/client';
import { Button, ButtonSize, ButtonType, Empty } from '@hum/ui-library';
import { useCompanyDataAssets } from '@hum/icm-app/src/hooks/useCompanyDataAssets';
import { Table, TableCellConfig } from '@hum/legacy-ui';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { getCompanyDataAssets, useApiQuery } from '@hum/api';

type DataAssetProps = {
  dataAsset: CompanyDataAsset;
  company: Company;
};

const DATA_ASSET_TYPE_LABEL_MAP = {
  [ConnectorDataType.QuickbooksIncomeStatement]: 'Quickbooks Income Statement',
  [ConnectorDataType.QuickbooksBalanceSheet]: 'Quickbooks Balance Sheet',
  [ConnectorDataType.CodatNetsuiteBalanceSheet]: 'Netsuite Balance Sheet',
  [ConnectorDataType.CodatNetsuiteProfitAndLoss]: 'Netsuite P&L',
  [ConnectorDataType.CodatQuickbooksSandboxBalanceSheet]:
    'QuickbooksSandbox Balance Sheet',
  [ConnectorDataType.CodatQuickbooksSandboxProfitAndLoss]:
    'QuickbooksSandbox P&L',
  [ConnectorDataType.CodatQuickbooksBalanceSheet]: 'Quickbooks Balance Sheet',
  [ConnectorDataType.CodatQuickbooksProfitAndLoss]: 'Quickbooks P&L',
  [ConnectorDataType.XeroBalanceSheet]: 'Xero Balance Sheet',
  [ConnectorDataType.XeroProfitAndLoss]: 'Xero P&L',
  [ConnectorDataType.InterpretedBraintreeTransactions]:
    'Braintree Transactions',
  [ConnectorDataType.InterpretedPaypalTransactionData]: 'Paypal Transactions',
  [ConnectorDataType.InterpretedShopifyOrders]: 'Shopify Orders',
  [ConnectorDataType.InterpretedStripeBalanceTransactions]:
    'Stripe Balance Transactions',
  [ConnectorDataType.InterpretedUploadedFileCustomerTape]:
    'Uploaded Customer Tapes',
};

const getDataAssetDisplayName = (dataAsset: CompanyDataAsset) => {
  if (dataAsset.displayName) {
    return dataAsset.displayName;
  } else if (dataAsset.dataType in DATA_ASSET_TYPE_LABEL_MAP) {
    return DATA_ASSET_TYPE_LABEL_MAP[dataAsset.dataType];
  } else {
    return dataAsset.dataType;
  }
};

// overrides
const dataAssetOverrides = {
  [ConnectorDataType.QuickbooksBalanceSheet]: 'balance_sheet',
  [ConnectorDataType.QuickbooksIncomeStatement]: 'income_statement',
  [ConnectorDataType.XeroBalanceSheet]: 'balance_sheet',
  [ConnectorDataType.XeroProfitAndLoss]: 'income_statement',
};

const generateDownloadLink = (
  companyId: number,
  dataAssetId: number,
  dataType: ConnectorDataType
) =>
  `${client.defaults.baseURL}/companies/${companyId}/files/${dataAssetId}/${dataAssetOverrides[dataType]}/download`;

const DataAssetActions = ({ dataAsset }: DataAssetProps) => {
  const {
    state: { currentCompany },
  } = useAppStore();
  const history = useHistory();

  return (
    <div>
      <Button
        size={ButtonSize.SLIM}
        type={ButtonType.SECONDARY}
        onClick={() =>
          history.push({
            pathname: `/label-mapper`,
            search: `${history.location.search}&dataAssetId=${dataAsset.id}`,
          })
        }
      >
        Go to Labeling UI
      </Button>
      {[
        ConnectorDataType.QuickbooksIncomeStatement,
        ConnectorDataType.QuickbooksBalanceSheet,
      ].includes(dataAsset.dataType as ConnectorDataType) && (
        <Button type={ButtonType.QUIET} size={ButtonSize.SLIM}>
          <a
            href={generateDownloadLink(
              currentCompany.data!.id,
              dataAsset.id,
              dataAsset.dataType as ConnectorDataType
            )}
            download
          >
            Download CSV
          </a>
        </Button>
      )}
    </div>
  );
};

type DataAssetsProps = {
  company: Company;
};

export const DataAssets = ({ company }: DataAssetsProps) => {
  const flags = useFlags();
  const { data: companyDataAssets } = useApiQuery(getCompanyDataAssets, {
    params: {
      companyId: company.id,
    },
  });
  const { data: sagaCompanyDataAssets = EMPTY_ARRAY } = useCompanyDataAssets(
    company.id
  );
  const data = flags.enabled('use-saga-data-assets')
    ? sagaCompanyDataAssets
    : companyDataAssets || [];

  const TABLE_CELLS: Record<string, TableCellConfig> = {
    service: {
      label: `Type`,
      style: {
        width: 350,
      },
      renderer: (dataAsset: any) => {
        return getDataAssetDisplayName(dataAsset.item);
      },
    },
    actions: {
      label: `Actions`,
      renderer: (dataAsset: any) => {
        return (
          <DataAssetActions
            key={dataAsset.item.id}
            dataAsset={dataAsset.item}
            company={company}
          />
        );
      },
    },
  };

  if (!data.length) {
    return <Empty message="There are no data assets yet." />;
  }

  return <Table cellConfig={TABLE_CELLS} itemKey="id" data={data!} />;
};
