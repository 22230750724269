import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { groupBy } from 'lodash';

import { Button } from '@hum/ui-library';
import { ConnectorOption } from '@hum/types';
import { EmptyConnectorTab, ConnectionCard } from '../components';

export type ConnectorTabProps = {
  connections: ConnectorOption[];
  icon: string;
  title: string;
  description: string;
};

export const ConnectorTab = ({
  connections,
  icon,
  title,
  description,
}: ConnectorTabProps) => {
  const location = useLocation();
  const history = useHistory();
  const groupedConnections = groupBy(
    connections,
    ({ connectorType }) => connectorType
  );
  return connections.length ? (
    <>
      <Button
        className="my-8"
        onClick={() =>
          history.push(`${location.pathname}/connect${location.search}`)
        }
      >
        Add linked account
      </Button>
      {Object.entries(groupedConnections).map(
        ([connectorType, connections]) => (
          <ConnectionCard key={connectorType} connections={connections} />
        )
      )}
    </>
  ) : (
    <EmptyConnectorTab icon={icon} title={title} description={description} />
  );
};
