import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

import { Text, TextType } from '@hum/ui-library';

export type TabProps = {
  label: string;
  children: React.ReactNode;
  path: string;
  isActive?: boolean;
  isLast?: boolean;
  className?: string;
  onClick?: () => void;
};

export const Tab = ({
  label,
  path,
  isActive = false,
  onClick,
  className,
}: TabProps) => {
  const history = useHistory();
  const location = useLocation();
  const handleClick = () => {
    onClick && onClick();
    history.push(`${path}${location.search}`);
  };

  return (
    <>
      <div
        className={clsx(
          {
            'border-borderColor': !isActive,
            'border-blue-400': isActive,
          },
          'border-b-2',
          'hover:bg-gray-600'
        )}
        onClick={handleClick}
      >
        <Text
          className={clsx('my-3', 'mx-4', 'hover:cursor-pointer', className)}
          type={TextType.UI_ELEMENT}
        >
          {label}
        </Text>
      </div>
    </>
  );
};
