import React from 'react';
import { Company } from '@hum/types';
import { Routes } from '@hum/icm-app/src/state';
import { DescriptionList, InfoSection, Text, TextType } from '@hum/ui-library';
import { useBranding } from '@hum/icm-app/src/hooks/useBranding';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

const DEVELOPMENT_HOSTS_REGEX = /(localhost|frontend\-e2e)/;

// BrandingDetails is only available to admins
// admins will have preferredHost set as 'icm.humcapital.[com/info]'
export const BrandingDetails = ({ company }: { company?: Company }) => {
  const { data: branding } = useBranding(company?.id || 0);
  const { state } = useAppStore();
  const preferredHost = state.session.data?.preferredHost || '';
  const subdomainLinkUrl = DEVELOPMENT_HOSTS_REGEX.test(preferredHost)
    ? `http://${preferredHost}${Routes.LOGIN}?subdomain=${branding?.subdomain}`
    : `https://${state.session.data?.preferredHost.replace(
        'icm',
        branding?.subdomain || 'icm'
      )}${Routes.LOGIN}`;

  if (!branding?.name) {
    return null;
  }

  return (
    <InfoSection title="Branding Details">
      <DescriptionList>
        <dt>
          <Text type={TextType.BODY_S} subdued>
            Branding Page Title
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>{branding.name || '–'}</Text>
        </dd>

        <dt>
          <Text type={TextType.BODY_S} subdued>
            Branding Color
          </Text>
        </dt>
        <dd style={{ color: branding.primaryColor }}>
          <Text type={TextType.BODY_S}>{branding.primaryColor || '-'}</Text>
        </dd>

        <dt>
          <Text type={TextType.BODY_S} subdued>
            Branding URL
          </Text>
        </dt>
        <dd>
          <Text type={TextType.BODY_S}>
            {branding.subdomain ? (
              <a href={subdomainLinkUrl} target="_blank" rel="noreferrer">
                {subdomainLinkUrl}
              </a>
            ) : (
              '–'
            )}
          </Text>
        </dd>

        {branding.logoFileUrl && (
          <>
            <dt>
              <Text type={TextType.BODY_S} subdued>
                Branding Logo
              </Text>
            </dt>
            <dd>
              <img src={branding.logoFileUrl} width={250} />
            </dd>
          </>
        )}

        {branding.editedLogoFileUrl && (
          <>
            <dt>
              <Text type={TextType.BODY_S} subdued>
                Branding Logo Edited
              </Text>
            </dt>
            <dd>
              <img src={branding.editedLogoFileUrl} width={250} />
            </dd>
          </>
        )}
      </DescriptionList>
    </InfoSection>
  );
};
