import React from "react";
import _pub7Ab5Bb97, {Container as _pub7Ab5Bb97_Container,Detail as _pub7Ab5Bb97_Detail} from "../../syndication-v2/base.pc";
import _pubCb76A421, {MatchesTitle as _pubCb76A421_MatchesTitle,Match as _pubCb76A421_Match} from "../../syndication-v2/matches.pc";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pubBab5A68F from "../../../../../design-system/src/utils.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import _pub8A05Fd1A from "../../../../../design-system/src/BlankSlate.pc";
import _pubDf2A042 from "../../../../../design-system/src/Field.pc";
import _pub14733275 from "../../../../../design-system/src/Checkbox.pc";
import _pubC530B6D5, {ModalPreview as _pubC530B6D5_ModalPreview} from "../../../../../design-system/src/Modal.pc";
import _pub8Ac8C6B9, {Preview as _pub8Ac8C6B9_Preview} from "../../../components/Topbar/index.pc";
import _pub5448A231 from "../../../../../design-system/src/Detail.pc";
import _pub61E4905A from "../../../../../design-system/src/Separator.pc";
import _pub5Ccf593C from "../../../../../design-system/src/Toggle.pc";
import _pubE434Fb6A from "../../../../../design-system/src/Card.pc";
import _pub622F8858, {MenuItem as _pub622F8858_MenuItem} from "../../../../../design-system/src/Menu.pc";
import "./company-investors.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var EmptyInvestors = React.memo(React.forwardRef(function EmptyInvestors(props, ref) {
  return React.createElement("div", {
    "className": "_eab61063 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_634a8a9c _bd36497c _pub-bd36497c _pub-a97d53f3",
    }, 
      React.createElement(_pub8A05Fd1A, {
        "class": "_e0b48448 _bd36497c _pub-bd36497c _pub-a97d53f3",
        "title": "Discover unique opportunities",
        "description": "Use this section to manage your investors and permission your data. To get the ball rolling, complete your funding profile to wow prospective investors",
        "controls": React.createElement(_pub2Dbb4493, {
          "class": "_6232c32b _bd36497c _pub-bd36497c _pub-a97d53f3",
          "v3": true,
          "primary": true,
          "onClick": props["onClick"],
        }, 
          "Sync your data"
          
        ),
      }, null)
    )
  )
}));
export { EmptyInvestors };

var Content = React.memo(React.forwardRef(function Content(props, ref) {
  return React.createElement("div", {
    "className": "_d0e3d64 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Content };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_28f8e695 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub8Ac8C6B9_Preview, {
      "class": "_2756956 _bd36497c _pub-bd36497c _pub-a97d53f3",
      "investor": true,
    }, null),
    React.createElement(Content, {
      "class": "_ec7b087a",
    }, 
      React.createElement(_pubCb76A421_MatchesTitle, {
        "class": "_eb26bd1f _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, 
        "Investors"
        
      ),
      React.createElement(_pubCb76A421_Match, {
        "class": "_528dc33 _bd36497c _pub-bd36497c _pub-a97d53f3",
        "bigtitle": true,
        "title": "Company 1",
        "hover": props["hoverItem"],
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_e18a91bd _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Location",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_968da12b _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Industry Vertical",
            "value": "Puters 👾",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_f84f091 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_7883c007 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Equity Raised",
            "value": "&#36;1 bn",
          }, null)
        ),
      }, null),
      React.createElement(_pubCb76A421_Match, {
        "class": "_9b4c4990 _bd36497c _pub-bd36497c _pub-a97d53f3",
        "bigtitle": true,
        "title": "Company 2",
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_7d9fc333 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Location",
            "value": "San Francisco, CA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_a98f3a5 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Industry Vertical",
            "value": "Clothing",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_9391a21f _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_e4969289 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Equity Raised",
            "value": "&#36;1 tn",
          }, null)
        ),
      }, null),
      React.createElement(_pubCb76A421_Match, {
        "class": "_ec4b7906 _bd36497c _pub-bd36497c _pub-a97d53f3",
        "bigtitle": true,
        "title": "Company 2",
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_c523a456 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Location",
            "value": "Taipei, Taiwan",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_b22494c0 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Industry Vertical",
            "value": "Hamburgers 🍔",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_2b2dc57a _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_5c2af5ec _bd36497c _pub-bd36497c _pub-a97d53f3",
            "light": true,
            "label": "Equity Raised",
            "value": "&#36;12 m",
          }, null)
        ),
      }, null)
    )
  )
}));
export { Preview };

var Empty = React.memo(React.forwardRef(function Empty(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_2f95228c _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(Content, {
      "class": "_57cc18a",
    }, 
      React.createElement(EmptyInvestors, {
        "class": "_6a25ff27",
      }, null)
    )
  )
}));
export { Empty };

var BackArrowWrapper = React.memo(React.forwardRef(function BackArrowWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_c19b43a0 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
    "data-testid": props["testId"],
  }, 
    React.createElement(InvestorDetailBackButton, {
      "class": "_71ff2572",
      "transparent": true,
      "onClick": props["clickGoBack"],
    }, 
      React.createElement("img", {
        "className": "_1127c670 _bd36497c _pub-bd36497c _pub-a97d53f3",
        "src": getDefault(require("./assets/icon.svg")),
      }, null)
    ),
    React.createElement("div", {
      "className": "_e8f674c8 _bd36497c _pub-bd36497c _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { BackArrowWrapper };

var InvestorDetailBackButton = React.memo(React.forwardRef(function InvestorDetailBackButton(props, ref) {
  return React.createElement(_pub2Dbb4493, {
    "class": "_26236ea7 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
    "transparent": props["transparent"],
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { InvestorDetailBackButton };

var InvestorDetail = React.memo(React.forwardRef(function InvestorDetail(props, ref) {
  return React.createElement("div", {
    "className": "_31e3d7d4 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(BackArrowWrapper, {
      "class": "_cdce3ea5",
      "clickGoBack": props["clickGoBack"],
    }, 
      React.createElement(InvestorDetailHeader, {
        "class": "_553be075",
        "title": props["title"],
        "description": React.createElement("div", {
          "className": "_a3e651e4 _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          props["description"]
        ),
      }, null),
      React.createElement("div", {
        "className": "_223cd0e3 _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, 
        React.createElement(_pub5448A231, {
          "class": "_c3ad18ed _bd36497c _pub-bd36497c _pub-a97d53f3",
          "horizontal": true,
          "title": "Location",
          "description": props["location"],
        }, null),
        React.createElement(_pub5448A231, {
          "class": "_5aa44957 _bd36497c _pub-bd36497c _pub-a97d53f3",
          "horizontal": true,
          "title": "Website",
          "description": React.createElement("a", {
            "className": "_ef80de13 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "href": props["website"],
            "target": "_blank",
            "rel": "noopener noreferrer",
          }, 
            props["website"]
          ),
        }, null),
        React.createElement(_pub5448A231, {
          "class": "_2da379c1 _bd36497c _pub-bd36497c _pub-a97d53f3",
          "horizontal": true,
          "title": "Investment types",
          "description": props["investment_types"],
        }, null),
        React.createElement(_pub5448A231, {
          "class": "_b3c7ec62 _bd36497c _pub-bd36497c _pub-a97d53f3",
          "horizontal": true,
          "title": "Investment range",
          "description": props["investment_ranges"],
        }, null)
      ),
      React.createElement(_pub61E4905A, {
        "class": "_bb358159 _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, null),
      React.createElement("div", {
        "className": "_cc32b1cf _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, 
        React.createElement(InvestorDetailToggle, {
          "class": "_c029cc83",
          "title": React.createElement(InvestorDetailToggleTitle, {
            "class": "_80aa2477",
            "text": "Enable Dataroom",
            "onClick": props["onClickDataroom"],
            "on": props["dataroomEnabled"],
            "testId": "company:enable-dataroom-button",
          }, null),
          "description": "Grant access to " + props["title"] + " to all the documents you uploaded to the dataroom",
        }, null),
        React.createElement(InvestorDetailToggle, {
          "class": "_59209d39",
          "title": React.createElement(InvestorDetailToggleTitle, {
            "class": "_ab8777b4",
            "text": "Enable Insights",
            "onClick": props["onClickInsights"],
            "on": props["insightsEnabled"],
            "testId": "company:enable-insights-button",
          }, null),
          "description": "Grant access to all the insights generated by Hum Capital based on your documents and connectors",
        }, null)
      ),
      React.createElement(InvestorFundFooter, {
        "class": "_5256246c",
        "handleArchiveSharedSpacesModal": props["handleArchiveSharedSpacesModal"],
      }, null)
    )
  )
}));
export { InvestorDetail };

var InvestorDetailHeader = React.memo(React.forwardRef(function InvestorDetailHeader(props, ref) {
  return React.createElement(_pub5448A231, {
    "class": "_dfedb6f8 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
    "title": props["title"],
    "description": props["description"],
    "header": true,
  }, null)
}));
export { InvestorDetailHeader };

var InvestorDetailToggle = React.memo(React.forwardRef(function InvestorDetailToggle(props, ref) {
  return React.createElement("div", {
    "className": "_368e13cd _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubE434Fb6A, {
      "class": "_bdc0a6ef _bd36497c _pub-bd36497c _pub-a97d53f3",
    }, 
      React.createElement(_pub5448A231, {
        "class": "_d67e1c14 _bd36497c _pub-bd36497c _pub-a97d53f3",
        "title": props["title"],
        "description": props["description"],
      }, null)
    )
  )
}));
export { InvestorDetailToggle };

var InvestorDetailToggleTitle = React.memo(React.forwardRef(function InvestorDetailToggleTitle(props, ref) {
  return React.createElement("div", {
    "className": "_d88072e1 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("span", {
      "className": "_c9434217 _bd36497c _pub-bd36497c _pub-a97d53f3",
    }, 
      props["text"]
    ),
    React.createElement(_pub5Ccf593C, {
      "class": "_504a13ad _bd36497c _pub-bd36497c _pub-a97d53f3",
      "onClick": props["onClick"],
      "on": props["on"],
      "testId": props["testId"],
    }, null)
  )
}));
export { InvestorDetailToggleTitle };

var InvestorFundFooter = React.memo(React.forwardRef(function InvestorFundFooter(props, ref) {
  return React.createElement("div", {
    "className": "_3f385fe6 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("p", {
      "className": "_c3dd6f1d _bd36497c _pub-bd36497c _pub-a97d53f3",
    }, 
      "\n    Not interested in this investor? "
      ,
      React.createElement("a", {
        "className": "_124c9b22 _bd36497c _pub-bd36497c _pub-a97d53f3",
        "onClick": props["handleArchiveSharedSpacesModal"],
      }, 
        "\n      Archive shared space.\n    "
        
      )
    )
  )
}));
export { InvestorFundFooter };

var InvestorPreview = React.memo(React.forwardRef(function InvestorPreview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_7ea24113 _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub8Ac8C6B9, {
      "class": "_271e368a _bd36497c _pub-bd36497c _pub-a97d53f3",
      "slim": true,
      "title": props["title"]||"Example Page",
    }, null),
    React.createElement(Content, {
      "class": "_5019061c",
    }, 
      React.createElement(InvestorDetail, {
        "class": "_483ed0cd",
        "title": "Investor Fund",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi egestas nam consectetur bibendum nulla aliquam. Id gravida augue sit habitant lectus ipsum, odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi egestas nam consectetur bibendum nulla aliquam. Id gravida augue sit habitant lectus ipsum, odio.",
        "location": "New York, USA",
        "website": "wwww.investors.com",
        "investment_types": "Mezz Debt, Special Situations, Leveraged, Buyout (LBO), Venture Debt",
        "investment_ranges": "Up to 50M",
      }, null)
    )
  )
}));
export { InvestorPreview };

var ArchiveSharedSpace = React.memo(React.forwardRef(function ArchiveSharedSpace(props, ref) {
  return React.createElement("div", {
    "className": "_90ac203f _bd36497c _pub-bd36497c _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubC530B6D5_ModalPreview, {
      "class": "_249ae2e4 _bd36497c _pub-bd36497c _pub-a97d53f3",
      "visible": true,
      "padded": true,
      "footer": React.createElement("div", {
        "className": "_bd96028e _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, 
        React.createElement(_pub2Dbb4493, {
          "class": "_7b232155 _bd36497c _pub-bd36497c _pub-a97d53f3",
          "transparent": true,
        }, 
          "Cancel"
          
        ),
        React.createElement(_pub2Dbb4493, {
          "class": "_e22a70ef _bd36497c _pub-bd36497c _pub-a97d53f3",
          "negative": true,
        }, 
          "Archive"
          
        )
      ),
      "header": "Archive shared space?",
    }, 
      React.createElement("p", {
        "className": "_333ce99a _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, 
        "\n      Archiving your shared space will permanently remove you as a investment\n      oppurtunity for this investor. The investor will no longer have access to\n      any of your permissioned data.\n    "
        
      ),
      React.createElement("p", {
        "className": "_443bd90c _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, 
        "Let us know why you’ve decided to archive this investor"
        
      ),
      React.createElement(_pub622F8858, {
        "class": "_dd3288b6 _bd36497c _pub-bd36497c _pub-a97d53f3",
      }, 
        React.createElement(_pub622F8858_MenuItem, {
          "class": "_61d19734 _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          React.createElement(_pubDf2A042, {
            "class": "_926bec7d _bd36497c _pub-bd36497c _pub-a97d53f3",
            "toggle": true,
            "label": "Too early",
          }, 
            React.createElement(_pub14733275, {
              "class": "_7407433f _bd36497c _pub-bd36497c _pub-a97d53f3",
            }, null)
          )
        ),
        React.createElement(_pub622F8858_MenuItem, {
          "class": "_f8d8c68e _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          React.createElement(_pubDf2A042, {
            "class": "_902d5224 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "toggle": true,
            "label": "Issue with metrics",
          }, 
            React.createElement(_pub14733275, {
              "class": "_33a739ef _bd36497c _pub-bd36497c _pub-a97d53f3",
            }, null)
          )
        ),
        React.createElement(_pub622F8858_MenuItem, {
          "class": "_8fdff618 _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          React.createElement(_pubDf2A042, {
            "class": "_91ef3813 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "toggle": true,
            "label": "Market size",
          }, 
            React.createElement(_pub14733275, {
              "class": "_ec7105f _bd36497c _pub-bd36497c _pub-a97d53f3",
            }, null)
          )
        ),
        React.createElement(_pub622F8858_MenuItem, {
          "class": "_11bb63bb _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          React.createElement(_pubDf2A042, {
            "class": "_94a02e96 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "toggle": true,
            "label": "Macro on industry",
          }, 
            React.createElement(_pub14733275, {
              "class": "_bce7cc4f _bd36497c _pub-bd36497c _pub-a97d53f3",
            }, null)
          )
        ),
        React.createElement(_pub622F8858_MenuItem, {
          "class": "_66bc532d _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          React.createElement(_pubDf2A042, {
            "class": "_956244a1 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "toggle": true,
            "label": "Deal size and valuation not a fit",
          }, 
            React.createElement(_pub14733275, {
              "class": "_8187e5ff _bd36497c _pub-bd36497c _pub-a97d53f3",
            }, null)
          )
        ),
        React.createElement(_pub622F8858_MenuItem, {
          "class": "_ffb50297 _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          React.createElement(_pubDf2A042, {
            "class": "_9724faf8 _bd36497c _pub-bd36497c _pub-a97d53f3",
            "toggle": true,
            "label": "Investor not a fit",
          }, 
            React.createElement(_pub14733275, {
              "class": "_c6279f2f _bd36497c _pub-bd36497c _pub-a97d53f3",
            }, null)
          )
        ),
        React.createElement(_pub622F8858_MenuItem, {
          "class": "_88b23201 _bd36497c _pub-bd36497c _pub-a97d53f3",
        }, 
          React.createElement(_pubDf2A042, {
            "class": "_96e690cf _bd36497c _pub-bd36497c _pub-a97d53f3",
            "toggle": true,
            "label": "Other",
          }, 
            React.createElement(_pub14733275, {
              "class": "_fb47b69f _bd36497c _pub-bd36497c _pub-a97d53f3",
            }, null)
          )
        )
      )
    )
  )
}));
export { ArchiveSharedSpace };


